import React from "react";
import { Screen, FullScreenLayout } from "../components/layout";
import { isNotEmpty } from "../../framework/utils";
import M from "../../strings";

export default class Forbidden extends Screen {
  render() {
    const content = this.props.content;

    return (
      <FullScreenLayout>
        {isNotEmpty(content) ? (
          { content }
        ) : (
          <section className="error">
            <div className="error__inner">
              <h1>
                <i className="zmdi zmdi-lock-outline" />
              </h1>
              <h2>{M("forbiddenScreenGenericMessage")}</h2>
            </div>
          </section>
        )}
      </FullScreenLayout>
    );
  }
}
