import _ from "underscore";
import { hasRole } from "../api/session";
import { isNotEmpty } from "../framework/utils";
import { walk } from "./lang";

export function sanitizeMenu(menu, useRoles = true) {
  let _menu = JSON.parse(JSON.stringify(menu)),
    _items = _menu.items ?? [];

  // sanitizing children
  walk(_items, "children", (item) => {
    item.children = _.filter(
      item.children ?? [],
      (c) => (!useRoles || hasRole(c.permissions ?? [])) && isNotEmpty(c.id)
    );
  });

  // sanitizing root items
  _items = _.filter(_items, (menuItem) => {
    return (
      (!useRoles || hasRole(menuItem.permissions ?? [])) &&
      (isNotEmpty(menuItem.children) ||
        (menuItem.href != undefined && isNotEmpty(menuItem.id)))
    );
  });

  _menu.items = _items;

  return _menu;
}
