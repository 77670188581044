import { PAGES } from "../vars";
import M from "../../strings";
import { UserPermission } from "../enums";
import { isSuperuser } from "../../api/session";

export default {
  items: [
    /*
        {
            id: PAGES.PERMISSIONS,
            icon: "security",
            text: M(PAGES.PERMISSIONS),
            children: [
                {
                    id: "users",
                    icon: "supervisor_account",
                    text: M("users"),
                    href: "/entities/user",
                }
            ],
        },*/
    {
      id: PAGES.COMPANY,
      icon: "domain",
      text: M(PAGES.COMPANY),
      href: "/entities/section/" + PAGES.COMPANY + "/company",
      permissions: [UserPermission.ADMIN_SUPERUSER],
    },
    {
      id: PAGES.CALENDAR,
      icon: "date_range",
      text: M(PAGES.CALENDAR),
      href: "",
      permissions: [UserPermission.COMPANY_USER],
    },
    {
      id: PAGES.ORDERS,
      icon: "list_alt",
      text: M(PAGES.ORDERS),
      href: "/entities/section/" + PAGES.ORDERS + "/customerOrder",
      permissions: [
        UserPermission.ADMIN_SUPERUSER,
        UserPermission.COMPANY_USER,
      ],
    },
    {
      id: PAGES.SERVICES,
      icon: "room_service",
      text: M(PAGES.SERVICES),
      href: "/entities/section/" + PAGES.SERVICES + "/experience",
      permissions: [
        UserPermission.ADMIN_SUPERUSER,
        UserPermission.COMPANY_USER,
      ],
    },
    {
      id: PAGES.USERS,
      icon: "group",
      text: M(PAGES.USERS),
      href: "/entities/section/" + PAGES.USERS,
      permissions: [UserPermission.ADMIN_SUPERUSER],
    },
    {
      id: PAGES.SETTINGS,
      icon: "settings",
      text: M(PAGES.SETTINGS),
      href: "/entities/company/my",
      permissions: [UserPermission.COMPANY_USER],
    },
    {
      id: PAGES.SETTINGS,
      icon: "settings",
      text: M(PAGES.SETTINGS),
      href: "/entities/company",
      permissions: [UserPermission.ADMIN_SUPERUSER],
    },
  ],
};
