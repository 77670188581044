import React from "react";
import { hasRole } from "../../api/session";
import { UserPermission } from "../../model/enums";
import FacilityStore from "../../stores/facility";
import { connect } from "../../utils/aj-react";
import { optional } from "../../utils/lang";

const Logo = (props) => {
  return (
    <h1>
      <a href="#">
        {props.text ? props.text : <img src="resources/images/logo.png" />}
      </a>
    </h1>
  );
};

const stateMapper = (state) => ({
  text: hasRole(UserPermission.COMPANY_USER)
    ? optional(() => state.userFacility.name, "Magellano")
    : null,
});

const actionsMapper = () => ({});

export default connect(Logo).to(FacilityStore, stateMapper, actionsMapper);
