"use strict";

import * as React from "react";
import M from "../../../strings";
import { Control } from "../forms";
import { HeaderBlock } from "../common";
import {
  ActionsCell,
  arrayResult,
  EditNumberCell,
  Grid,
  resultToGridData,
  TextCell,
} from "../grids";
import { optional, updatedList } from "../../../utils/lang";
import _ from "underscore";
import EntitiesLookupCell, {
  EntitiesSelectDialog,
} from "../cells/entitiesLookupCell";
import { hideDialog, showDialog } from "../../../actions/dialog";
import StandaloneEntityGrid from "../standaloneEntityGrid";
import { alert } from "../../../plugins";
import { UserPermission } from "../../../model/enums";

const ValueObjectType = {
  TEXT: "text",
  NUMBER: "number",
  YES_NO: "yes-no",
};

export class CompanyServiceSubCategoriesControl extends Control {
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
    };
  }

  componentDidMount() {
    let field = this.props.field;
    let model = this.props.model;
  }

  updateModelList(items) {
    let field = this.props.field;
    let model = this.props.model;
    model.set(field.property, items);
    this.forceUpdate();
  }

  createNewRow(id, description, categoryName) {
    let data = this.getData();
    let isAlreadyExist = _.filter(data, (d) => _.isEqual(d.id, id));
    if (_.size(isAlreadyExist) > 0) {
      hideDialog();
      alert(M("entityAlreadyAssociated"), "", "error");
    } else {
      let columns = _.initial(this.getDescriptor().columns);
      let arrayDefault = _.chain(columns)
        .map((c) => {
          let defaultValue = c.props?.defaultValue ?? null;
          if (!(_.isUndefined(defaultValue) || _.isNull(defaultValue))) {
            return { [c.property]: defaultValue };
          }
        })
        .value();

      arrayDefault.push({
        ["id"]: id,
        ["subCategoryDescription"]: description,
        ["categoryDescription"]: categoryName,
      });
      arrayDefault = _.chain(arrayDefault)
        .reduce((obj, ele) => _.assign({}, obj, ele))
        .value();

      data.push(arrayDefault);
      this.updateModelList(data);
      this.forceUpdate();
    }
  }

  onRemoveRow(row) {
    this.updateModelList(_.filter(this.getData(), (d) => d.id !== row.id));
  }

  getData() {
    let field = this.props.field;
    let model = this.props.model;
    let data = optional(model.get(field.property), []);

    return data;
  }

  onChange(column, row, newValue) {
    this.updatePropertyValue(column.property, row.id, newValue);
  }

  updatePropertyValue(property, id, value) {
    let object = {};
    object[property] = value;
    this.updateModelList(
      updatedList(
        this.getData(),
        (v) => v.id === id,
        (v) => _.assign({}, v, object)
      )
    );
  }

  onRowGridDialogSelect(row) {
    let id = row?.id ?? undefined;
    let description = row?.description ?? undefined;
    let categoryName = row?.experienceCategory?.description ?? undefined;
    this.createNewRow(id, description, categoryName);
  }

  onDialogOk() {
    let { selectedRows } = this.state;
    if (_.size(selectedRows) > 0) {
      _.each(selectedRows, (row) => {
        let id = row?.id ?? undefined;
        let description = row?.description ?? undefined;
        let categoryName = row?.experienceCategory?.description ?? undefined;
        this.createNewRow(id, description, categoryName);
      });
    }
    hideDialog();
  }

  onSelectionChanged(selectedRows) {
    this.setState({ selectedRows });
  }

  getActions() {
    return [
      {
        id: "add",
        icon: "add_circle_outline",
        tooltip: M("addRow"),
        action: () => {
          //this.createNewRow()
          showDialog({
            getContent: () => (
              <EntitiesSelectDialog
                entity="experienceSubCategory"
                onRowSelect={this.onRowGridDialogSelect.bind(this)}
                descriptor={this.getLookUpCellDescriptor()}
                discriminator={"lookUp-grid-dialog"}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
              />
            ),
            backdrop: "static",
            large: true,
            buttons: [
              {
                text: M("cancel"),
                action: hideDialog,
                className: "btn btn-default",
              },
              {
                text: "OK",
                action: this.onDialogOk.bind(this),
                className: "btn btn-default",
              },
            ],
          });
        },
        permissions: [UserPermission.ADMIN_SUPERUSER],
      },
    ];
  }

  getLookUpCellDescriptor() {
    return {
      columns: [
        {
          property: "description",
          header: M("description"),
          cell: TextCell,
          sortable: false,
        },
        {
          property: "experienceCategory.description",
          header: M("category"),
          cell: TextCell,
          sortable: false,
        },
      ],
    };
  }

  getDescriptor() {
    let descriptor = {
      columns: [
        {
          property: "feePlatform",
          header: M("feePlatform") + " %",
          cell: EditNumberCell,
          props: {
            defaultValue: "10",
            maxValue: "100",
            minValue: "0",
            onValueChange: this.onChange.bind(this),
          },
        },
        {
          property: "feeCrossSelling",
          header: M("feeCrossSelling") + " %",
          cell: EditNumberCell,
          props: {
            defaultValue: "2.5",
            maxValue: "100",
            minValue: "0",
            onValueChange: this.onChange.bind(this),
          },
        },
        {
          property: "id",
          header: M("experienceSubCategoryId"),
          cell: TextCell,
          visibility: (gridProps) => {
            return false;
          },
        },
        {
          property: "subCategoryDescription",
          header: M("experienceSubCategory"),
          cell: TextCell,
        },
        {
          property: "categoryDescription",
          header: M("experienceCategory"),
          cell: TextCell,
        },
      ],
    };
    descriptor.columns.push({
      property: "actions",
      header: "",
      cell: ActionsCell,
      tdClassName: "grid-actions",
      sortable: false,
      searchable: false,
      actions: [{ icon: "delete", action: this.onRemoveRow.bind(this) }],
    });

    return descriptor;
  }

  render() {
    let field = this.props.field;
    let title = field?.title ?? "";
    return (
      <div className="values-object-container">
        <HeaderBlock title={title} actions={this.getActions()} />
        <div>
          <Grid
            tableClassName="table table-striped table-hover table-condensed table-values-object"
            ref="values_object"
            descriptor={this.getDescriptor()}
            data={resultToGridData(arrayResult(this.getData()))}
            noResultsText={M("noItems")}
            summaryVisible="false"
            selectionEnabled="false"
            paginationEnabled="false"
            showInCard="false"
            footerVisible="false"
          />
        </div>
      </div>
    );
  }
}
