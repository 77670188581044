import _ from "underscore";
import { OrderStatus, PaymentStatus } from "../../model/enums";
import M from "../../strings";
import { DateTime, ReadOnlyText } from "../components/forms";
import { ActiveCell, DateCell, TextCell } from "../components/grids";
import EntitiesGridControl from "../components/controls/entitiesGridControl";
import { getSymbol } from "../../utils/currency";
import Price from "../components/controls/price";
import { ACTION_TYPES } from "../components/actions";
import {
  SEARCH_FORM_DATE_DESCRIPTOR,
  SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR,
  SEARCH_FORM_SELECT_DESCRIPTOR,
  SEARCH_FORM_STRING_DESCRIPTOR,
  SEARCH_FORM_VALUES_LIST,
  SEARCH_FORM_VALUES_SELECT_CONTAINER_FIELD_DESCRIPTOR,
} from "../../model/searchForms";
import { fromEnum, fromList } from "../../utils/datasource";
import { entitySearchForm } from "../screens/entities/commonFields";
import { getUrlParameter } from "../utils/ui";
import { EntitiesLookupContainer } from "../components/containers";
import { isSuperuser } from "../../api/session";
import SubGridControl from "../components/controls/subGridControl";

export default () => ({
    grid: {
      quickSearchEnabled: true,
      canDelete: false,
    canSelectAll: false,
        title: M("payments"),
    canCreate: false,
        descriptor: {
            columns: [
              {
                property: "customerName",
                header: M("customer"),
                cell: TextCell,
                sortable: true,
                searchable: true,
                searchForm: entitySearchForm(
                  "customerId.value",
                  M("customer"),
                  "customer",
                  true,
                  true
                ),
              },
              
              {
                property: "products",
                header: M("products"),
                cell: TextCell,
                sortable: false,
                searchable: false,
                props: {
                    formatter: (value) => {
                      if (!_.isEmpty(value)) {
                return value.map((v) => v.description).join(", ");
              } else {
                        return "Nessun prodotto";
                      }
            },
                  },
              },
              {
                property: "startedAt",
                header: M("startedAt"),
                cell: DateCell,
                sortable: true,
                searchable: true,
                props: {
                  type: "instantDate",
                },
                searchForm: SEARCH_FORM_DATE_DESCRIPTOR("startedAt"),
              },
              {
                property: "endedAt",
                header: M("endedAt"),
                cell: DateCell,
                sortable: true,
                searchable: true,
                props: {
                  type: "instantDate",
                },
                searchForm: SEARCH_FORM_DATE_DESCRIPTOR("endedAt"),
              },
                {
                    property: "status",
                    header: M("status"),
                    cell: ActiveCell,
                    sortable: true,
                    searchable: true,
                    searchForm: SEARCH_FORM_SELECT_DESCRIPTOR(
                      "status",
                      fromEnum(PaymentStatus)
                    ),
                    props: {
                      items: [
                        {
                          value: PaymentStatus.CREATED,
                          className: "waiting",
                        },
                        {
                          value: PaymentStatus.STARTED,
                          className: "active",
                        },
                        {
                          value: PaymentStatus.FAILED,
                          className: "disabled",
                        },
                        {
                          value: PaymentStatus.PARTIALLY_COMPLETED,
                          className: "partially-completed",
                        },
                        {
                          value: PaymentStatus.COMPLETE,
                          className: "complete",
                        },
                        {
                          value: PaymentStatus.CANCELLED,
                          className: "closed",
                        },
                      ],
                    },
                  },          
                ],
              },
    },
    form: {
    getTitle(data, params) {
      return [{ title: M("payments") }];
        },
        canSave: () => false,
    showFloatingSaveBtn: false,
    subtitle: null,
        descriptor: { 
             onModelLoad: (model) => {
        model.on("property:change", (property, value) => {
          if (_.isEqual(property, "id")) {
            model.invalidateForm();
              }
        });
          },
          areas: [
            {
                title: M("payments"),

            actions: (model) => {
            if (model.get("status") === "Created") {
                  return [
                    {
                      action: () => {},
                      title: M("Created"),
                      type: ACTION_TYPES.BUTTON,
                      disabled: true,
                      className: "btn-light",
                    },
                  ];
            } else if (model.get("status") === "Started") {
                  return [
                    {
                      action: () => {},
                      title: M("Started"),
                      type: ACTION_TYPES.BUTTON,
                      disabled: true,
                      className: "btn-primary",
                    },
                  ];
            } else if (model.get("status") === "Failed") {
                  return [
                    {
                      action: () => {},
                      title: M("Failed"),
                      type: ACTION_TYPES.BUTTON,
                      disabled: true,
                      className: "btn-danger",
                    },
                  ];
            } else if (model.get("status") === "PartiallyCompleted") {
                  return [
                    {
                      action: () => {},
                      title: M("PartiallyCompleted"),
                      type: ACTION_TYPES.BUTTON,
                      disabled: true,
                      className: "btn-warning",
                    },
                  ];
            } else if (model.get("status") === "Complete") {
                  return [
                    {
                      action: () => {},
                      title: M("Complete"),
                      type: ACTION_TYPES.BUTTON,
                      disabled: true,
                      className: "btn-success",
                    },
                  ];
            } else if (model.get("status") === "Canceled") {
                  return [
                    {
                      action: () => {},
                      title: M("Canceled"),
                      type: ACTION_TYPES.BUTTON,
                      disabled: true,
                      className: "btn-secondary",
                    },
                  ];
                }
              },
          
              fields: [
                {
                  property: "customerName",
                  label: M("customer"),
                  size: "col-sm-4",
                  control: ReadOnlyText,
                  sortable: true,
                  searchable: true,
                },
                
                {
                    property: "startedAt",
                    label: M("startDate"),
                    size: "col-sm-4",
                    control: ReadOnlyText,
                    sortable: true,
                    searchable: true,
                    placeholder: "Nessuna data",
                    searchForm: SEARCH_FORM_DATE_DESCRIPTOR("startedAt"),
                    props: {
                      formatter: (value) => {
                        if (value) {
                          const date = new Date(value * 1000);
                    const day = String(date.getDate()).padStart(2, "0");
                    const month = String(date.getMonth() + 1).padStart(2, "0");
                          const year = date.getFullYear();
                          return `${day}/${month}/${year}`;
                        }
                        return "Nessuna data";
                },
              },
                  },
                {
                    property: "endedAt",
                    label: M("endDate"),
                    size: "col-sm-4",
                    control: ReadOnlyText,
                    sortable: true,
                    searchable: true,
                    placeholder: "Nessuna data",
                    searchForm: SEARCH_FORM_DATE_DESCRIPTOR("endedAt"),
                    props: {
                      formatter: (value) => {
                        if (value) {
                          const date = new Date(value * 1000);
                    const day = String(date.getDate()).padStart(2, "0");
                    const month = String(date.getMonth() + 1).padStart(2, "0");
                          const year = date.getFullYear();
                          return `${day}/${month}/${year}`;
                        }
                        return "Nessuna data";
                },
              },
                    },
                    
                    {
                      property: "externalSystemId",
                      label: M("stripeId"),
                      control: ReadOnlyText,
                      sortable: true,
                      searchable: true,
                    },
                    {
                      property: "platformFee.value",
                      control: Price,
                      label: M("platformFee"),
                      validator: (value) => check(value).notEmpty(),
                      props: {
                        getCurrencyCode: (model) =>
                          model.get("currencyCode") ?? getCurrency(),
                        disabled: true,
                      },
                    },
                ],
            },
            {   
                fields: [
                  {
                    property: "products",
                    control: SubGridControl,
                    props: {
                      isSelectionWithCheck: false,
                      canDelete: false,
                      canCreate: false,
                      gridDescriptor: {
                        columns: [
                    {
                      property: "description",
                      header: M("description"),
                      cell: TextCell,
                    },
                    {
                      property: "quantity",
                      header: M("quantity"),
                      cell: TextCell,
                    },
                    {
                      property: "price",
                      header: M("price"),
                      cell: TextCell,
                      props: {
                        formatter: (v) =>
                          v.value + " " + getSymbol(v.currencyCode),
                      },
                    },
                        ],
                      },
                      formDescriptor: {
                        fields: [
                          {
                            property: "description",
                            control: ReadOnlyText,
                            label: M("description"),
                            validator: (value) => check(value).notEmpty(),
                          },
                          {
                            property: "quantity",
                            control: ReadOnlyText,
                            label: M("quantity"),
                            validator: (value) => check(value).notEmpty(),
                          },
                          {
                            property: "price.value",
                            control: Price,
                            label: M("price"),
                            validator: (value) => check(value).notEmpty(),
                            props: {
                              getCurrencyCode: (model) =>
                                model.get("currencyCode") ?? getCurrency(),
                              disabled: true,
                            },
                          },
                        ],
                      },
                    },
                  },
          ],
            },
            {   
              fields: [
                {
                  property: "recipients",
                  control: SubGridControl,
                  props: {
                    isSelectionWithCheck: false,
                    canDelete: false,
                    canCreate: false,
                    gridDescriptor: {
                      columns: [
                    {
                      property: "facilityName",
                      header: M("facility"),
                      cell: TextCell,
                    },
                    {
                      property: "amount",
                      header: M("total"),
                      cell: TextCell,
                      props: {
                        formatter: (v) =>
                          v.value + " " + getSymbol(v.currencyCode),
                      },
                    },
                      ],
                    },
                    formDescriptor: {
                      fields: [
                        {
                          property: "facilityName",
                          control: ReadOnlyText,
                          label: M("facility"),
                          validator: (value) => check(value).notEmpty(),
                        },
                        {
                          property: "amount.value",
                          control: Price,
                          label: M("total"),
                          validator: (value) => check(value).notEmpty(),
                          props: {
                            getCurrencyCode: (model) =>
                              model.get("currencyCode") ?? getCurrency(),
                            disabled: true,
                          },
                        },
                      ],
                    },
                  },
                },
            ],
        },
      ],
    },
  },
});
