import { dispatch } from "../aj";
import { createAsyncAction } from "../utils/ajex";
import Services from "../api/services";
import { hideLoader, showLoader, toast } from "../plugins";
import {
  LOAD_EXPERIENCE_PRICINGS,
  GET_EXPERIENCE_BASE_TARIFF,
  CREATE_EXPERIENCE_PRICING,
  UPDATE_EXPERIENCE_PRICING,
  SAVE_EXPERIENCE_PRICINGS,
  DELETE_EXPERIENCE_PRICING,
  CLEAR_EXPERIENCE_PRICINGS_CHANGES,
  CLEAR_EXPERIENCE_PRICINGS,
} from "./types";
import _ from "underscore";
import M from "../strings";
import * as responses from "../api/responses";
import { localDateToIsoString } from "../utils/date";
import ExperiencePricingsStore from "../stores/experiencePricing";
import { uuid } from "../utils/lang";

export const loadExperiencePricings = createAsyncAction(
  LOAD_EXPERIENCE_PRICINGS,
  async ({ experienceId, from, to }) => {
    if (_.isEmpty(experienceId)) {
      alert(M("problemOccoured"), M("pleaseSpecifyId"));
      return;
    }

    dispatch({
      type: LOAD_EXPERIENCE_PRICINGS,
    });

    //showLoader();
    try {
      const response = await Services.get(
        `experiences/${experienceId}/pricings`,
        {
          from: localDateToIsoString(from),
          to: localDateToIsoString(to),
        }
      );
      if (response.responseCode !== responses.OK) {
        throw response.responseCode;
      }
      loadExperiencePricings.complete({ pricings: response.pricings });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      loadExperiencePricings.fail();
    } finally {
      //
    }
  }
);

export const saveExperiencePricings = createAsyncAction(
  SAVE_EXPERIENCE_PRICINGS,
  async ({ experienceId }) => {
    if (_.isEmpty(experienceId)) {
      alert(M("problemOccoured"), M("pleaseSpecifyId"));
      return;
    }

    dispatch({
      type: SAVE_EXPERIENCE_PRICINGS,
    });

    showLoader();
    try {
      const response = await Services.postJson(
        `experiences/${experienceId}/pricings`,
        {
          experienceId,
          created: ExperiencePricingsStore.state.created,
          updated: ExperiencePricingsStore.state.updated,
          deleted: ExperiencePricingsStore.state.deleted,
        }
      );
      if (response.responseCode !== responses.OK) {
        throw response.responseCode;
      }
      clearExperiencePricingsChanges();
      saveExperiencePricings.complete({ pricings: response.pricings });
      toast(M("pricinsSavedSuccessfully"), "success");
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      saveExperiencePricings.fail();
    } finally {
      hideLoader();
    }
  }
);

export const getExperienceBaseTariff = createAsyncAction(
  GET_EXPERIENCE_BASE_TARIFF,
  async (experienceId) => {
    if (_.isEmpty(experienceId)) {
      alert(M("problemOccoured"), M("pleaseSpecifyId"));
      return;
    }

    dispatch({
      type: GET_EXPERIENCE_BASE_TARIFF,
    });

    //showLoader();
    try {
      const response = await Services.get(
        `experiences/${experienceId}/base-tariff`
      );
      if (response.responseCode !== responses.OK) {
        throw response.responseCode;
      }
      getExperienceBaseTariff.complete({ baseTariff: response.tariff });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      getExperienceBaseTariff.fail();
    } finally {
      //hideLoader();
    }
  }
);

export async function createExperiencePricing(pricing) {
  pricing.id = uuid();

  const response = await Services.get(
    "entities/pricingRule/" + pricing.pricingRuleId
  );
  pricing.pricingRule = response.value;

  dispatch({
    type: CREATE_EXPERIENCE_PRICING,
    pricing,
  });
}

export function updateExperiencePricing(pricing) {
  dispatch({
    type: UPDATE_EXPERIENCE_PRICING,
    pricing,
  });
}

export function deleteExperiencePricing(pricing) {
  dispatch({
    type: DELETE_EXPERIENCE_PRICING,
    pricing,
  });
}

export function clearExperiencePricingsChanges() {
  dispatch({ type: CLEAR_EXPERIENCE_PRICINGS_CHANGES });
}

export function clearExperiencePricings() {
  dispatch({ type: CLEAR_EXPERIENCE_PRICINGS });
}
