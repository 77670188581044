import React from "react";

const ColorCell = ({ value }) => (
  <div className="color-cell">
    <div className="color-badge" style={{ backgroundColor: value }}></div>
    <span>{value}</span>
  </div>
);

export default ColorCell;
