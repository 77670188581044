import React, { useCallback } from "react";
import cn from "classnames";
import _ from "underscore";
import { useDropzone } from "react-dropzone";
import { parseBoolean } from "../../utils/lang";
import M from "../../strings";

export default function Dropzone(props) {
  const onDrop = useCallback(
      (acceptedFiles, fileRejections, e) => {
        if (_.isFunction(props.onDrop)) {
          props.onDrop(acceptedFiles, fileRejections, e);
        }
      },
      [props.onDrop]
    ),
    multiple = parseBoolean(props.multiple) ?? false,
    { getRootProps, getInputProps, isDragActive } = useDropzone(
      _.extend({}, props, {
        onDrop: onDrop,
        multiple: multiple, //by default dropzone uses true, but we want the other way around
      })
    ),
    tooltip = props.tooltip ?? M("dropzoneTooltip"),
    onDragTooltip = props.onDragTooltip ?? M("dropzoneOnDragTooltip");

  return (
    <div
      {...getRootProps()}
      className={cn("dropzone__wrapper", props.className)}
    >
      <input {...getInputProps()} />
      <div className="dropzone__tooltip">
        {isDragActive ? onDragTooltip : tooltip}
      </div>
      {React.Children.count(props.children) > 0 && (
        <div className="m-t-16">{props.children}</div>
      )}
    </div>
  );
}
