import React from "react";
import _ from "underscore";
import { isEmpty, isNotEmpty } from "../../../framework/utils";
import { alert } from "../../../plugins";
import M from "../../../strings";
import { hideDialog, showDialog, updateDialog } from "../../../actions/dialog";
import { HeaderBlock } from "../common";
import { Grid, resultToGridData } from "../grids";
import { format, optional, uuid } from "../../../utils/lang";
import { Control, Form, VALIDATION_ERROR } from "../forms";
import { ACTION_TYPES, DEFAULT_ACTIONS } from "../actions";
import { DIALOG_RESULT_CANCEL, DIALOG_RESULT_OK } from "../dialogs";

export default class SubGridControl extends Control {
  constructor(props) {
    super(props);
    this.uuid = uuid();
    this.gridRef = React.createRef();

    if (isEmpty(this.props.gridDescriptor)) {
      throw new Error(
        "[SubGridControl] constructor: please provide a grid descriptos"
      );
    }

    if (
      isEmpty(this.props.formDescriptor) &&
      !_.isFunction(this.props.createNewRow)
    ) {
      throw new Error(
        "[SubGridControl] constructor: please provide a form descriptos"
      );
    }
  }

  componentDidMount() {
    const value = this.getValue();

    this.sanitizeModelList(value, true);
  }

  componentDidUpdate() {
    const value = this.getValue();

    this.sanitizeModelList(value, true);
  }

  getTitle() {
    let title = optional(this.getField().title, M(this.getProperty()));

    if (_.isFunction(this.getField().title)) {
      title = this.getField().title(this.getModel());
    }

    return title;
  }

  generateActions() {
    let actions = [];

    if (this.canCreate()) {
      actions.push({
        id: DEFAULT_ACTIONS.CREATE,
        type: ACTION_TYPES.ICON,
        icon: "add",
        tooltip: M("addItem"),
        action: () => {
          this.createNewRow();
        },
      });
    }

    if (this.canDelete()) {
      actions.push({
        id: DEFAULT_ACTIONS.DELETE,
        type: ACTION_TYPES.ICON,
        icon: "delete",
        tooltip: M("deleteItem"),
        action: () => {
          this.onRemoveRows();
        },
      });
    }

    return actions;
  }

  canCreate() {
    let canCreate = optional(this.props.canCreate, true);
    if (_.isFunction(this.props.canCreate))
      canCreate = this.props.canCreate(this.getModel());
    return canCreate;
  }

  canDelete() {
    let canDelete = optional(this.props.canDelete, true);
    if (_.isFunction(this.props.canDelete))
      canDelete = this.props.canDelete(this.getModel());
    return canDelete;
  }

  createNewRow() {
    const model = this.getModel(),
      createNewRow = this.props.createNewRow,
      data = this.generateItemList();

    if (createNewRow && _.isFunction(createNewRow)) {
      const newRow = createNewRow(model);
      this.updateModelList([...data, newRow]);
    } else {
      this.showFormDialog();
    }
  }

  sanitizeModelList(items, triggerUpdate = false) {
    let updated = false;

    _.each(items ?? [], (item) => {
      if (isEmpty(item.uuid)) {
        item.uuid = uuid();
        updated = true;
      }
    });

    if (updated && triggerUpdate) {
      this.onValueChange({
        target: {
          value: items,
        },
      });
    }
  }

  updateModelList(items) {
    this.sanitizeModelList(items);

    this.onValueChange({
      target: {
        value: items,
      },
    });
  }

  onRemoveRows() {
    const selection = this.gridRef?.current?.getSelection() ?? [];

    if (isNotEmpty(selection)) {
      const uuidsToDelete = _.pluck(selection, "uuid"),
        data = _.reject(this.generateItemList(), (item) =>
          _.contains(uuidsToDelete, item.uuid)
        );
      this.updateModelList(data);
    } else {
      alert(M("ooops"), M("noItemToDeleteAlert"), "error");
    }

    /*if(this.getIterativeKeyId()){
            this.updateKeyRows()
         }*/
  }

  /* getIterativeKeyId(){
        return optional(this.props.iterativeKeyId, false)
    }

    updateKeyRows(){
        const data = this.generateItemList()
        const updatedList = _.map(data, (row, index) =>{
            row.key = index + 1
            return row
        })

        this.updateModelList([...updatedList])
    }*/

  generateItemList() {
    const property = this.getProperty(),
      model = this.getModel(),
      data = model?.get(property) ?? [];

    return data;
  }

  showFormDialog(rowIndex) {
    const model = this.getModel(),
      property = this.getProperty(),
      descriptor = this.getFormDescriptor(),
      title = descriptor.title ?? M("add") + " " + this.getTitle();

    showDialog({
      title: title,
      getContent: () => (
        <SubGridRowFormDialog
          model={model}
          property={property}
          descriptor={descriptor}
          rowIndex={rowIndex}
          onSaveRow={this.updateModelList.bind(this)}
        />
      ),
      centered: true,
      large: true,
      onClose: () => {
        const data = this.generateItemList();
        this.updateModelList(data);
      },
    });
  }

  getErrors() {
    const model = this.getModel(),
      property = this.getProperty(),
      propertyRegExp = new RegExp("^" + property + "_"),
      validationResult = model.generateValidationResultForForm()?.errors ?? [],
      validationErrors = _.chain(validationResult)
        .filter((vr) => vr.property.match(propertyRegExp))
        .map((vr) => {
          const _property = vr.property.replace(propertyRegExp, ""),
            row = _property.match(/^\d+/)[0],
            subProperty = _property.replace(/^\d+_/, "");

          return _.extend({}, vr, {
            row: parseFloat(row),
            subProperty: subProperty,
          });
        })
        .sortBy("row")
        .value();
    return validationErrors;
  }

  isSelectionEnabled() {
    const selectionEnabled = this.props.isSelectionEnabled ?? true,
      model = this.getModel();

    return _.isFunction(selectionEnabled)
      ? selectionEnabled(model)
      : selectionEnabled;
  }

  isSelectWithCheck() {
    const selectionEnabled = this.isSelectionEnabled(),
      selectWithCheck = this.props.isSelectionWithCheck ?? true,
      model = this.getModel();

    if (!selectionEnabled) {
      return false;
    }

    return _.isFunction(selectWithCheck)
      ? selectWithCheck(model)
      : selectWithCheck;
  }

  getGridDescriptor() {
    const model = this.getModel(),
      gridDescriptor = this.props.gridDescriptor;

    return _.isFunction(gridDescriptor)
      ? gridDescriptor(model)
      : gridDescriptor;
  }

  getFormDescriptor() {
    const model = this.getModel(),
      formDescriptor = this.props.formDescriptor;

    return _.isFunction(formDescriptor)
      ? formDescriptor(model)
      : formDescriptor;
  }

  editRow(row) {
    const value = this.getValue(),
      rowIndex = _.findIndex(value, (item) => item.uuid === row.uuid);

    this.showFormDialog(rowIndex !== -1 ? rowIndex : undefined);
  }

  onRowDoubleClick() {
    return _.isFunction(this.props.onRowDoubleClick)
      ? this.props.onRowDoubleClick()
      : this.editRow.bind(this);
  }

  render() {
    const validationResultField = this.props.validationResult;
    const model = this.getModel(),
      actions = this.generateActions(),
      errors = this.getErrors(),
      data = this.generateItemList(),
      result = {
        rows: data,
        totalRows: data.length,
      },
      selectionEnabled = this.isSelectionEnabled(),
      selectionWithCheck = this.isSelectWithCheck(),
      className =
        "col-sm-12 sub-grid p-0" +
        (_.isFunction(this.props.getExtraClassName)
          ? " " + this.props.getExtraClassName(model)
          : ""),
      gridDescriptor = this.getGridDescriptor();

    const tableClassName =
      gridDescriptor.tableClassName ??
      "table table-bordered" + (selectionEnabled ? " table-hover" : "");

    return (
      <div className={className}>
        <HeaderBlock title={this.getTitle()} actions={actions} />
        <div className="sub-grid__container">
          <Grid
            ref={this.gridRef}
            descriptor={gridDescriptor}
            tableClassName={tableClassName}
            data={resultToGridData(result)}
            noResultsText={M("noItems")}
            summaryVisible="false"
            singleClickSelection={selectionEnabled}
            selectWithCheck={selectionWithCheck}
            selectionEnabled={selectionEnabled}
            paginationEnabled="false"
            showInCard="false"
            footerVisible="false"
            onRowDoubleClick={this.onRowDoubleClick()}
          />
        </div>
        {isNotEmpty(errors) && (
          <div className="sub-grid-control__validation-result has-error">
            {_.map(errors, (e, i) => {
              return (
                <div key={"sub-grid-control-" + this.uuid + "-error-" + i}>
                  {format(
                    M("subGridControlValidationError"),
                    e.row + 1,
                    M(e.subProperty),
                    e.message
                  )}
                </div>
              );
            })}
          </div>
        )}
        {!validationResultField.valid &&
          !_.isEmpty(validationResultField.message) &&
          _.isEmpty(errors) && (
            <div className="sub-grid-control__validation-result has-error">
              <div key={"sub-grid-control-" + this.uuid + "-error-" + 0}>
                {validationResultField.message}
              </div>
            </div>
          )}
      </div>
    );
  }
}

class SubGridRowFormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  getValue() {
    const { model, property } = this.props;

    return [...(model.get(property) ?? [])];
  }

  saveRow() {
    const { model, property, rowIndex } = this.props,
      value = this.getValue(),
      currentModel = this.formRef.current.model,
      data = _.omit(this.formRef.current.model.sanitized(), "_parent");

    try {
      currentModel.validate();

      if (rowIndex !== undefined) {
        value[rowIndex] = data;
      } else {
        value.push(data);
      }

      if (this.props.onSaveRow && _.isFunction(this.props.onSaveRow)) {
        this.props.onSaveRow(value);
      } else {
        model.set(property, value);
      }

      hideDialog();
    } catch (e) {
      if (e === VALIDATION_ERROR) {
        this.formRef.current.forceUpdate();
      } else {
        throw e;
      }
    }
  }

  componentDidMount() {
    updateDialog({
      buttons: [
        {
          text: M("cancel"),
          dialogResult: DIALOG_RESULT_CANCEL,
          action: hideDialog,
        },
        {
          text: M("confirm"),
          dialogResult: DIALOG_RESULT_OK,
          action: this.saveRow.bind(this),
        },
      ],
    });
  }

  render() {
    const rowIndex = this.props.rowIndex,
      value = this.getValue(),
      data = rowIndex !== undefined ? value[rowIndex] ?? {} : {},
      descriptor = _.extend({}, this.props.descriptor ?? {}, {
        showFormFooter: false,
        showInCard: false,
      });

    const model = this.props.model;
    data["_parent"] = model.sanitized();

    return (
      <Form
        ref={this.formRef}
        descriptor={descriptor}
        onCancel={hideDialog}
        onSubmit={this.saveRow.bind(this)}
        data={data}
      />
    );
  }
}
