import React from "react";
import ReactDOM from "react-dom";
import { defaultMenuState, MenuStore } from "../../stores/menu";
import { SessionStore } from "../../stores/session";
import { UIStore } from "../../stores/ui";
import * as ui from "../utils/ui";
import { GlobalLoader, UnobtrusiveLoader } from "./loader";
import { optional, parseBoolean, uuid } from "../../utils/lang";
import M from "../../strings";
import _ from "underscore";
import { SystemStore } from "../../stores/system";
import { systemInformation } from "../../actions/system";
import globalComponents from "./extra/globalComponents";
import GlobalDialog from "./globalDialog";
import { MaterialIcon } from "./icons";
import { logout } from "../../actions/session";
import { confirm } from "../../plugins";
import { isNotEmpty } from "../../framework/utils";
import { getLoggedUser, isLoggedIn, isSuperuser } from "../../api/session";
import { connect } from "../../utils/aj-react";
import { hideDialog, showDialog } from "../../actions/dialog";
import EntitiesDialog from "./dialogs/entitiesDialog";
import { getEntityData } from "../entities";
import { changeUserFacility } from "../../actions/facility";
import Logo from "./logo";

function showPageLoader() {
  $(".page-loader").show();
}

function hidePageLoader() {
  $(".page-loader").fadeOut(500);
}

let GlobalTransitionTimer = null;

class ScreenContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentScreen: null,
    };
  }

  componentDidMount() {
    ui.addScreenChangeListener((screen) => {
      //showPageLoader()
      this.setState(_.assign(this.state, { currentScreen: screen }));
      //hidePageLoader()
    });
  }

  render() {
    if (_.isEmpty(this.state.currentScreen)) {
      return <div />;
    }
    return this.state.currentScreen;
  }
}

class Screen extends React.Component {
  render() {
    return <>{this.props.children}</>;
  }
}

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <GlobalDialog />
        <GlobalLoader />
        <UnobtrusiveLoader />
        <ScreenContainer />
        {globalComponents()}
      </div>
    );
  }
}
class LayoutTransition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      __screen_animating: true,
    };
  }

  componentDidMount() {
    if (GlobalTransitionTimer) {
      clearTimeout(GlobalTransitionTimer);
    }

    GlobalTransitionTimer = setTimeout(
      () => this.setState({ __screen_animating: false }),
      250
    );
  }

  componentWillUnmount() {
    if (GlobalTransitionTimer) {
      clearTimeout(GlobalTransitionTimer);
    }
  }

  render() {
    const transition =
      "animated animated-fast " + (this.props.transition || "fadeIn");
    let className =
      (this.props.className || "") +
      " " +
      (this.state.__screen_animating ? transition : "");

    return <div className={className}>{this.props.children}</div>;
  }
}

class Layout extends React.Component {
  constructor(props) {
    super(props);
    connect(this, MenuStore, defaultMenuState());
  }

  render() {
    const noMenu = parseBoolean(this.props.noMenu ?? false),
      topMenu = this.state.topMenu?.items ?? [],
      { page, activeMenuItem } = this.props,
      hideFooter = this.props.hideFooter === true;

    return (
      <>
        <Header
          page={page}
          noMenu={noMenu}
          items={topMenu}
          profileImage={this.state.profileImage}
        />

        {!noMenu && (
          <SideBar>
            <MainMenuContainer activeMenuItem={activeMenuItem} />
          </SideBar>
        )}

        <Body {...this.props}>{this.props.children}</Body>

        {!hideFooter && <Footer />}
      </>
    );
  }
}

class LayoutNoMenu extends React.Component {
  render() {
    const noMenu = this.props.noMenu ?? true;
    return (
      <Layout {...this.props} noMenu={true}>
        {this.props.children}
      </Layout>
    );
  }
}

class FullScreenLayout extends React.Component {
  render() {
    return <LayoutTransition>{this.props.children}</LayoutTransition>;
  }
}

class Header extends React.Component {
  logout() {
    confirm(M("logoutConfirmMessage"))
      .then(logout)
      .catch((e) => {
        console.log(e);
      });
  }

  onChangeFacility() {
    showDialog({
      getContent: () => (
        <EntitiesDialog
          entity="facility"
          onRowDoubleClick={async (row) => {
            await changeUserFacility({ id: row.id });
            hideDialog();
            ui.navigate("/");
            window.location.reload();
          }}
          descriptor={getEntityData("facility").grid.descriptor}
          discriminator={"facility-grid-dialog"}
        />
      ),
      large: true,
      buttons: [
        {
          text: M("cancel"),
          action: () => hideDialog(),
        },
      ],
      backdrop: "static",
    });
  }

  getLoggedUserInitials() {
    const loggedUser = getLoggedUser();
    let initials = "";

    if (isNotEmpty(loggedUser)) {
      initials += optional(() => loggedUser.profile.name[0].toUpperCase(), "");
      initials += optional(() => loggedUser.profile.name[1].toUpperCase(), "");
    } else {
      initials = "U";
    }

    return initials;
  }

  onItemClick(item) {
    const href = item.href;

    if (href !== undefined) {
      ui.navigate(href);
    }
  }

  render() {
    const noMenu = parseBoolean(this.props.noMenu ?? false),
      initials = this.getLoggedUserInitials(),
      items = this.props.items ?? [];

    const profileImage = optional(
      () => URL.createObjectURL(this.props.profileImage),
      null
    );

    return (
      <header id="header" className="header clearfix">
        {!noMenu && (
          <div
            className="navigation-trigger hidden-xl-up"
            data-ma-action="aside-open"
            data-ma-target=".sidebar"
          >
            <div className="navigation-trigger__inner">
              <i className="navigation-trigger__line"></i>
              <i className="navigation-trigger__line"></i>
              <i className="navigation-trigger__line"></i>
            </div>
          </div>
        )}

        <div className="header__logo hidden-sm-down">
          <Logo />
        </div>

        <div className="header__spacer" />

        {isNotEmpty(items) && (
          <div className="header__nav">
            {_.map(items, (item, index) => {
              const { id, icon, text, children } = item,
                active = id === this.props.page,
                isDropdown = isNotEmpty(children),
                key = "header-nav-item-" + index,
                itemContentProps = {
                  className:
                    "header__nav-item-content" +
                    (isDropdown ? " dropdown-toggle" : ""),
                };

              let className = "header__nav-item";

              if (active) {
                className += " header__nav-item--active";
              }

              if (isDropdown) {
                className += " dropdown";
                _.extend(itemContentProps, {
                  role: "button",
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                });
              } else {
                itemContentProps.onClick = this.onItemClick.bind(this, item);
              }

              return (
                <div key={key} className={className}>
                  <div {...itemContentProps}>
                    {icon && <MaterialIcon icon={icon} />}
                    {text}
                  </div>

                  {isDropdown && (
                    <ul className="dropdown-menu">
                      {_.map(children, (child, index) => {
                        const { icon, text } = child;
                        return (
                          <li
                            key={key + "-dropdonw-item-" + index}
                            className="dropdown-item"
                            onClick={this.onItemClick.bind(this, child)}
                          >
                            {icon && <MaterialIcon icon={icon} />}
                            {text}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {isNotEmpty(items) && (
          <div
            id="header-nav-dropdown"
            className="header__nav-dropdown dropdown"
          >
            <div
              className="dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <MaterialIcon icon="more_vert" />
            </div>
            <ul className="dropdown-menu" aria-labelledby="header-nav-dropdown">
              {_.map(items, (item, index) => {
                const { id, icon, text, children } = item,
                  isDropdown = isNotEmpty(children),
                  key = "header-nav-dropdown-item-" + index,
                  active = id === this.props.page,
                  itemProps = {
                    key: key,
                    className: className,
                  };

                let className = isDropdown
                  ? "dropdown-header"
                  : "dropdown-item";

                if (active) {
                  className += " " + className + "--active";
                }

                if (!isDropdown) {
                  itemProps.onClick = this.onItemClick.bind(this, item);
                }

                return (
                  <>
                    {isDropdown && index > 0 && (
                      <li
                        key={key + "-top-divider"}
                        className="dropdown-divider"
                      />
                    )}
                    <li key={key} {...itemProps} className={className}>
                      {icon && <MaterialIcon icon={icon} />}
                      {text}
                    </li>
                    {isDropdown &&
                      _.map(children, (child, index) => {
                        const { icon, text } = child;
                        return (
                          <li
                            className="dropdown-item"
                            key={key + "-sub-item-" + index}
                            onClick={this.onItemClick.bind(this, child)}
                          >
                            {icon && <MaterialIcon icon={icon} />}
                            {text}
                          </li>
                        );
                      })}
                    {isDropdown && index < items.length && (
                      <li
                        key={key + "-bottom-divider"}
                        className="dropdown-divider"
                      />
                    )}
                  </>
                );
              })}
            </ul>
          </div>
        )}

        {isLoggedIn() && (
          <div
            id="header-badge"
            className="header__nav-item header__nav-item--badge dropdown"
          >
            <div
              className="header__nav-item-content dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span
                style={{
                  backgroundImage: profileImage
                    ? `url('${profileImage}')`
                    : null,
                }}
              >
                {initials}
              </span>
            </div>
            <ul className="dropdown-menu" aria-labelledby="header-badge">
              {!isSuperuser() && (
                <li
                  className="dropdown-item"
                  onClick={this.onChangeFacility.bind(this)}
                >
                  {M("changeFacility")}
                </li>
              )}

              <li className="dropdown-item" onClick={this.logout.bind(this)}>
                {M("logout")}
              </li>
            </ul>
          </div>
        )}
      </header>
    );
  }
}

class Body extends React.Component {
  render() {
    const noMenu = parseBoolean(this.props.noMenu ?? false);
    let className = "content";

    if (noMenu) {
      className += " " + "content--full";
    }

    if (this.props.className) {
      className += " " + this.props.className;
    }

    return (
      <section className={className}>
        <LayoutTransition className={this.props.layoutTransitionClassName}>
          {this.props.children}
        </LayoutTransition>
      </section>
    );
  }
}

class SideBar extends React.Component {
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).find(".scrollbar-inner").scrollbar();
  }

  render() {
    return (
      <aside
        id="sidebar"
        className={"sidebar " + (this.props.hidden ? "sidebar--hidden" : "")}
      >
        <div className="scrollbar-inner">{this.props.children}</div>
      </aside>
    );
  }
}

class ProfileBox extends React.Component {
  constructor(props) {
    super(props);

    connect(this, [SessionStore, UIStore]);

    this.state = {};
  }

  logout() {
    logout();
    ui.navigate("/login");
  }

  render() {
    return (
      <div className="user">
        <div className="user__info" data-toggle="dropdown">
          {this.state.profileImage ? (
            <img className="user__img" src={this.state.profileImage} alt="" />
          ) : (
            <img
              className="user__img"
              src="resources/images/ic_perm_identity.png"
              alt=""
            />
          )}
          <div>
            <div className="user__name">
              {optional(() => this.state.user.profile.name, "NA")}
            </div>
            <div className="user__email">
              {optional(() => this.state.user.email, "NA")}
            </div>
          </div>
        </div>

        <div className="dropdown-menu">
          <a className="dropdown-item" onClick={this.logout.bind(this)}>
            <i className="zmdi zmdi-time-restore"></i> Logout
          </a>
        </div>
      </div>
    );
  }
}

class MainMenuContainer extends React.Component {
  constructor(props) {
    super(props);
    connect(this, MenuStore, defaultMenuState());
  }

  onItemClick(item) {
    const href = item.href;

    if (href) {
      ui.navigate(href);
    }
  }

  render() {
    const menu = this.state.menu,
      activeMenuItem = this.props.activeMenuItem;

    return (
      <MainMenu
        menu={menu}
        onItemClick={this.onItemClick.bind(this)}
        activeMenuItem={activeMenuItem}
      />
    );
  }
}

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  onItemClick(item) {
    if (this.props.onItemClick && _.isFunction(this.props.onItemClick)) {
      this.props.onItemClick(item);
    }
  }

  onBackClick() {
    const backUrl = this.props.menu?.backUrl;
    if (isNotEmpty(backUrl)) {
      ui.navigate(backUrl);
    }
  }

  render() {
    const items = this.props.menu?.items ?? [],
      activeMenuItem = this.props.activeMenuItem,
      backUrl = this.props.menu?.backUrl,
      backText = this.props.menu?.backText ?? M("back");

    return (
      <div className="menu__wrapper">
        {isNotEmpty(backUrl) && (
          <div className="menu__item menu__item--back">
            <button
              className="btn btn-primary btn-block"
              onClick={this.onBackClick.bind(this)}
            >
              <MaterialIcon icon="chevron_left" />
              {backText}
            </button>
          </div>
        )}
        {_.map(items, (item, index) => {
          const groupItems = isNotEmpty(item.children) ? item.children : [item],
            groupKey = "main-menu-" + this.uuid + "-item-group-" + index;

          return (
            <div key={groupKey} className="menu__item-group">
              {_.map(groupItems, (groupItem, index) => {
                const active = groupItem.id === activeMenuItem,
                  itemKey = groupKey + "-item-" + index;

                return (
                  <MenuItem
                    key={itemKey}
                    item={groupItem}
                    active={active}
                    onClick={this.onItemClick.bind(this, groupItem)}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

class MenuItem extends React.Component {
  onClick() {
    const item = this.props.item;

    if (this.props.onClick && _.isFunction(this.props.onClick)) {
      this.props.onClick(item);
    }
  }

  render() {
    const item = this.props.item ?? {},
      active = this.props.active ?? false,
      { text, icon } = item;
    let className = "menu__item";

    if (active) {
      className += " menu__item--active";
    }

    return (
      <div className={className} onClick={this.onClick.bind(this)}>
        {icon && <MaterialIcon className="menu__item-icon" icon={icon} />}
        <div className="menu__item-text">{text}</div>
      </div>
    );
  }
}

class Footer extends React.Component {
  constructor(props) {
    super(props);
    connect(this, SystemStore, {});
  }

  componentDidMount() {
    systemInformation();
  }

  render() {
    let backendVersion = this.state.backendVersion;
    let apiVersion = this.state.apiVersion;
    let copyrightInfos = this.state.copyrightInfos;

    return (
      <footer className="footer hidden-xs-down">
        <p className="nav footer__nav">
          {backendVersion && <span> Web: v{backendVersion}&nbsp; </span>}
          -&nbsp;
          {apiVersion && <span>API: v{apiVersion}&nbsp; </span>}
          -&nbsp;
          {copyrightInfos && <span>Copyright: {copyrightInfos}&nbsp; </span>}
        </p>
      </footer>
    );
  }
}

exports.Index = Index;
exports.Screen = Screen;
exports.FullScreenLayout = FullScreenLayout;
exports.Layout = Layout;
exports.LayoutNoMenu = LayoutNoMenu;
exports.Header = Header;
exports.Footer = Footer;
