import deepmerge from "deepmerge";
import _ from "underscore";
import { getSessionToken } from "../api/session";
("use strict");

function getBaseUrl(url) {
  const fsUrl = config.get("fs.url");
  let sanitizedUrl = url;

  if (fsUrl.endsWith("/") && url.startsWith("/")) {
    sanitizedUrl = url.substring(1);
  }

  return fsUrl + "/" + sanitizedUrl;
}

exports.isEmpty = function (obj) {
  if (obj == undefined) {
    return true;
  }
  if (obj == null) {
    return true;
  }

  if (obj instanceof Array) {
    return obj.length == 0;
  }

  if (obj == "") {
    return true;
  }

  return false;
};

exports.isNotEmpty = function (obj) {
  return !exports.isEmpty(obj);
};

exports.deepClone = function (obj) {
  return deepmerge({}, obj);
};

exports.attemptTask = function (task, options = {}, ...args) {
  if (!_.isFunction(task)) {
    throw new Error("[attemptTask]: task is not a function");
  }

  if (!_.isObject(options)) {
    throw new Error("[attemptTask]: invalid options parameter");
  }

  const _options = _.extend(
    {
      retryAttempts: 3,
      retryInterval: 0,
    },
    options
  );

  if (!_.isFinite(_options.retryAttempts)) {
    throw new Error("[attemptTask]: options.retryAttempts is not a number");
  }

  if (!_.isFinite(_options.retryInterval)) {
    throw new Error("[attemptTask]: options.retryInterval is not a number");
  }

  let error, response;
  const retryAttempts = _options.retryAttempts,
    retryInterval = _options.retryInterval,
    executor = () => {
      if (error === false) {
        return Promise.resolve();
      } else {
        return new Promise((resolve, reject) => {
          task(...args)
            .then((r) => {
              response = r;
              error = false;
              resolve();
            })
            .catch((e) => {
              response = e;
              error = true;
              if (retryInterval === undefined || retryInterval <= 0) {
                resolve();
              } else {
                _.delay(resolve, retryInterval);
              }
            });
        });
      }
    },
    promises = [];

  for (let i = 0; i < retryAttempts; i++) {
    promises.push(executor);
  }

  const promiseChain = _.reduce(
    promises,
    (prev, next) => {
      return prev.then(next);
    },
    Promise.resolve()
  );

  return new Promise((resolve, reject) => {
    promiseChain.then(() => {
      if (!error) {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });
};

exports.getFileServerUrl = function (path) {
  const baseUrl = getBaseUrl("file");
  let url = baseUrl + "?path=" + encodeURIComponent(path);

  url += "&__TOKEN=" + encodeURIComponent(getSessionToken());

  return url;
};

exports.getImageServerUrl = function (path, size = null) {
  const baseUrl = getBaseUrl("image");
  let url = baseUrl + "?path=" + encodeURIComponent(path);

  if (size) {
    url += "&size=" + encodeURIComponent(size);
  }

  url += "&__TOKEN=" + encodeURIComponent(getSessionToken());

  return url;
};
