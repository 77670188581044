"use strict";

import * as aj from "../aj/index";
import * as Actions from "../actions/types";
import { isNotEmpty } from "../framework/utils";
import _ from "underscore";
import { MENU } from "./types";
import * as MenuUtils from "../utils/menu";
import { MENUS, TYPES } from "../model/menu/menu";
import topMenu from "../model/menu/topMenu";

function getMenu(menuId) {
  const menu = MENUS[menuId];
  return isNotEmpty(menu) ? MenuUtils.sanitizeMenu(menu) : undefined;
}

export function defaultMenuState() {
  return {
    menuId: undefined,
    menu: {},
    topMenu: {},
  };
}

export const MenuStore = aj.createStore(
  MENU,
  (state = defaultMenuState(), action) => {
    switch (action.type) {
      case Actions.SETUP_MENU: {
        const menuId = action.menu ?? TYPES.DEFAULT,
          menu = getMenu(menuId);

        if (isNotEmpty(menu)) {
          return _.assign(state, { menuId: menuId, menu: menu });
        } else {
          return state;
        }
      }
      case Actions.RELOAD_MENU: {
        const menuId = state.menuId,
          menu = getMenu(menuId);

        if (isNotEmpty(menu)) {
          return _.assign(state, { menuId: menuId, menu: menu });
        } else {
          return state;
        }
      }
      case Actions.SETUP_TOP_MENU:
      case Actions.RELOAD_TOP_MENU:
        return _.assign(state, { topMenu: MenuUtils.sanitizeMenu(topMenu) });
    }
  }
);
