import { isEmpty } from "../../framework/utils";
import _ from "underscore";
import {
  getLanguage,
  getLanguages as getSupportedLanguages,
} from "../../strings";

export function sanitizedLocalizedObject(localizedObject) {
  const sanitizedValue = { values: [] },
    values = _.isArray(localizedObject?.values) ? localizedObject.values : [],
    languages = _.chain()
      .union(getSupportedLanguages(), _.pluck(values, "lang"))
      .reject((l) => isEmpty(l))
      .uniq()
      .sort()
      .value();

  _.each(languages, (language) => {
    const defaultValue = {
        lang: language,
        value: undefined,
      },
      value = _.findWhere(values, { lang: language });

    if (value) {
      _.extend(defaultValue, value);
    }

    sanitizedValue.values.push(defaultValue);
  });

  return sanitizedValue;
}

export function getLocalizedValue(localizedObject, lang = getLanguage()) {
  const sanitizedValue = sanitizedLocalizedObject(localizedObject),
    localizedValue = _.findWhere(sanitizedValue.values, { lang: lang });

  return localizedValue?.value;
}

export function getLanguages(localizedObject) {
  return _.pluck(sanitizedLocalizedObject(localizedObject).values, "lang");
}

export function isLocalizedObject(object) {
  if (isEmpty(object)) {
    return false;
  } else {
    return (
      _.isObject(object) &&
      !_.isArray(object) &&
      _.has(object, "localizedValue", "values") &&
      _.isArray(object?.values)
    );
  }
}

export function localizeObject(object) {
  if (isEmpty(object) || !_.isObject(object)) {
    return object;
  } else if (_.isArray(object)) {
    return _.map(object, (o) => localizeObject(o));
  } else if (isLocalizedObject(object)) {
    return localizeObject(getLocalizedValue(object));
  } else {
    return _.mapObject(object, (val) => localizeObject(val));
  }
}
