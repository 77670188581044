"use strict";

import React from "react";
import _ from "underscore";
import { onDialogHidden } from "../../actions/dialog";
import DialogStore from "../../stores/dialog";
import { connect } from "../../utils/aj-react";
import { optional } from "../../utils/lang";
import { Dialog } from "./dialogs";

export default class GlobalDialog extends React.Component {
  constructor(props) {
    super(props);
    connect(this, DialogStore, {});
  }

  _onDialogClose(dialogResult) {
    const options = this.getOptions();
    // the order is important here, as calling onDialogHidden wipes away state.options from the store before we have the chance to trigger onClose
    if (options.onClose && _.isFunction(options.onClose)) {
      options.onClose(dialogResult);
    }
    onDialogHidden(true);
  }

  getOptions() {
    return optional(this.state.options, {});
  }

  getContent() {
    const options = this.getOptions();
    if (_.isFunction(options.getContent)) {
      return optional(options.getContent(), <></>);
    }

    return null;
  }

  render() {
    const hidden = optional(() => this.state.hidden, true),
      options = this.getOptions(),
      content = this.getContent(),
      dialogProps = _.extend(_.omit(options, "content", "onClose"), {
        hidden: hidden,
        onClose: this._onDialogClose.bind(this),
      });

    return (
      <div id="global-dialog" className="global-dialog">
        <Dialog {...dialogProps}>{content}</Dialog>
      </div>
    );
  }
}
