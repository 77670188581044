import React, { useCallback, useEffect, useMemo, useState } from "react";
import { check, sanitize } from "../../libs/validator";
import M from "../../strings";
import { FormBody, Model, Text, TextArea } from "./forms";
import { Tab, Tabs } from "./tabs";

const DescriptionsControl = ({ model, field, getFields, getDataSource }) => {
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(languages[0]);
  const [forms, setForms] = useState(createForms(languages));
  const onDataSourceChange = useCallback((data) => {
    setLanguages(data?.rows ?? []);
    setLanguage(data?.rows[0]);
    setForms(createForms(data?.rows ?? []));
  });
  const datasource = useMemo(() => getDataSource(model), []);

  function onTabSelected(id) {
    setLanguage(id);
  }

  function createForms(languages) {
    return languages.map((l) => {
      const fields = getFields(l);

      const descriptor = {
        showInCard: false,
        fields,
      };
      const subModel = new Model();
      subModel.descriptor = descriptor;

      subModel.on("property:change", (property, value) => {
        model.untrackChanges();

        fields.forEach((field) => {
          const newValue = subModel.get(field.property);
          const currentValue = model.get(field.property + ".texts") ?? {};
          model.set(field.property + ".texts", {
            ...currentValue,
            [l]: newValue,
          });
        });

        model.trackChanges();
      });

      subModel.untrackChanges();
      fields.forEach((field) => {
        subModel.set(
          field.property,
          model.get(field.property + ".texts." + l) ?? ""
        );
      });
      subModel.trackChanges();

      return {
        language: l,
        model: subModel,
        descriptor,
      };
    });
  }

  useEffect(() => {
    datasource.on("change", onDataSourceChange);
    model.once("load", () => {
      onDataSourceChange(datasource.data);
    });
    return () => {
      datasource.off("change", onDataSourceChange);
    };
  }, []);

  function getForm(l) {
    return (forms ?? []).find((f) => f.language === l);
  }

  return (
    <div className="facility-form__descriptions">
      <Tabs onTabSelected={onTabSelected}>
        {forms &&
          languages
            .filter((l) => getForm(l))
            .map((l) => (
              <Tab key={l} id={l} title={l} active={l === language}>
                <FormBody
                  descriptor={getForm(l).descriptor}
                  model={getForm(l).model}
                ></FormBody>
              </Tab>
            ))}
      </Tabs>
    </div>
  );
};

export default DescriptionsControl;
