import React from "react";
import { ActiveCell, TextCell } from "../components/grids";
import { Mail, Text } from "../components/forms";
import M from "../../strings";
import {
  getLoggedUser,
  hasCrudPermissions,
  isSuperuser,
  Permission,
} from "../../api/session";
import * as ui from "../utils/ui";
import * as _query from "../../framework/query";
import { isNotEmpty } from "../../framework/utils";
import { ACTION_TYPES } from "../components/actions";
import { check, sanitize } from "../../libs/validator";
import _ from "underscore";
import EntitiesGridControl from "../components/controls/entitiesGridControl";

export default () => ({
  grid: {
    title: M("company"),
    quickSearchEnabled: true,
    canCreate: () => hasCrudPermissions("company:new"),
    canDelete: () => hasCrudPermissions("company:delete"),
    descriptor: {
      columns: [
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "vatCode",
          header: M("vatCode"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        /*{
                    property: "address", 
                    header: M("address"), 
                    cell: TextCellWithSubText, 
                    sortable: false, 
                    searchable: false,
                    props:{
                        formatterTitle(value, data) {
                            return value ? value.city.toUpperCase() + " - " + value.country.toUpperCase() : ""
                        },
                        formatterSubtitle(value, data){
                            return value ? value.street + " - " + value.zipCode : ""
                        }
                    }
                },*/
        {
          property: "phoneNumber",
          header: M("phoneNumber"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "pec",
          header: M("PEC"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "enabled",
          header: M("enabled"),
          cell: ActiveCell,
          sortable: false,
          searchable: false,
        },
        /*{property: "date", header: M("creationDate"), cell: DateCell, sortable: false, searchable: false},*/
      ],
    },
  },
  form: {
    title: M("editCompany"),
    subtitle: null,
    showFloatingSaveBtn: false,
    canSave: () => hasCrudPermissions("company:save"),
    canGoBack: () => isSuperuser(),
    getTitle() {
      return [{ title: M("editCompany") }];
    },
    descriptor: {
      onModelLoadFirstTime: (model) => {
        if (
          _.isNull(model.get("creatorId")) ||
          _.isUndefined(model.get("creatorId"))
        ) {
          model.set("creatorId", getLoggedUser().id);
        }
      },
      areas: [
        {
          title: M("company"),
          actions: (model) => {
            if (model.get("enabled") === true) {
              return [
                {
                  action: () => {},
                  title: M("Active"),
                  type: ACTION_TYPES.BUTTON,
                  disabled: true,
                  className: "btn-success",
                },
              ];
            } else {
              return [
                {
                  action: () => {},
                  title: M("Inactive"),
                  type: ACTION_TYPES.BUTTON,
                  disabled: true,
                  className: "btn-danger",
                },
              ];
            }
          },
          fields: [
            {
              property: "name",
              control: Text,
              size: "col-sm-4",
              label: M("name"),
              placeholder: M("name"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "fiscalCode",
              control: Text,
              size: "col-sm-4",
              label: M("fiscalCode"),
              placeholder: M("fiscalCode"),
              validator: (value, model) => {
                if (_.isNull(value) || _.isUndefined(value)) {
                  throw new Error("Field mustn't be empty");
                }
                let stringUpperCase = value.toUpperCase();
                if (!_.isEqual(stringUpperCase, value)) {
                  throw new Error("Incorrect type of field");
                }
              },
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              property: "vatCode",
              control: Text,
              size: "col-sm-4",
              label: M("vatCode"),
              placeholder: M("vatCode"),
              validator: (value, model) => {
                if (_.isNull(value) || _.isUndefined(value)) {
                  throw new Error("Field mustn't be empty");
                }
                let stringUpperCase = value.toUpperCase();
                if (!_.isEqual(stringUpperCase, value)) {
                  throw new Error("Incorrect type of field");
                }
              },
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "sdi",
              control: Text,
              size: "col-sm-4",
              label: M("sdi"),
              placeholder: M("sdi"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "phoneNumber",
              control: Text,
              size: "col-sm-4",
              label: M("phoneNumber"),
              placeholder: M("phoneNumber"),
              validator: (v) => {
                if (_.isEmpty(v) || _.isUndefined(v)) {
                  throw new Error("Field mustn't be empty");
                }
                let number = parseInt(v);
                if (_.isNaN(number)) {
                  throw new Error("Field must be a number");
                }
              },
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              property: "pec",
              control: Mail,
              size: "col-sm-4",
              label: M("pec"),
              placeholder: M("pec"),
              validator: (v) => check(v).notEmpty(),
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              property: "address.street",
              control: Text,
              size: "col-sm-4",
              label: M("street"),
              placeholder: M("street"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "address.city",
              control: Text,
              size: "col-sm-4",
              label: M("city"),
              placeholder: M("city"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "address.state",
              control: Text,
              size: "col-sm-4",
              label: M("region"),
              placeholder: M("region"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "address.country",
              control: Text,
              size: "col-sm-4",
              label: M("country"),
              placeholder: M("country"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "address.zipCode",
              control: Text,
              size: "col-sm-4",
              label: M("zipCode"),
              placeholder: M("zipCode"),
              validator: (v) => {
                if (_.isEmpty(v) || _.isUndefined(v)) {
                  throw new Error("Field mustn't be empty");
                }
                let number = parseInt(v);
                if (_.isNaN(number)) {
                  throw new Error("Field must be a number");
                }
              },
              sanitizer: (value) => sanitize(value).trim(),
            },
          ],
        },
        /*{
                    subtitle: null,
                    visibility: model => isNotEmpty(model.get("id")),
                    fields: [
                        {
                            property: "currentLicense",
                            control: CurrentLicenseControl,
                            props:{
                                entity: "license",
                                title: M("currentLicense").toUpperCase(),
                                }
                        }
                        
                    ]
                },*/
        {
          title: M("license"),
          subtitle: null,
          actions: (model) => [
            {
              id: "create",
              type: ACTION_TYPES.BUTTON,
              icon: "add_circle",
              title: M("create"),
              permissions: ["license" + ":" + Permission.NEW],
              action: () =>
                ui.navigate(
                  "/entities/section/company/license/new?parentProperty=companyId&parentEntity=company&parentEntityId=" +
                    model.get("id")
                ),
            },
          ],
          visibility: (model) => isNotEmpty(model.get("id")),
          fields: [
            {
              property: "licenses",
              control: EntitiesGridControl,
              props: {
                entity: "license",
                parentEntity: "company",
                parentProperty: "companyId",
                tableClassName: "table table-hover table-striped",
                getEditUrl: (data) => {
                  let url = "/entities/section/company/license/" + data.id;
                  const params = {
                      parentEntity: "company",
                      parentEntityId: data.companyId,
                    },
                    paramsString = _.chain(params)
                      .map((v, k) => k + "=" + v)
                      .value()
                      .join("&");

                  url += "?" + paramsString;

                  return url;
                },
              },
            },
          ],
        },
      ],
    },
  },
});
