import _ from "underscore";
import * as _query from "../framework/query";
import M from "../strings";
import { DateTime, Number, Select, Text, TextArea, YesNo } from "../web/components/forms";
import * as datasource from "../utils/datasource";
import {
  valuesSelectContainerField,
  entitySelectContainerField,
} from "../web/screens/entities/commonFields";
import { ValuesSelectContainer } from "../web/components/containers";

export const SEARCH_FORM_DATE_DESCRIPTOR = (propertyName) =>
  _.assign(
    {},
    {
      showInCard: false,
      fields: [
        {
          property: propertyName,
          label: M("value"),
          control: DateTime,
        },
        {
          property: "_filterType",
          label: M("filterType"),
          control: Select,
          props: {
            allowNull: false,
            datasource: datasource.fixed([
              { label: M("FILTER_GTE"), value: _query.GTE },
              { label: M("FILTER_LTE"), value: _query.LTE },
            ]),
          },
        },
      ],
    }
  );

export const SEARCH_FORM_NUMBER_DESCRIPTOR = (propertyName) =>
  _.assign(
    {},
    {
      showInCard: false,
      fields: [
        {
          property: propertyName,
          label: M("value"),
          control: Number,
        },
        {
          property: "_filterType",
          label: M("filterType"),
          control: Select,
          props: {
            allowNull: false,
            datasource: datasource.fixed([
              { label: M("FILTER_EQ"), value: _query.EQ },
              { label: M("FILTER_GTE"), value: _query.GTE },
              { label: M("FILTER_LTE"), value: _query.LTE },
            ]),
          },
        },
      ],
    }
  );

export const SEARCH_FORM_SELECT_DESCRIPTOR = (propertyName, datasource) => {
  return {
    showInCard: false,
    fields: [
      {
        property: propertyName,
        label: M("value"),
        control: Select,
        props: {
          allowNull: false,
          datasource: datasource,
        },
      },
    ],
  };
};

export const SEARCH_FORM_ENTITY_SELECT_CONTAINER_FIELD_DESCRIPTOR = (
  propertyName,
  entity,
  options = {}
) => {
  const _options = _.assign(
    {},
    {
      label: M("value"),
      itemLabel: "label",
      itemValue: "value",
    },
    options,
    {
      property: propertyName,
      entity: entity,
      allowNull: true,
    }
  );

  return _.assign(
    {},
    {
      showInCard: false,
      fields: [entitySelectContainerField(_options)],
    }
  );
};

export const SEARCH_FORM_VALUES_SELECT_CONTAINER_FIELD_DESCRIPTOR = (
  propertyName,
  collection,
  options = {}
) => {
  const _options = _.assign(
    {},
    {
      label: M("value"),
      mode: "single",
      itemLabel: "label",
      itemValue: "value",
    },
    options,
    {
      property: propertyName,
      collection: collection,
      allowNull: true,
    }
  );

  return _.assign(
    {},
    {
      showInCard: false,
      fields: [valuesSelectContainerField(_options)],
    }
  );
};