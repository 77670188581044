import _ from "underscore";
import React from "react";
import { Layout, Screen } from "../../components/layout";
import M from "../../../strings";
import { freeEntities, getEntity, saveEntity } from "../../../actions/entities";
import { Form } from "../../components/forms";
import * as ui from "../../utils/ui";
import { optional, safeGet } from "../../../utils/lang";
import { EntitiesStore } from "../../../stores/entities";
import { HeaderBlockWithBreadcrumbs } from "../../components/common";
import { ActionsMatcher, ACTION_TYPES } from "../../components/actions";
import { hasCrudPermissions, Permission } from "../../../api/session";
import { getEntityData } from "../../entities";
import { PAGES } from "../../../model/vars";
import { connectDiscriminated } from "../../../utils/aj-react";
import { isNotEmpty, isEmpty } from "../../../framework/utils";
import { registerMenu, setupMenu, unregisterMenu } from "../../../actions/menu";

class EntityForm extends Screen {
  constructor(props) {
    super(props);

    if (_.isEmpty(props.entity)) {
      throw new Error("Please specify entity for form");
    }

    this.discriminator = "entity_form_" + props.entity;
    this.initialEntity = null;
    this.willGoBack = true;

    connectDiscriminated(this.discriminator, this, EntitiesStore, {
      data: null,
    });
  }

  componentDidMount() {
    let form = this.refs.form;
    let model = form.model;

    const parent = safeGet(this.props.params, "parent");
    const parentProperty = safeGet(this.props.params, "parentProperty");

    if (parentProperty && parent) {
      model.set(parentProperty, parent);
    }

    model.on("load", (m) => {
      if (parentProperty && parent) {
        m.set(parentProperty, parent);
      }
    });

    this.onBeforeUnload = function () {
      if (model.hasChanges()) {
        return M("formChangeAlert");
      }
    };

    const initModelParams = _.omit(
        this.props.params,
        "entity",
        "entityId",
        "parentEntity",
        "parentEntityId"
      ),
      initModel = () => {
        model.untrackChanges();
        //model.data = _.extend(initModelParams, model.data);
        _.each(initModelParams, (v, k) => {
          if (isEmpty(model.get(k))) {
            model.set(k, v);
          }
        });
        model.trackChanges();
        model.invalidateForm();

        const customMenuData = this.getEntityForm().customMenuData;
        if (customMenuData) {
          const menu = "entity_menu_" + this.getEntity();
          const menuData = _.isFunction(customMenuData)
            ? customMenuData(model)
            : customMenuData;
          registerMenu({ menu, menuData });
          setupMenu({ menu });
        }
      };

    if (isNotEmpty(initModelParams)) {
      if (model.initialized) {
        initModel();
      } else {
        model.once("load", initModel);
      }
    }

    //window.onbeforeunload = this.onBeforeUnload
    //ui.addOnBeforeChangeListener(this.onBeforeUnload)

    this.setState({ isCreation: this.props.entityId == "new" });
    getEntity({
      discriminator: this.discriminator,
      entity: this.props.entity,
      id: this.props.entityId,
      params: this.props.params,
    });

    //checkRevisionEnableStatus({discriminator: this.discriminator, entity: this.props.entity})
  }

  goToRevision() {
    ui.navigate("/revision/" + this.props.entity + "/" + this.getEntityId());
  }

  getEntityId() {
    let id = this.state.data != null ? this.state.data.id : null;
    if (!id) {
      if (this.props.entityId !== "new" && this.props.entityId !== "_")
        id = this.props.entityId;
    }
    return id;
  }
  componentWillUnmount() {
    const menu = "entity_menu_" + this.getEntity();
    unregisterMenu({ menu });
    setupMenu();

    freeEntities({ discriminator: this.discriminator });

    window.onbeforeunload = null;
    ui.removeOnBeforeChangeListener(this.onBeforeUnload);
  }

  submit(goBack) {
    this.willGoBack = goBack && this.canGoBack();
    this.refs.form.submit();
  }

  onSubmit(data) {
    if (_.isFunction(this.props.onSubmit)) {
      this.props.onSubmit(data);
    } else {
      const form = this.refs.form;
      const model = form.model;

      saveEntity({
        discriminator: this.discriminator,
        entity: this.props.entity,
        data: data,
        parts: model.attachments,
        reload: !this.willGoBack,
      });
    }
  }

  onCancel() {
    this.goBack();
  }

  goBack() {
    const form = this.refs.form;
    const data = form.model.sanitized();
    ui.navigate(this.getGridUrl(data));
  }

  componentWillUpdate(props, state) {
    if (state.saved) {
      this.refs.form.model.reset();
    }

    if (state.saved && this.willGoBack) {
      this.goBack();
      return false;
    }

    if (state.validationError) {
      if (state.validationResult) {
        let form = this.refs.form;
        if (form && form.model) {
          _.each(state.validationResult.errors, (e) => {
            form.model.setError(e.property, M(e.message));
          });
        }
      }
      this.refs.form.model.invalidateForm();
    }

    if (state.loaded && !this.initialized) {
      this.onDataLoad(state.data);
      this.initialized = true;
    }
  }

  onDataLoad(data) {
    let form = this.getEntityForm();
    if (_.isFunction(form.onDataLoad)) {
      form.onDataLoad(data, this.props.params);
    }
  }

  getEntity() {
    return this.props.entity;
  }

  getEntityForm() {
    const entity = this.getEntity(),
      descriptorData = getEntityData(entity),
      entityForm = descriptorData.form;
    return entityForm;
  }

  getEntityGrid() {
    const entity = this.getEntity(),
      descriptorData = getEntityData(entity),
      entityGrid = descriptorData.grid;
    return entityGrid;
  }

  getRoot() {
    const section = this.props.section;
    return isNotEmpty(section) ? "entities/section/" + section : "entities";
  }

  getGridUrl(data) {
    const form = this.getEntityForm(),
      parentEntity = safeGet(this.props.params, "parentEntity"),
      parentEntityId = safeGet(this.props.params, "parentEntityId");

    let gridUrl = form.gridUrl;
    if (_.isFunction(gridUrl)) {
      gridUrl = gridUrl(data);
    }

    const fallBackUrl =
      parentEntity && parentEntityId && this.refs.form
        ? `/${this.getRoot()}/${parentEntity}/${parentEntityId}`
        : `/${this.getRoot()}/${this.getEntity()}`;

    return optional(gridUrl, fallBackUrl);
  }

  canGoBack() {
    const form = this.getEntityForm();
    return _.isFunction(form.canGoBack)
      ? form.canGoBack(this.props.model)
      : true;
  }

  getActions() {
    let defaultActions = [];

    if (this.canSave()) {
      defaultActions.push(
        {
          id: "save",
          type: ACTION_TYPES.BUTTON,
          icon: "save",
          title: M("save"),
          permissions: this.getEntitySavePermissions(),
          action: () => {
            this.submit(true);
          },
        }
        /*{
                    id: "save-go-back",
                    type: "icon",
                    icon: "zmdi zmdi-rotate-ccw",
                    tooltip: M("saveAndGoBack"),
                    permissions: this.getEntitySavePermissions(),
                    action: () => {
                        this.submit(true)
                    }
                }*/
      );
    }

    if (this.canGoBack()) {
      defaultActions.push({
        id: "back",
        type: ACTION_TYPES.ICON,
        icon: "arrow_back",
        title: M("back"),
        action: () => {
          this.goBack();
        },
      });
    }

    if (this.canShowRevisions()) {
      defaultActions.push({
        id: "revisions",
        type: ACTION_TYPES.ICON,
        icon: "restore",
        title: M("showRevisions"),
        action: () => {
          this.goToRevision();
        },
      });
    }

    let form = this.getEntityForm();
    let matcher = new ActionsMatcher(defaultActions);
    return matcher.match(
      _.isFunction(form.getActions)
        ? form.getActions(this.state.data)
        : form.actions
    );
  }

  canShowRevisions() {
    return (
      this.state &&
      this.state.revisionEnabled &&
      this.state.revisionEnabled === true &&
      this.getEntityId()
    );
  }

  canSave() {
    let form = this.getEntityForm();
    return optional(
      form.canSave,
      hasCrudPermissions(this.getEntitySavePermissions())
    );
  }

  getEntitySavePermissions() {
    return [this.getEntity() + ":" + Permission.SAVE];
  }

  getEntityListPermissions() {
    return [this.getEntity() + ":" + Permission.LIST];
  }

  getPermittedActions() {
    return _.filter(
      this.getActions(),
      (a) => hasCrudPermissions(a.permissions) === true
    );
  }

  canCancel() {
    let descriptor = this.getDescriptor();
    return _.isFunction(descriptor.canCancel)
      ? descriptor.canCancel(this.state.data)
      : true;
  }

  getTitle() {
    let entity = this.getEntity();
    let form = this.getEntityForm();
    let title = _.isFunction(form.getTitle)
      ? form.getTitle(this.state.data, this.props.params)
      : null;

    if (title) return title;
    else {
      let items = [];
      let gridTitle = this.getEntityGrid().title;
      let data = this.state && this.state.data ? this.state.data : {};
      let parentEntity = this.props?.params?.parentEntity ?? "";
      items.push({
        title: _.isEqual(parentEntity, "") ? gridTitle : M(parentEntity),
        url: this.getGridUrl(data),
      });
      if (data.id && data.id !== "new") {
        items.push({
          title:
            M(["edit", entity]) +
            " " +
            " <b>" +
            this.generateDataDescription(data) +
            "</b>",
        });
      } else {
        items.push({ title: M(["create", entity]) });
      }
      return items;
    }
  }

  generateDataDescription(data) {
    return data ? data.name ?? data.description ?? data.title ?? "" : "";
  }

  getSubtitle() {
    let form = this.getEntityForm();
    return form.subtitle;
  }

  getDescriptor() {
    let form = this.getEntityForm();
    return form.descriptor;
  }

  getFormComponent() {
    let form = this.getEntityForm();
    return optional(
      () => form.component,
      () => Form
    );
  }

  getPage() {
    return this.props.section ?? PAGES.SETTINGS;
  }

  getExtraContentBeforeForm() {
    const formRef = this.refs.form;
    if (!formRef) {
      return null;
    }
    const model = formRef.model;
    const form = this.getEntityForm();

    if (_.isFunction(form.extraContentBeforeForm)) {
      return form.extraContentBeforeForm(model) ?? null;
    }
    return null;
  }

  render() {
    let title = this.getTitle();
    let subtitle = this.getSubtitle();
    let actions = this.getActions();
    let descriptor = this.getDescriptor();
    let component = this.getFormComponent();
    let selectedTab = this.props.params.selectedTab;
    let entity = this.getEntity();
    let page = this.getPage();

    return (
      <Layout page={page} activeMenuItem={this.getEntity()}>
        <HeaderBlockWithBreadcrumbs
          className="entity-form__subheader entity-form__subheader--fixed"
          title={title}
          subtitle={subtitle}
          actions={actions}
        />

        {this.getExtraContentBeforeForm()}

        {React.createElement(component, {
          ref: "form",
          entity: entity,
          descriptor: descriptor,
          data: this.state.data,
          selectedTab: selectedTab,
          onSubmit: this.onSubmit.bind(this),
          onCancel: this.onCancel.bind(this),
        })}
      </Layout>
    );
  }
}

export default EntityForm;
