import React from "react";
import _ from "underscore";
import { isNotEmpty } from "../../framework/utils";
import { uuid } from "../../utils/lang";

export const DROPDOWN_ALIGNMENT = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
};

export class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  render() {
    const { className: _className, alignment } = this.props.options ?? {},
      className = "dropdown" + (isNotEmpty(_className) ? " " + _className : ""),
      items = this.props.items ?? [],
      Child = React.Children.only(this.props.children);

    let dropdownClassName = "dropdown-menu";

    if (isNotEmpty(alignment)) {
      dropdownClassName += " dropdown-menu-" + alignment;
    }

    return (
      <div className={className}>
        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {Child}
        </div>
        <div className={dropdownClassName}>
          {_.map(items, (item, index) => {
            const key = "dropdown-" + this.uuid + "_item-" + index;
            return (
              <div key={key} className="dropdown-item">
                {item}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export function withDropdown(Component, _items, options) {
  return (props) => {
    return (
      <Dropdown items={_items} options={options}>
        <Component {...props} />
      </Dropdown>
    );
  };
}
