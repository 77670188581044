import { check, sanitize } from "../../libs/validator";
import M from "../../strings";
import { ValuesSelectContainer } from "../components/containers";
import SubGridControl from "../components/controls/subGridControl";
import WeeklyAvailabilityControl from "../components/controls/weeklyAvailabilityControl";
import { Area, AreaNoCard, DateTime, Text } from "../components/forms";
import { DateCell, TextCell } from "../components/grids";
import MultiTenantArea from "./multiTenantArea";
import multiTenantCells from "./multiTenantCells";

export default () => ({
  grid: {
    title: M("availability"),
    quickSearchEnabled: true,
    descriptor: {
      columns: [
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "type",
          header: M("type"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        ...multiTenantCells(),
      ],
    },
  },
  form: {
    title: M("editAvailability"),
    descriptor: {
      areas: [
        MultiTenantArea,
        {
          title: M("generalInfo"),
          fields: [
            {
              property: "name",
              control: Text,
              label: M("name"),
              placeholder: M("name"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "type",
              control: ValuesSelectContainer,
              label: M("type"),
              props: {
                id: "availability_type",
                collection: "availability-types",
                mode: "single",
                allowNull: false,
                multiple: false,
              },
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
          ],
        },
        {
          title: null,
          fields: [
            {
              property: "periods",
              control: SubGridControl,
              props: {
                isSelectionWithCheck: false,
                gridDescriptor: {
                  columns: [
                    { property: "start", header: M("start"), cell: DateCell },
                    { property: "end", header: M("end"), cell: DateCell },
                  ],
                },
                formDescriptor: {
                  fields: [
                    {
                      property: "start",
                      control: DateTime,
                      label: M("start"),
                      validator: (value) => check(value).notEmpty(),
                      props: {
                        isLocalDate: true,
                      },
                    },
                    {
                      property: "end",
                      control: DateTime,
                      label: M("end"),
                      validator: (value) => check(value).notEmpty(),
                      props: {
                        isLocalDate: true,
                      },
                    },
                    {
                      property: "days",
                      control: WeeklyAvailabilityControl,
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
  },
});
