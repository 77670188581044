import { dispatch } from "../aj";
import { createAsyncAction } from "../utils/ajex";
import Services from "../api/services";
import {
  CALENDAR_LOAD_BOOKINGS,
  CALENDAR_LOAD_EXPERIENCES,
  CALENDAR_LOAD_EXPERIENCE_PRICINGS,
  CALENDAR_SET_PERIOD,
  CALENDAR_OPEN_BOOKING_DETAILS,
  CALENDAR_CLOSE_BOOKING_DETAILS,
  CALENDAR_FORCE_CANCEL_BOOKING,
} from "./types";
import _ from "underscore";
import M from "../strings";
import * as responses from "../api/responses";
import { localDateToIsoString } from "../utils/date";
import { hideUnobtrusiveLoader, showUnobtrusiveLoader } from "../plugins";

export const loadBookings = createAsyncAction(
  CALENDAR_LOAD_BOOKINGS,
  async ({ from, to }) => {
    dispatch({
      type: CALENDAR_LOAD_BOOKINGS,
    });

    showUnobtrusiveLoader();
    try {
      const response = await Services.get("bookings-calendar/bookings", {
        from: localDateToIsoString(from),
        to: localDateToIsoString(to),
      });
      if (response.responseCode !== responses.OK) {
        throw response.responseCode;
      }
      loadBookings.complete({ bookings: response.bookings });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      loadBookings.fail();
    } finally {
      hideUnobtrusiveLoader();
    }
  }
);

export const loadExperiencePricings = createAsyncAction(
  CALENDAR_LOAD_EXPERIENCE_PRICINGS,
  async ({ experienceId, from, to }) => {
    if (_.isEmpty(experienceId)) {
      alert(M("problemOccoured"), M("pleaseSpecifyId"));
      return;
    }

    dispatch({
      type: CALENDAR_LOAD_EXPERIENCE_PRICINGS,
    });

    showUnobtrusiveLoader();
    try {
      const response = await Services.get(
        `experiences/${experienceId}/pricings`,
        {
          from: localDateToIsoString(from),
          to: localDateToIsoString(to),
        }
      );
      if (response.responseCode !== responses.OK) {
        throw response.responseCode;
      }
      loadExperiencePricings.complete({ pricings: response.pricings });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      loadExperiencePricings.fail();
    } finally {
      hideUnobtrusiveLoader();
    }
  }
);

export const loadExperiences = createAsyncAction(
  CALENDAR_LOAD_EXPERIENCES,
  async () => {
    dispatch({
      type: CALENDAR_LOAD_EXPERIENCES,
    });

    showUnobtrusiveLoader();
    try {
      const response = await Services.get(`bookings-calendar/experiences`);
      if (response.responseCode !== responses.OK) {
        throw response.responseCode;
      }
      loadExperiences.complete({ experiences: response.value });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      loadExperiences.fail();
    } finally {
      hideUnobtrusiveLoader();
    }
  }
);

export const openBookingDetails = createAsyncAction(
  CALENDAR_OPEN_BOOKING_DETAILS,
  async ({ bookingId }) => {
    dispatch({
      type: CALENDAR_OPEN_BOOKING_DETAILS,
      bookingId,
    });

    showUnobtrusiveLoader();
    try {
      const response = await Services.get(
        `bookings-calendar/bookings/${bookingId}`
      );
      if (response.responseCode !== responses.OK) {
        throw response.responseCode;
      }
      openBookingDetails.complete({ booking: response.booking });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      openBookingDetails.fail();
    } finally {
      hideUnobtrusiveLoader();
    }
  }
);

export const forceCancelBooking = createAsyncAction(
  CALENDAR_FORCE_CANCEL_BOOKING,
  async ({ bookingId }) => {
    dispatch({
      type: CALENDAR_FORCE_CANCEL_BOOKING,
      bookingId,
    });

    showUnobtrusiveLoader();
    try {
      const response = await Services.delete(
        `bookings-calendar/bookings/${bookingId}`
      );
      if (response.responseCode !== responses.OK) {
        throw response.responseCode;
      }
      forceCancelBooking.complete({ bookingId });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      forceCancelBooking.fail();
    } finally {
      hideUnobtrusiveLoader();
    }
  }
);

export function closeBookingDetails() {
  dispatch({
    type: CALENDAR_CLOSE_BOOKING_DETAILS,
  });
}

export function setPeriod(from, to) {
  dispatch({ type: CALENDAR_SET_PERIOD, period: { from, to } });
}
