import React from "react";
import { TextCell, DateCell, CheckCell } from "../components/grids";
import { Image, Mail, Text, YesNo } from "../components/forms";
import {
  EntitiesLookupContainer,
  EntitiesSelectContainer,
} from "../components/containers";
import M from "../../strings";
import { getLoggedUser, hasRole, isSuperuser } from "../../api/session";
import * as ui from "../utils/ui";
import * as _query from "../../framework/query";
import { ACTION_TYPES } from "../components/actions";
import _ from "underscore";
import { SEARCH_FORM_DATE_DESCRIPTOR } from "../../model/searchForms";
import {
  activeSearchForm,
  companySearchForm,
} from "../screens/entities/commonFields";
import { confirm } from "../../plugins";

export default () => ({
  grid: {
    quickSearchEnabled: true,
    title: M("companyAdministratorsList"),
    //subtitle: M("usersListDescription"),
    descriptor: {
      columns: [
        {
          property: "email",
          header: M("email"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "registrationDate",
          header: M("registrationDate"),
          cell: DateCell,
          sortable: true,
          searchable: true,
          searchForm: SEARCH_FORM_DATE_DESCRIPTOR("registrationDate"),
        },
        {
          property: "company.name",
          header: M("company"),
          cell: TextCell,
          sortable: true,
          searchable: true,
          searchForm: companySearchForm,
        },
        {
          property: "active",
          header: M("active"),
          cell: CheckCell,
          sortable: true,
          searchable: true,
          searchForm: activeSearchForm,
        },
      ],
    },
  },
  form: {
    getTitle(data, params) {
      return [
        { title: M("usersList"), url: "/entities/companyUser" },
        {
          title:
            !data || !data.id
              ? M(["create", "companyUser"])
              : M(["edit", "companyUser"]) + " <b>" + data.email + "</b>",
        },
      ];
    },
    getActions(data) {
      let actions = ["save", "back", "save-go-back", "revisions"];
      if (hasRole("ROLE_ADMIN", "ROLE_COMPANY")) {
        if (data && data.id) {
          actions.push({
            type: ACTION_TYPES.ICON,
            icon: "brush",
            title: "Reset password",
            action: () => {
              confirm(
                M("confirm"),
                "Verrà impostata una nuova password ed inviata all'indirizzo mail dell'utente"
              )
                .then(() => {
                  resetUserPassword(data);
                  if (data.id === getLoggedUser().id) {
                    confirm(
                      M("confirm"),
                      "La tua password è stata resettata. Dovrai eseguire un nuovo accesso"
                    ).then((res) => {
                      logout();
                      ui.navigate("/login");
                    });
                  }
                })
                .catch((e) => {
                  logger.i(e);
                });
            },
          });
        }
      }
      return actions;
    },
    descriptor: {
      onModelLoadFirstTime: (model) => {
        model.on("property:change", (property, value) => {
          if (property === "active" || property === "roles") {
            model.invalidateForm();
          }

          if (property === "company") {
            model.set("companyId", value?.id ?? null);
          }
        });
      },

      areas: [
        {
          title: M("generalInformations"),
          subtitle: null,
          fields: [
            {
              property: "name",
              control: Text,
              label: M("name"),
              placeholder: M("name"),
            },
            {
              property: "company",
              visibility: (model) => isSuperuser(),
              control: EntitiesLookupContainer,
              label: M("company"),
              props: {
                id: "companyUser_company",
                entity: "company",
                mode: "single",
                selectionGrid: {
                  columns: [
                    {
                      property: "name",
                      header: M("name"),
                      cell: TextCell,
                      sortable: true,
                      searchable: true,
                    },
                    {
                      property: "vatCode",
                      header: M("vatCode"),
                      cell: TextCell,
                      sortable: true,
                      searchable: true,
                    },
                    {
                      property: "pec",
                      header: M("pec"),
                      cell: TextCell,
                      sortable: true,
                      searchable: true,
                    },
                    {
                      property: "phoneNumber",
                      header: M("phoneNumber"),
                      cell: TextCell,
                      sortable: true,
                      searchable: true,
                    },
                  ],
                },
                popupGrid: {
                  columns: [
                    {
                      property: "name",
                      header: M("name"),
                      cell: TextCell,
                      sortable: true,
                      searchable: true,
                    },
                    {
                      property: "vatCode",
                      header: M("vatCode"),
                      cell: TextCell,
                      sortable: true,
                      searchable: true,
                    },
                    {
                      property: "pec",
                      header: M("pec"),
                      cell: TextCell,
                      sortable: true,
                      searchable: true,
                    },
                    {
                      property: "phoneNumber",
                      header: M("phoneNumber"),
                      cell: TextCell,
                      sortable: true,
                      searchable: true,
                    },
                  ],
                },
              },
            },
            {
              property: "facilityId",
              control: EntitiesSelectContainer,
              label: M("selectedFacility"),
              props: {
                id: "user_facility",
                entity: "facility",
                allowSearch: true,
                parentProperty: "companyId",
              },
            },
            {
              property: "_image",
              control: Image,
              label: M("image"),
            },
          ],
        },
        {
          title: "Account",
          fields: [
            {
              property: "email",
              control: Mail,
              label: M("email"),
              placeholder: M("mailAddress"),
            },
            {
              property: "active",
              control: YesNo,
              label: M("active"),
            },
          ],
        },
      ],
    },
  },
});
