import { useCallback, useEffect, useState } from "react";

export function useModelValue(model, property) {
  const [value, setValue] = useState(model.get(property));
  const onModelPropertyChange = useCallback((changedProperty, value) => {
    if (changedProperty === property) {
      setValue(model.get(property));
    }
  });
  model.once("load", () => {
    setValue(model.get(property));
  });

  useEffect(() => {
    model.on("property:change", onModelPropertyChange);

    return () => model.off("property:change", onModelPropertyChange);
  });

  const setModelValue = (value) => {
    model.set(property, value);
  };

  return [value, setModelValue];
}
