import React, { useMemo } from "react";
import { optional } from "../../utils/lang";
import { isEnter } from "../utils/keyboard";
import * as ui from "../utils/ui";
import _ from "underscore";
import ReactDOM from "react-dom";
import { isNotEmpty } from "../../framework/utils";
import { Actions } from "./actions";
import cn from "classnames";
import Collapse from "./collapse";

export class ComponentWithTooltips extends React.Component {
  constructor(props) {
    super(props);
  }

  initTooltip() {
    _.each(this.getAllTooltipItems(), (i) =>
      $(i).tooltip({ trigger: "hover" })
    );
  }

  getAllTooltipItems() {
    const me = ReactDOM.findDOMNode(this);
    let items = [];
    $(me)
      .find('[data-toggle="tooltip"]')
      .each(function () {
        items.push(this);
      });
    return items;
  }

  destroyAllTooltips() {
    _.each(this.getAllTooltipItems(), (i) => $(i).tooltip("dispose"));
  }

  componentDidMount() {
    this.initTooltip();
  }

  componentDidUpdate() {
    this.destroyAllTooltips();
    this.initTooltip();
  }

  componentWillUnmount() {
    this.destroyAllTooltips();
  }
}

export function Card(props) {
  const { title, subtitle, className, padding, collapsible, noPadding } = props,
    cardClass = className ?? "card",
    bodyClass = cn("card-body", { "card-padding": padding, "p-0": noPadding }),
    actions = useMemo(() => {
      const actions = [];
      if (isNotEmpty(props.actions) & _.isArray(props.actions)) {
        actions.push(...props.actions);
      }
      if (collapsible) {
        actions.push({
          icon: "expand_more",
          className: "card-collapse__toggle",
        });
      }
      return actions;
    }, [props.actions, collapsible]),
    titleProps = _.pick(props, "inverseHeader", "actions"),
    subtitleProps = _.pick(props, "subtitle", "inverseHeader");

  return (
    <div className={cardClass}>
      <div className={bodyClass}>
        {collapsible ? (
          <Collapse
            header={
              <CardTitle {...titleProps} actions={actions}>
                {title}
              </CardTitle>
            }
            open={props.open}
          >
            {isNotEmpty(props.subtitle) && (
              <CardSubtitle {...subtitleProps}>{subtitle}</CardSubtitle>
            )}
            <CardContent>{props.children}</CardContent>
          </Collapse>
        ) : (
          <>
            {isNotEmpty(props.title) && (
              <CardTitle {...titleProps}>{title}</CardTitle>
            )}
            {isNotEmpty(props.subtitle) && (
              <CardSubtitle {...subtitleProps}>{subtitle}</CardSubtitle>
            )}
            <CardContent>{props.children}</CardContent>
          </>
        )}
      </div>
    </div>
  );
}

function CardTitle(props) {
  const { inverseHeader } = props,
    actions = props.actions ?? [],
    className = cn("card-title d-flex", props.className, {
      "card-title-inverse": inverseHeader,
    }),
    passProps = _.omit(props, "title", "inverseHeader", "actions", "className");

  return (
    <h4 {...passProps} className={className}>
      {isNotEmpty(props.children) && <div>{props.children}</div>}
      <div className="flex-grow-1 flex-shrink-1" />
      {isNotEmpty(actions) && <Actions actions={actions} />}
    </h4>
  );
}

function CardSubtitle(props) {
  const { inverseHeader } = props,
    className = cn("card-subtitle", props.className, {
      "card-subtitle-inverse": inverseHeader,
    }),
    passProps = _.omit(props, "subtitle", "inverseHeader", "className");

  return (
    <h6 {...passProps} className={className}>
      {props.children}
    </h6>
  );
}

function CardContent(props) {
  return (
    <div {...props} className={cn("card-text", props.className)}>
      {props.children}
    </div>
  );
}

export class FloatingButton extends React.Component {
  onClick() {
    if (_.isFunction(this.props.onClick)) {
      this.props.onClick();
    }
  }

  render() {
    return (
      <button
        type="button"
        className="btn btn--action btn-primary"
        onClick={this.onClick.bind(this)}
      >
        <i className={this.props.icon}></i>
      </button>
    );
  }
}

export class EditableText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: _.isEmpty(props.value),
      value: props.value,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      editing: _.isEmpty(newProps.value),
      value: newProps.value,
    });
  }

  onBlur() {
    this.setState({ editing: false, value: this.state.lastValue });
  }

  onValueChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState(
      _.assign(this.state, { editing: true, value: e.target.value })
    );
  }

  onKeyDown(e) {
    if (isEnter(e.which)) {
      e.preventDefault();
      e.stopPropagation();

      this.setState(
        _.assign(this.state, { editing: false, lastValue: this.state.value })
      );

      if (_.isFunction(this.props.onChange)) {
        this.props.onChange(this.state.value);
      }
    }
  }

  edit() {
    this.setState(
      _.assign(this.state, { editing: true, lastValue: this.state.value })
    );
  }

  render() {
    let className = this.props.className;
    return this.state.editing || _.isEmpty(this.state.value) ? (
      <div className={"fg-line editable-text " + optional(className, "")}>
        <input
          ref="name"
          type="text"
          className="form-control"
          onKeyDown={this.onKeyDown.bind(this)}
          onChange={this.onValueChange.bind(this)}
          value={optional(this.state.value, "")}
          placeholder={this.props.placeholder}
          autoFocus="autoFocus"
          onBlur={this.onBlur.bind(this)}
        />
      </div>
    ) : (
      <span className={optional(className, "")} onClick={this.edit.bind(this)}>
        {this.state.value}
      </span>
    );
  }
}

export class HeaderBlock extends React.Component {
  render() {
    const { title, subtitle, actions, className } = this.props;
    let _className = "header-block";

    if (isNotEmpty(className)) {
      _className += " " + className;
    }

    return (
      <header className={_className}>
        <div className="header-block__text">
          {!_.isEmpty(title) && (
            <div className="header-block__title">{title}</div>
          )}

          {!_.isEmpty(subtitle) && (
            <div className="header-block__subtitle">{subtitle}</div>
          )}
        </div>

        {!_.isEmpty(actions) && (
          <div className="header-block__actions">
            <Actions actions={actions} />
          </div>
        )}
      </header>
    );
  }
}

export class HeaderBlockWithBreadcrumbs extends React.Component {
  render() {
    let title;
    if (_.isArray(this.props.title)) {
      title = this.props.title.map((item, i) => (
        <BreadcrumbItem
          key={i}
          title={item.title}
          url={item.url}
          first={i == 0}
          last={i < this.props.title.length - 1}
        />
      ));
    } else {
      title = (
        <span dangerouslySetInnerHTML={{ __html: this.props.title }}></span>
      );
    }

    return <HeaderBlock {...this.props} title={title} />;
  }
}

class BreadcrumbItem extends React.Component {
  constructor(props) {
    super(props);
  }

  onClick() {
    if (this.props.url) {
      ui.navigate(this.props.url);
    }
  }

  render() {
    let style = { marginLeft: !this.props.first ? "10px" : "px" };
    if (this.props.url) style.cursor = "pointer";

    let iconStyle = {
      marginLeft: "10px",
    };
    return (
      <span onClick={this.onClick.bind(this)} style={style}>
        <span dangerouslySetInnerHTML={{ __html: this.props.title }}></span>
        {this.props.last && (
          <i style={iconStyle} className="zmdi zmdi-caret-right" />
        )}
      </span>
    );
  }
}
