import React from "react";
import _ from "underscore";
import { CustomIcon, MaterialIcon, MaterialSymbol } from "./icons";
import { isEmpty, isNotEmpty } from "../../framework/utils";
import { hasCrudPermissions, hasRole } from "../../api/session";
import { optional, parseBoolean, uuid } from "../../utils/lang";
import { Dropdown, withDropdown } from "./dropdown";
import M from "../../strings";

export const ACTION_TYPES = {
  ICON: "icon",
  DROPDOWN: "dropdown",
  BUTTON: "button",
  CUSTOM: "custom",
  SELECT: "select",
};

export const DEFAULT_ACTIONS = {
  REFRESH: "refresh",
  CREATE: "create",
  DELETE: "delete",
  SELECT_ALL: "selectAll",
  SAVE: "save",
  BACK: "back",
  REVISIONS: "revisions",
};

export const ICON_TYPES = {
  MATERIAL_ICON: "materialIcon",
  MATERIAL_SYMBOL: "materialSymbol",
  CUSTOM_ICONS: "customIcons",
};

function getIconComponent(type) {
  switch (type) {
    case ICON_TYPES.MATERIAL_SYMBOL:
      return MaterialSymbol;
    case ICON_TYPES.MATERIAL_ICON:
      return MaterialIcon;
    case ICON_TYPES.CUSTOM_ICONS:
      return CustomIcon;
    default:
      return MaterialIcon;
  }
}

export class ActionsMatcher {
  constructor(defaultActions) {
    this.defaultActions = defaultActions;
  }

  match(userActions) {
    let actions = [];

    if (userActions) {
      if (!_.isArray(userActions)) {
        throw new Error("grid.actions must be an array but is " + userActions);
      }

      _.each(userActions, (a) => {
        if (_.isObject(a)) {
          actions.push(a);
        } else if (typeof a === "string") {
          let defaultAction = _.find(this.defaultActions, (d) => d.id === a);
          if (!_.isEmpty(defaultAction)) {
            actions.push(defaultAction);
          } else {
            logger.w("Default action not found: " + a);
          }
        }
      });
    } else {
      actions = this.defaultActions;
    }

    return actions;
  }
}

export class Actions extends React.Component {
  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  mapActions() {
    const actions = _.filter(
      optional(this.props.actions, []),
      (a) =>
        hasRole(a.permissions ?? []) === true ||
        hasCrudPermissions(a.permissions ?? []) === true
    );
    return _.map(actions, (a, index) => {
      const key = "actions-" + this.uuid + "_action-" + a.id;

      return <Action key={key} {...a} />;
    });
  }

  render() {
    const className =
        "actions" +
        (isNotEmpty(this.props.className) ? " " + this.props.className : ""),
      actions = this.mapActions();
    return <div className={className}>{actions}</div>;
  }
}

class Action extends React.Component {
  getComponent() {
    const { type, component } = this.props;
    switch (type) {
      case ACTION_TYPES.CUSTOM:
        return component;
      case ACTION_TYPES.BUTTON:
        return ButtonAction;
      case ACTION_TYPES.DROPDOWN:
        return DropdownActionButton;
      case ACTION_TYPES.SELECT:
        return Select;
      case ACTION_TYPES.ICON:
      default:
        return TooltipActionButton;
    }
  }

  sanitizeProps() {
    const { type, className } = this.props;
    switch (type) {
      case ACTION_TYPES.CUSTOM:
        return _.extend({}, this.props, {
          className: isEmpty(className)
            ? "actions__item"
            : className + " actions__item",
        });
      default:
        return { ...this.props };
    }
  }

  render() {
    const Component = this.getComponent(),
      _props = this.sanitizeProps();

    return <Component {..._props} />;
  }
}

export class TooltipActionButton extends React.Component {
  onItemClick() {
    const action = this.props.action,
      _arguments = this.props.arguments;

    if (action && _.isFunction(action)) {
      action.apply(this, _arguments);
    }
  }

  getComponent() {
    return getIconComponent(this.props.iconType);
  }

  render() {
    const Component = this.getComponent(),
      className =
        "actions__item" +
        (isNotEmpty(this.props.className) ? " " + this.props.className : ""),
      _props = _.omit(this.props, "action", "arguments", "iconType");
    return (
      <Component
        {..._props}
        className={className}
        onClick={this.onItemClick.bind(this)}
      />
    );
  }
}

class DropdownActionButton extends React.Component {
  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  onItemClick(item) {
    const action = item.action,
      _arguments = item.arguments;

    if (action && _.isFunction(action)) {
      action.apply(this, _arguments);
    }
  }

  getItems() {
    const items = _.filter(
      optional(this.props.items, []),
      (item) =>
        hasRole(item.permissions) === true ||
        hasCrudPermissions(item.permissions) === true
    );

    return _.map(items, (item) => {
      const { icon, title, iconType } = item,
        Component = getIconComponent(iconType);

      return (
        <div
          onClick={this.onItemClick.bind(this, item)}
          className="actions__dropdown-item"
        >
          <Component icon={icon} />
          {title}
        </div>
      );
    });
  }

  getComponent() {
    return getIconComponent(this.props.iconType);
  }

  render() {
    const items = this.getItems(),
      className =
        "actions__item actions__item--dropdown" +
        (isNotEmpty(this.props.className) ? " " + this.props.className : ""),
      Component = this.getComponent();
    //Component = withDropdown(this.getComponent(), items);

    return (
      <div className={className}>
        <Dropdown items={items}>
          <Component
            {..._.omit(this.props, "items", "icon", "className")}
            icon={this.props.icon || "more_vert"}
            className="actions__dropdown-trigger"
          />
        </Dropdown>
      </div>
    );
  }
}

class ButtonAction extends React.Component {
  onItemClick() {
    const action = this.props.action,
      _arguments = this.props.arguments;

    if (action && _.isFunction(action)) {
      action.apply(this, _arguments);
    }
  }

  render() {
    const text = this.props.title,
      disabled = parseBoolean(optional(this.props.disabled, false)),
      className =
        "actions__item actions__item--btn btn" +
        (isNotEmpty(this.props.className)
          ? " " + this.props.className
          : " btn-primary");
    return (
      <button
        disabled={disabled}
        type="button"
        className={className}
        onClick={this.onItemClick.bind(this)}
      >
        {text}
      </button>
    );
  }
}

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  getItems() {
    const items = this.props.items;
    return _.map(items, (item) => {
      return (
        <div
          onClick={this.onItemClick.bind(this, item)}
          className="actions__dropdown-item"
        >
          {M(item.title)}
        </div>
      );
    });
  }

  onItemClick(item) {
    if (_.isFunction(item.action)) {
      item.action(item);
    }
  }

  render() {
    const items = this.getItems(),
      className =
        "actions__item actions__item--btn btn " +
        (this.props.className ?? "btn-primary ");

    return (
      <div className="d-inline-block">
        <Dropdown items={items}>
          <div className={className}>
            {M(this.props.title)}
            <span className="material-icons-outlined">keyboard_arrow_down</span>
          </div>
        </Dropdown>
      </div>
    );
  }
}
