import React from "react";
import _ from "underscore";
import { uuid, format } from "../../../utils/lang";
import { Cell } from "../grids";
import { sanitizedLocalizedObject } from "../../utils/localizedObject";

function withLocalizedCell(Component) {
  return class LocalizedCell extends Cell {
    constructor(props) {
      super(props);
      this.uuid = uuid();
    }

    getValue() {
      return sanitizedLocalizedObject(super.getValue());
    }

    render() {
      const values = this.getValue().values;
      return (
        <div className="grid-localized-cell-container">
          {_.map(values, (v, index) => {
            const key = format("{0}_{1}", this.uuid, index);
            return <Component key={key} {...this.props} {...v} />;
          })}
        </div>
      );
    }
  };
}

function _LocalizedTextCell(props) {
  const { lang, value } = props;
  return (
    <div className="m-b-4 m-t-4">
      <span className="text-uppercase font-weight-bold">{lang}:</span>
      <span className="m-l-4">{value}</span>
    </div>
  );
}

export const LocalizedTextCell = withLocalizedCell(_LocalizedTextCell);
