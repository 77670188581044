import services from "../../api/services";
import { getAllowedLanguages, isSuperuser } from "../../api/session";
import { DataSource } from "../../utils/datasource";

export default function (model) {
  if (isSuperuser()) {
    let dataSource = new DataSource();

    async function load() {
      const facilityId = model.get("facilityId");
      if (!facilityId) {
        return;
      }
      const response = await services.get(`facilities/${facilityId}/languages`);
      dataSource.setData(response.value);
    }

    model.on("property:change", (mp, value) => {
      if (mp === "facilityId") {
        load();
      }
    });

    model.once("load", () => {
      load();
    });

    load();

    return dataSource;
  }

  return new DataSource(getAllowedLanguages());
}
