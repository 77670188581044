import _ from "underscore";
import React from "react";
import M from "../../../strings";
import {
  deleteEntities,
  freeEntities,
  loadEntities,
  updateQuery,
} from "../../../actions/entities";
import { ActionsMatcher } from "../../components/common";
import { Grid, resultToGridData } from "../../components/grids";
import * as query from "../../../framework/query";
import { format, optional } from "../../../utils/lang";
import entities, { getEntityData } from "../../entities";
import * as ui from "../../utils/ui";
import { Permission } from "../../../api/session";
import { connectDiscriminated } from "../../../utils/aj-react";
import { EntitiesStore } from "../../../stores/entities";

export class EntitiesDialog extends React.Component {
  constructor(props) {
    super(props);

    if (_.isEmpty(this.getEntity())) {
      throw new Error("Please specify entity for dialog");
    }
    this.discriminator = "entity_dialog_" + this.getEntity();
    let _query = this.getInitialQuery();
    this.state = { grid: null, result: null, query: _query };
    this.state.query.on("change", () => {
      updateQuery({
        discriminator: this.discriminator,
        query: this.state.query,
      });
      this.onQueryChanged();
    });

    connectDiscriminated(this.discriminator, this, [EntitiesStore]);
  }

  getInitialQuery() {
    let _query;

    if (!_query) {
      const propQuery = this.props.initialQuery;
      if (_.isFunction(propQuery)) {
        _query = propQuery();
      } else {
        _query = propQuery;
      }
    }

    if (!_query) _query = getEntityData(this.getEntity()).grid.initialQuery;

    if (_.isFunction(getEntityData(this.getEntity()).grid.initialQuery)) {
      _query = getEntityData(this.getEntity()).grid.initialQuery();
    }
    if (!_query) {
      _query = query.create();
      _query.page = 1;
      _query.rowsPerPage = 50;
    }

    return _query;
  }

  getEntity() {
    return this.props.entity;
  }

  componentDidMount() {
    loadEntities({
      discriminator: this.discriminator,
      entity: this.getEntity(),
      query: this.state.query,
    });
  }

  componentWillUnmount() {
    freeEntities({ discriminator: this.discriminator });
  }

  onQueryChanged() {
    loadEntities({
      discriminator: this.discriminator,
      entity: this.getEntity(),
      query: this.state.query,
    });
  }

  editEntity(data) {
    if (!this.canEdit()) {
      return;
    }

    ui.navigate(this.getEditUrl(data));
  }

  createEntity() {
    if (!this.canCreate()) {
      return;
    }

    ui.navigate(this.getCreateUrl());
  }

  getCreateUrl() {
    let grid = getEntityData(this.getEntity()).grid;
    let createUrl = grid.createUrl;
    if (_.isFunction(createUrl)) {
      createUrl = createUrl();
    }
    return optional(createUrl, `/entities/${this.getEntity()}/new`);
  }

  getEditUrl(data) {
    let grid = getEntityData(this.getEntity()).grid;
    if (_.isFunction(grid.editUrl)) {
      return format(grid.editUrl(data));
    } else if (!_.isEmpty(grid.editUrl)) {
      return format(grid.editUrl, data.id);
    } else {
      return `/entities/${this.getEntity()}/${data.id}`;
    }
  }

  getDeleteMessage() {
    let message = format(
      M("entityDeleteConfirm"),
      this.refs.grid.getSelection().length
    );
    let entityMessage = this.getGrid().deleteMessage;
    if (entityMessage) message = format("{0}\n{1}", message, entityMessage);
    return message;
  }

  deleteEntities() {
    if (!this.canDelete()) {
      return;
    }

    let selection = this.refs.grid.getSelection();
    if (_.isEmpty(selection)) {
      return;
    }

    swal({
      title: M("confirm"),
      text: this.getDeleteMessage(),
      showCancelButton: true,
    }).then((res) => {
      if (res.value) {
        deleteEntities({
          discriminator: this.discriminator,
          entity: this.getEntity(),
          ids: selection.map((s) => s.id),
        });
      }
    });
  }

  onGridRowDoubleClick(row) {
    if (_.isFunction(this.props.onRowDoubleClick)) {
      this.props.onRowDoubleClick(row);
    }
  }

  getTitle() {
    let grid = getEntityData(this.getEntity()).grid;
    return optional(grid.title, "List");
  }

  getSubtitle() {
    let grid = getEntityData(this.getEntity()).grid;
    return grid.subtitle;
  }

  getActions() {
    let defaultActions = [
      {
        id: "refresh",
        type: "button",
        icon: "zmdi zmdi-refresh-alt",
        tooltip: M("refresh"),
        permissions: [this.getEntity() + ":" + Permission.LIST],
        action: () => {
          loadEntities({
            discriminator: this.discriminator,
            entity: this.getEntity(),
            query: this.state.query,
          });
        },
      },
    ];

    if (this.canCreate()) {
      defaultActions.push({
        id: "create",
        type: "button",
        icon: "zmdi zmdi-plus",
        tooltip: M("create"),
        permissions: [this.getEntity() + ":" + Permission.NEW],
        action: () => {
          this.createEntity();
        },
      });
    }

    if (this.canDelete()) {
      defaultActions.push({
        id: "delete",
        type: "button",
        icon: "zmdi zmdi-delete",
        tooltip: M("delete"),
        permissions: [this.getEntity() + ":" + Permission.DELETE],
        action: () => {
          this.deleteEntities();
        },
      });
    }

    defaultActions.push({
      id: "selectAll",
      type: "button",
      icon: "zmdi zmdi-select-all",
      tooltip: M("selectAll"),
      action: () => {
        this.refs.grid.toggleSelectAll();
      },
    });

    let grid = getEntityData(this.getEntity()).grid;
    let matcher = new ActionsMatcher(defaultActions);
    return matcher.match(
      _.isFunction(grid.getActions) ? grid.getActions() : grid.actions
    );
  }

  getGrid() {
    return this.refs.grid;
  }

  getDescriptor() {
    let grid = getEntityData(this.getEntity()).grid;
    return grid.descriptor;
  }

  getData() {
    let data = this.state.result;
    if (this.props.filterData && _.isFunction(this.props.filterData)) {
      data = this.props.filterData(data);
    }
    return resultToGridData(data);
  }

  isQuickSearchEnabled() {
    let grid = getEntityData(this.getEntity()).grid;
    return optional(grid.quickSearchEnabled, false);
  }

  getQuickSearchPlaceholder() {
    let grid = getEntityData(this.getEntity()).grid;
    return optional(grid.quickSearchPlaceholder, "");
  }

  getHeaderVisibleNoResults() {
    let grid = getEntityData(this.getEntity()).grid;
    return optional(grid.headerVisibleNoResults, false);
  }

  getNoResultsText() {
    let grid = getEntityData(this.getEntity()).grid;
    return grid.noResultsText;
  }

  canEdit() {
    return false;
  }

  canCreate() {
    return false;
  }

  canDelete() {
    return false;
  }

  hideFilters() {
    return true;
  }

  filtersVisible() {
    return false;
  }

  render() {
    let descriptor = this.getDescriptor();
    let data = this.getData();

    return (
      <Grid
        ref="grid"
        descriptor={descriptor}
        discriminator={this.discriminator}
        data={data}
        query={this.state.query}
        hideFilters={this.hideFilters()}
        filtersVisible={this.filtersVisible()}
        onRowDoubleClick={this.onGridRowDoubleClick.bind(this)}
        quickSearchEnabled={this.isQuickSearchEnabled()}
        quickSearchPlaceholder={this.getQuickSearchPlaceholder()}
        headerVisibleNoResults={this.getHeaderVisibleNoResults()}
        // selectWithCheck="true"
        showInCard={false}
        noResultsText={this.getNoResultsText()}
      />
    );
  }
}

export default EntitiesDialog;
