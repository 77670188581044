import { CheckCell, DateCell, TextCell } from "../components/grids";
import { Image, Mail, Text, YesNo } from "../components/forms";
import M from "../../strings";
import { getLoggedUser, isSuperuser } from "../../api/session";
import { resetUserPassword } from "../../actions/account";
import * as ui from "../utils/ui";
import { logout } from "../../actions/session";
import { activeSearchForm } from "../screens/entities/commonFields";
import { SEARCH_FORM_DATE_DESCRIPTOR } from "../../model/searchForms";
import { confirm } from "../../plugins";
import { ACTION_TYPES } from "../components/actions";
import _ from "underscore";

export default () => ({
  grid: {
    quickSearchEnabled: true,
    title: M("administratorsList"),
    //subtitle: M("usersListDescription"),
    descriptor: {
      columns: [
        {
          property: "email",
          header: M("email"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "registrationDate",
          header: M("registrationDate"),
          cell: DateCell,
          sortable: true,
          searchable: true,
          searchForm: SEARCH_FORM_DATE_DESCRIPTOR("registrationDate"),
        },
        {
          property: "active",
          header: M("active"),
          cell: CheckCell,
          sortable: true,
          searchable: true,
          searchForm: activeSearchForm,
        },
      ],
    },
  },
  form: {
    getTitle(data, params) {
      return [
        { title: M("usersList"), url: "/entities/adminUser" },
        {
          title:
            !data || !data.id
              ? M(["create", "adminUser"])
              : M(["edit", "adminUser"]) + " <b>" + data.email + "</b>",
        },
      ];
    },
    getActions(data) {
      let actions = ["save", "back", "save-go-back", "revisions"];
      if (isSuperuser()) {
        if (data && data.id) {
          actions.push({
            type: ACTION_TYPES.ICON,
            icon: "brush",
            title: "Reset password",
            action: () => {
              confirm(
                M("confirm"),
                "Verrà impostata una nuova password ed inviata all'indirizzo mail dell'utente"
              )
                .then(() => {
                  resetUserPassword(data);
                  if (data.id === getLoggedUser().id) {
                    confirm(
                      M("confirm"),
                      "La tua password è stata resettata. Dovrai eseguire un nuovo accesso"
                    ).then((res) => {
                      logout();
                      ui.navigate("/login");
                    });
                  }
                })
                .catch((e) => {
                  logger.i(e);
                });
            },
          });
        }
      }
      return actions;
    },
    descriptor: {
      onModelLoadFirstTime: (model) => {
        model.on("property:change", (property, value) => {
          if (property === "active" || property === "roles") {
            model.invalidateForm();
          }
        });
      },

      areas: [
        {
          title: M("generalInformations"),
          subtitle: null,
          collapsible: false,
          fields: [
            {
              property: "name",
              control: Text,
              label: M("name"),
              placeholder: M("name"),
            },
            {
              property: "_image",
              control: Image,
              label: M("image"),
            },
          ],
        },
        {
          title: "Account",
          fields: [
            {
              property: "email",
              control: Mail,
              label: M("email"),
              placeholder: M("mailAddress"),
            },
            {
              property: "active",
              control: YesNo,
              label: M("active"),
            },
          ],
        },
      ],
    },
  },
});
