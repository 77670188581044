"use strict";

import * as http from "../aj/http";
import * as responses from "./responses";
import { getSessionToken } from "./session";
import _ from "underscore";

export function addToken(headers) {
  if (!_.isEmpty(getSessionToken())) {
    return _.assign(headers || {}, {
      Authorization: "Bearer " + getSessionToken(),
    });
  } else {
    return headers;
  }
}

function handleError(reject) {
  return ({ error, errorBody }) => {
    logger.e("Error in request:", error, errorBody);
    try {
      reject(JSON.parse(errorBody));
    } catch (e) {
      reject(responses.ERROR);
    }
  };
}

export function post(url, data, headers = {}) {
  return new Promise((resolve, reject) => {
    http
      .post(url, data, addToken(headers))
      .then((json) => {
        if (_.isEmpty(json)) {
          reject(responses.ERROR);
        } else {
          let response = JSON.parse(json);

          if (responses.OK != response.responseCode) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      })
      .catch(handleError(reject));
  });
}

export function postJson(url, data, headers = {}) {
  return new Promise((resolve, reject) => {
    let json = typeof data == "string" ? data : JSON.stringify(data);
    headers = _.assign(headers, { "Content-Type": "application/json" });
    http
      .post(url, json, addToken(headers))
      .then((json) => {
        if (_.isEmpty(json)) {
          reject(responses.ERROR);
        } else {
          let response = JSON.parse(json);

          if (responses.OK != response.responseCode) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      })
      .catch(handleError(reject));
  });
}

export function get(url, data, headers = {}) {
  return new Promise((resolve, reject) => {
    http
      .get(url, data, addToken(headers))
      .then((json) => {
        if (_.isEmpty(json)) {
          reject(responses.ERROR);
        } else {
          let response = JSON.parse(json);

          if (responses.OK != response.responseCode) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      })
      .catch(handleError(reject));
  });
}

export function delete_(url, data, headers) {
  return new Promise((resolve, reject) => {
    http
      .delete(url, data, addToken(headers))
      .then((json) => {
        if (_.isEmpty(json)) {
          reject(responses.ERROR);
        } else {
          let response = JSON.parse(json);

          if (responses.OK != response.responseCode) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      })
      .catch(handleError(reject));
  });
}

export async function download(url, headers = {}) {
  const response = await fetch(url, { headers: addToken(headers) });
  return await response.blob();
}

export function postMultipart(url, data, headers = {}) {
  return new Promise((resolve, reject) => {
    const options = {
      headers: _.omit(addToken(headers), "Content-Type"), //see https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
      method: "POST",
      body: new FormData(),
    };

    for (let key in data) {
      options.body.append(key, data[key]);
    }

    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(response);
        }
      })
      .catch(handleError(reject));
  });
}
