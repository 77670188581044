import _ from "underscore";
import React from "react";
import M from "../../../strings";
import {
  deleteEntities,
  freeEntities,
  loadEntities,
  updateQuery,
} from "../../../actions/entities";
import { HeaderBlock } from "../common";
import { Grid, resultToGridData } from "../grids";
import * as query from "../../../framework/query";
import { format, optional } from "../../../utils/lang";
import * as ui from "../../utils/ui";
import { Permission } from "../../../api/session";
import { discriminated } from "../../../utils/ajex";
import { connectDiscriminated } from "../../../utils/aj-react";
import { EntitiesStore, SearchStore } from "../../../stores/entities";
import { confirm } from "../../../plugins";
import { Control } from "../forms";
import { getEntityData } from "../../entities";
import { ActionsMatcher, ACTION_TYPES } from "../actions";

class EntitiesGridControl extends Control {
  constructor(props) {
    super(props);

    if (_.isEmpty(this.getEntity())) {
      throw new Error("Please specify entity for form");
    }
    this.discriminator = "entity_grid_control_" + this.getEntity();
    let _query = this.getInitialQuery();
    this.state = { grid: null, result: null, query: _query };
    this.state.query.on("change", () => {
      updateQuery({
        discriminator: this.discriminator,
        query: this.state.query,
      });
      this.onQueryChanged();
    });

    connectDiscriminated(this.discriminator, this, [EntitiesStore]);
  }

  getInitialQuery() {
    const model = this.props.model;
    let _query;

    if (!_query) {
      _query = this.props.initialQuery;
    }

    if (!_query) _query = this.getEntityGrid().initialQuery;

    if (_.isFunction(_query)) {
      _query = _query(model);
    }
    if (!_query) {
      _query = query.create();
      _query.page = 1;
      _query.rowsPerPage = 50;
    }

    const id = this.props.model.get("id") || "NA";
    const parentProperty =
      this.props.parentQueryProperty || this.props.parentProperty;

    return _query.eq(parentProperty, id);
  }

  reload() {
    this.onQueryChanged();
  }

  getEntity() {
    return this.props.entity;
  }

  getEntityGrid() {
    if (this.props.grid) {
      return this.props.grid;
    }

    const entity = this.getEntity(),
      descriptorData = getEntityData(entity),
      entityGrid = descriptorData.grid;
    return entityGrid;
  }

  componentDidMount() {
    loadEntities({
      discriminator: this.discriminator,
      entity: this.getEntity(),
      query: this.state.query,
    });
  }

  componentWillUnmount() {
    freeEntities({ discriminator: this.discriminator });
  }

  onQueryChanged() {
    loadEntities({
      discriminator: this.discriminator,
      entity: this.getEntity(),
      query: this.state.query,
    });
  }

  editEntity(data) {
    if (!this.canEdit()) {
      return;
    }

    ui.navigate(this.getEditUrl(data));
  }

  createEntity() {
    if (!this.canCreate()) {
      return;
    }

    ui.navigate(this.getCreateUrl());
  }

  getCreateUrl() {
    let grid = this.getDescriptor();
    let createUrl = grid.createUrl;
    if (_.isFunction(createUrl)) {
      createUrl = createUrl();
    }
    return (
      optional(createUrl, `/entities/${this.getEntity()}/new`) +
      "?parentProperty=" +
      this.props.parentProperty +
      "&parentEntity=" +
      this.props.parentEntity +
      "&parentEntityId=" +
      this.props.model.get("id")
    );
  }

  getEditUrl(data) {
    let grid = this.getDescriptor();
    if (_.isFunction(this.props.getEditUrl)) {
      return this.props.getEditUrl(data);
    }
    if (_.isFunction(grid.editUrl)) {
      return (
        format(grid.editUrl(data)) +
        "?parentProperty=" +
        this.props.parentProperty +
        "&parentEntityId=" +
        this.props.model.get("id")
      );
    } else if (!_.isEmpty(grid.editUrl)) {
      return (
        format(grid.editUrl, data.id) +
        "?parentProperty=" +
        this.props.parentProperty +
        "&parentEntityId=" +
        this.props.model.get("id")
      );
    } else {
      return (
        `/entities/${this.getEntity()}/${data.id}` +
        "?parentProperty=" +
        this.props.parentProperty +
        "&parentEntity=" +
        this.props.parentEntity +
        "&parentEntityId=" +
        this.props.model.get("id")
      );
    }
  }

  getDeleteMessage() {
    let message = format(
      M("entityDeleteConfirm"),
      this.refs.grid.getSelection().length
    );
    let entityMessage = this.getGrid().deleteMessage;
    if (entityMessage) message = format("{0}\n{1}", message, entityMessage);
    return message;
  }

  getSelection() {
    return this.refs.grid.getSelection();
  }

  deleteEntities() {
    if (!this.canDelete()) {
      return;
    }

    let selection = this.getSelection();
    if (_.isEmpty(selection)) {
      return;
    }

    confirm(M("confirm"), this.getDeleteMessage())
      .then(() => {
        deleteEntities({
          discriminator: this.discriminator,
          entity: this.getEntity(),
          ids: selection.map((s) => s.id),
        }).catch(this.onQueryChanged.bind(this));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onGridRowDoubleClick(row) {
    this.editEntity(row);
  }

  getTitle() {
    const grid = this.getEntityGrid();
    return grid.title;
  }

  getSubtitle() {
    const grid = this.getEntityGrid(),
      subtitle = grid.subtitle;
    return subtitle;
  }

  getActions() {
    let defaultActions = [];

    if (this.canRefresh()) {
      defaultActions.push({
        id: "refresh",
        type: ACTION_TYPES.ICON,
        icon: "cached",
        title: M("refresh"),
        permissions: [this.getEntity() + ":" + Permission.LIST],
        action: () => {
          loadEntities({
            discriminator: this.discriminator,
            entity: this.getEntity(),
            query: this.state.query,
          });
        },
      });
    }

    if (this.canCreate()) {
      defaultActions.push({
        id: "create",
        type: ACTION_TYPES.ICON,
        icon: "add_circle",
        title: M("create"),
        permissions: [this.getEntity() + ":" + Permission.NEW],
        action: () => {
          this.createEntity();
        },
      });
    }

    if (this.canDelete()) {
      defaultActions.push({
        id: "delete",
        type: ACTION_TYPES.ICON,
        icon: "delete",
        title: M("delete"),
        permissions: [this.getEntity() + ":" + Permission.DELETE],
        action: () => {
          this.deleteEntities();
        },
      });
    }

    if (this.canSelectAll()) {
      defaultActions.push({
        id: "selectAll",
        type: ACTION_TYPES.ICON,
        icon: "select_all",
        title: M("selectAll"),
        action: () => {
          this.refs.grid.toggleSelectAll();
        },
      });
    }

    let grid = this.getEntityGrid();
    let matcher = new ActionsMatcher(defaultActions);
    let actions = matcher.match(
      _.isFunction(grid.getActions)
        ? grid.getActions(this.props.model)
        : grid.actions
    );

    //grouping non-create actions, if any
    const dropdownActions = _.reject(actions, (a) => a.id === "create");
    if (dropdownActions.length > 1) {
      actions = _.where(defaultActions, { id: "create" });
      actions.push({
        id: "more",
        type: ACTION_TYPES.DROPDOWN,
        items: dropdownActions,
      });
    }

    return actions;
  }

  getGrid() {
    return this.refs.grid;
  }

  getDescriptor() {
    let grid = this.getEntityGrid();
    return grid.descriptor;
  }

  getData() {
    return resultToGridData(this.state.result);
  }

  isQuickSearchEnabled() {
    let grid = this.getEntityGrid();
    return optional(grid.quickSearchEnabled, false);
  }

  getQuickSearchPlaceholder() {
    let grid = this.getEntityGrid();
    return optional(grid.quickSearchPlaceholder, "");
  }

  getHeaderVisibleNoResults() {
    let grid = this.getEntityGrid();
    return optional(grid.headerVisibleNoResults, false);
  }

  canEdit() {
    let grid = this.getEntityGrid();
    return optional(grid.canEdit, true);
  }

  canCreate() {
    let grid = this.getEntityGrid();
    if(!_.isUndefined(this.props.canCreate)){
      return grid.canCreate
    }else if(!_.isUndefined(grid.canCreate)){
      return this.props.canCreate
    }
    return true;
  }

  canDelete() {
    let grid = this.getEntityGrid();
    return optional(grid.canDelete, true);
  }

  canRefresh() {
    let grid = this.getEntityGrid();
    return optional(grid.canRefresh, true);
  }

  canSelectAll() {
    let grid = this.getEntityGrid();
    return optional(grid.canSelectAll, true);
  }

  hideFilters() {
    return true;
  }

  selectWithCheck() {
    return optional(this.props.selectWithCheck, false);
  }

  generateHeaderBlock() {
    const title = this.getTitle(),
      subtitle = this.getSubtitle(),
      actions = this.getActions();

    if (
      title == null &&
      subtitle == null &&
      (actions == null || actions.length == 0)
    ) {
      return null;
    }

    return <HeaderBlock title={title} subtitle={subtitle} actions={actions} />;
  }

  renderExtra() {
    return null;
  }

  tableClassName() {
    return this.props.tableClassName;
  }

  showInCard() {
    return optional(this.props.showInCard, false);
  }

  render() {
    let descriptor = this.getDescriptor();
    let data = this.getData();
    let header = this.generateHeaderBlock();

    return (
      <>
        {header}
        <Grid
          ref="grid"
          descriptor={descriptor}
          discriminator={this.discriminator}
          data={data}
          query={this.state.query}
          hideFilters={this.hideFilters()}
          onRowDoubleClick={this.onGridRowDoubleClick.bind(this)}
          quickSearchEnabled={this.isQuickSearchEnabled()}
          quickSearchPlaceholder={this.getQuickSearchPlaceholder()}
          headerVisibleNoResults={this.getHeaderVisibleNoResults()}
          selectWithCheck={this.selectWithCheck()}
          tableClassName={this.tableClassName()}
          showInCard={this.showInCard()}
        />
      </>
    );
  }
}

export default EntitiesGridControl;
