import React from "react";
import Secure from "../components/secure";
import BookingsCalendar from "./bookingsCalendar";
import { isLoggedIn, isSuperuser } from "../../api/session";
import EntitiesGrid from "./entities/entitiesGrid";

const { Screen } = require("../components/layout");

export default class Home extends Screen {
  render() {
    return (
      <Secure>
        {isLoggedIn() && !isSuperuser() && <BookingsCalendar />}
        {isSuperuser() && (
          <EntitiesGrid
            key={"company"}
            entity={"company"}
            menu={"company"}
            section={"company"}
          />
        )}
      </Secure>
    );
  }
}
