import React, { useEffect, useRef } from "react";
import _ from "underscore";
import cn from "classnames";
import { useState } from "react";
import { useUuid } from "../utils/react";

function Collapse(props) {
  const uuid = useUuid().replace(/[^a-zA-Z]/g, ""),
    collapseRef = useRef(),
    [open, setOpen] = useState(Boolean(props.open)),
    Header = React.Children.only(props.header),
    onShow = _.isFunction(props.onShow) ? props.onShow : _.identity,
    onShown = _.isFunction(props.onShown) ? props.onShown : _.identity,
    onHide = _.isFunction(props.onHide) ? props.onHide : _.identity,
    onHidden = _.isFunction(props.onHidden) ? props.onHidden : _.identity;

  useEffect(() => {
    const options = {
      toggle: Boolean(props.open),
    };

    if (!_.isUndefined(props.parent)) {
      options.parent = props.parent;
    }

    $(collapseRef.current)
      .collapse(options)
      .on("show.bs.collapse", () => setOpen(true))
      .on("hide.bs.collapse", () => setOpen(false));
  }, [props.parent]);

  useEffect(() => {
    $(collapseRef.current).on("show.bs.collapse", onShow);
    return () => $(collapseRef.current).off("show.bs.collapse", onShow);
  }, [onShow]);

  useEffect(() => {
    $(collapseRef.current).on("shown.bs.collapse", onShown);
    return () => $(collapseRef.current).off("shown.bs.collapse", onShown);
  }, [onShown]);

  useEffect(() => {
    $(collapseRef.current).on("hide.bs.collapse", onHide);
    return () => $(collapseRef.current).off("hide.bs.collapse", onHide);
  }, [onHide]);

  useEffect(() => {
    $(collapseRef.current).on("hidden.bs.collapse", onHidden);
    return () => $(collapseRef.current).off("hidden.bs.collapse", onHidden);
  }, [onHidden]);

  useEffect(() => {
    if (Boolean(props.open)) {
      $(collapseRef.current).collapse("show");
    } else {
      $(collapseRef.current).collapse("hide");
    }
  }, [props.open]);

  return (
    <>
      {React.cloneElement(Header, {
        className: cn("pointer-cursor", Header?.props?.className, {
          open: open,
          closed: !open,
        }),
        ["data-toggle"]: "collapse",
        ["data-target"]: "#" + uuid,
        open: open,
      })}
      <div
        ref={collapseRef}
        id={uuid}
        className={cn("collapse", props.className)}
      >
        {props.children}
      </div>
    </>
  );
}

export default Collapse;
