import React, { useEffect, useState } from "react";
import config from "../../framework/config";
import { assertNotNull } from "../../aj/assert";
import { download } from "../../api/utils";

export function getAttachmentImageUrl(
  entity,
  entityId,
  property,
  attachmentId,
  size = null
) {
  const serviceUrl = config.get("service.url");
  assertNotNull(serviceUrl);

  return `${serviceUrl}attachments/${entity}/${entityId}/${property}/${attachmentId}${
    size ? `?size=${size}` : ""
  }`;
}

export default function AttachmentImage({
  entity,
  entityId,
  property,
  attachmentId,
  size,
  alt,
  className,
}) {
  const [localUrl, setLocalUrl] = useState(
    "resources/images/attachment-loading.gif"
  );

  useEffect(() => {
    async function go() {
      const data = await download(
        getAttachmentImageUrl(entity, entityId, property, attachmentId, size)
      );
      const objectUrl = URL.createObjectURL(data);
      setLocalUrl(objectUrl);
    }

    go();

    return () => {
      URL.revokeObjectURL(localUrl);
    };
  }, [entity, entityId, property, attachmentId]);

  return (
    <div
      style={{ backgroundImage: `url(${localUrl})` }}
      alt={alt}
      className={className}
    />
  );
}
