import { isSuperuser } from "../../api/session";
import M from "../../strings";
import { TextCell } from "../components/grids";
import {
  companySearchForm,
  facilitySearchForm,
} from "../screens/entities/commonFields";

const formatId = (v) =>
  v ? v.substring(0, 5) + "..." + v.substring(v.length - 5) : "";

const multiTenantCells = () => [
  {
    property: "companyId",
    header: M("company"),
    cell: TextCell,
    sortable: true,
    searchable: true,
    searchForm: companySearchForm,
    visibility: () => isSuperuser(),
    props: { formatter: formatId },
  },
  {
    property: "facilityId",
    header: M("facility"),
    cell: TextCell,
    sortable: true,
    searchable: true,
    searchForm: facilitySearchForm,
    visibility: () => isSuperuser(),
    props: { formatter: formatId },
  },
];

export default multiTenantCells;
