import React from "react";
import { TextCell } from "../components/grids";
import { Number, Select, Text, YesNo } from "../components/forms";
import { ValuesSelectContainer } from "../components/containers";
import M from "../../strings";
import * as _query from "../../framework/query";
import { check, sanitize } from "../../libs/validator";
import _ from "underscore";

export default () => ({
  grid: {
    title: M("experienceCategory"),
    quickSearchEnabled: true,
    initialQuery: () =>
      _query
        .create()
        .setPage(1)
        .setRowsPerPage(50)
        .sort("visualizationPriority", true),
    descriptor: {
      columns: [
        {
          property: "description",
          header: M("description"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "timeType",
          header: M("timeType"),
          cell: TextCell,
          sortable: false,
          searchable: false,
          props: { formatter: (v) => M(v) },
        },
        {
          property: "peopleSelectionModes",
          header: M("peopleSelectionModes"),
          cell: TextCell,
          sortable: false,
          searchable: false,
          props: {
            formatter: (v) => {
              return _.chain(v)
                .map((str) => M(str))
                .join(", ")
                .value();
            },
          },
        },
        {
          property: "daysCalculationMode",
          header: M("daysCalculationMode"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "visualizationPriority",
          header: M("visualizationPriority"),
          cell: TextCell,
          sortable: true,
          searchable: false,
        },
      ],
    },
  },
  form: {
    title: M("editExperienceCategory"),
    subtitle: null,
    showFloatingSaveBtn: false,
    descriptor: {
      fields: [
        {
          property: "description",
          control: Text,
          size: "col-sm-4",
          label: M("description"),
          placeholder: M("description"),
          label: M("description"),
          validator: (value) => check(value).notEmpty(),
          sanitizer: (value) => sanitize(value).trim(),
        },
        {
          property: "timeType",
          control: ValuesSelectContainer,
          label: M("timeType"),
          size: "col-sm-4",
          props: {
            id: "experienceCategory_timeType",
            collection: "time-type",
            mode: "single",
            allowNull: false,
            multiple: false,
            getSingleItemLabel: (value) => {
              return M(value.label);
            },
          },
        },
        {
          property: "peopleSelectionModes",
          control: ValuesSelectContainer,
          label: M("peopleSelectionModes"),
          size: "col-sm-4",
          props: {
            id: "experienceCategory_peopleSelection",
            collection: "people-selection-modes",
            allowNull: false,
            multiple: true,
            getSingleItemLabel: (value) => {
              return M(value.label);
            },
          },
        },
        {
          property: "daysCalculationMode",
          control: ValuesSelectContainer,
          label: M("daysCalculationMode"),
          size: "col-sm-4",
          props: {
            id: "experienceCategory_daysCalculationMOdes",
            collection: "days-calculation-modes",
            allowNull: false,
            multiple: false,
            getSingleItemLabel: (value) => {
              return M(value.label);
            },
          },
        },
        {
          property: "visualizationPriority",
          control: Number,
          size: "col-sm-4",
          label: M("visualizationPriority"),
          placeholder: M("visualizationPriority"),
          validator: (value) => check(value).notEmpty(),
          sanitizer: (value) => {
            const _value = (value ?? "").toString();
            return sanitize(_value).trim();
          },
        },
        {
          property: "channelManagerEnabled",
          control: YesNo,
          size: "col-sm-4",
          label: M("channelManagerEnabled"),
          placeholder: M("channelManagerEnabled"),
        },
      ],
    },
  },
});
