import React, { useEffect } from "react";
import _ from "underscore";
import { setupMenu } from "../../actions/menu";
import { TYPES } from "../../model/menu/menu";

export function CustomMenu(props) {
  const menu = props.menu,
    Child = React.Children.only(props.children);

  useCustomMenu(menu);

  return Child;
}

export function useCustomMenu(menu) {
  useEffect(() => {
    setupMenu({ menu: menu });
    return () => setupMenu({ menu: TYPES.DEFAULT });
  }, [menu]);
}

export function withCustomMenu(Component, menu) {
  return React.forwardRef((props, ref) => {
    const menu = props.menu ?? menu,
      passProps = _.omit(props, "menu");

    return (
      <CustomMenu menu={menu}>
        <Component ref={ref} {...passProps} />
      </CustomMenu>
    );
  });
}
