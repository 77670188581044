import React from "react";
import ReactDOM from "react-dom";
import _ from "underscore";
import { isNotEmpty } from "../../framework/utils";

export class Icon extends React.Component {
  componentDidMount() {
    if (this.getTitle()) {
      $(ReactDOM.findDOMNode(this)).tooltip({ trigger: "hover" });
    }
  }

  componentWillUnmount() {
    if (this.getTitle()) {
      $(ReactDOM.findDOMNode(this)).tooltip("dispose");
    }
  }

  getTitle() {
    return this.props.title;
  }

  isClickable() {
    return this.props.onClick && _.isFunction(this.props.onClick);
  }

  onClick(e) {
    if (this.isClickable()) this.props.onClick(e);
  }

  render() {
    const icon = this.props.icon,
      isClickable = this.isClickable(),
      passProps = _.omit(this.props, "className, icon, title, onClick");

    let className = this.props.className ?? "";

    if (isClickable) {
      className += " pointer-cursor";
    }

    return (
      <i {...passProps} onClick={this.onClick.bind(this)} className={className}>
        {icon}
      </i>
    );
  }
}

export const MATERIAL_ICONS = {
  OUTLINED: "outlined",
  ROUND: "round",
  SHARP: "sharp",
  TWO_TONE: "twoTone",
};

export class MaterialIcon extends React.Component {
  render() {
    const iconVariant = this.props.iconVariant,
      passProps = _.omit(this.props, "iconVariant", "className");

    let className;

    switch (iconVariant) {
      case MATERIAL_ICONS.OUTLINED:
        className = "material-icons-outlined";
        break;
      case MATERIAL_ICONS.ROUND:
        className = "material-icons-round";
        break;
      case MATERIAL_ICONS.SHARP:
        className = "material-icons-sharp";
        break;
      case MATERIAL_ICONS.TWO_TONE:
        className = "material-icons-two-tone";
        break;
      default:
        className = "material-icons";
    }

    if (isNotEmpty(this.props.className)) {
      className += " " + this.props.className;
    }

    return <Icon {...passProps} className={className} />;
  }
}

export const MATERIAL_SYMBOLS = {
  OUTLINED: "outlined",
  ROUNDED: "rounded",
  SHARP: "sharp",
};

export class MaterialSymbol extends React.Component {
  render() {
    const iconVariant = this.props.iconVariant,
      passProps = _.omit(this.props, "iconVariant", "className");

    let className;

    switch (iconVariant) {
      case MATERIAL_SYMBOLS.ROUNDED:
        className = "material-symbols-rounded";
        break;
      case MATERIAL_SYMBOLS.SHARP:
        className = "material-symbols-sharp";
        break;
      case MATERIAL_SYMBOLS.OUTLINED:
      default:
        className = "material-symbols-outlined";
    }

    if (isNotEmpty(this.props.className)) {
      className += " " + this.props.className;
    }

    return <Icon {...passProps} className={className} />;
  }
}

export class CustomIcon extends React.Component {
  render() {
    const passProps = _.omit(this.props, "className");
    let className = "custom-icons";

    if (isNotEmpty(this.props.className)) {
      className += " " + this.props.className;
    }

    return <Icon {...passProps} className={className} />;
  }
}
