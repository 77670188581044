"use strict";

import * as aj from "../aj/index";
import { completed, failed } from "../utils/ajex";
import * as actions from "../actions/types";
import _ from "underscore";
import { FACILITY } from "./types";

const defaultState = function () {
  return {
    userFacility: null,
  };
};

export const FacilityStore = aj.createStore(
  FACILITY,
  (state = defaultState(), action) => {
    switch (action.type) {
      case actions.SET_USER_FACILITY:
        return _.assign(state, { userFacility: action.userFacility });

      case completed(actions.GET_USER_FACILITY):
        return _.assign(state, { userFacility: action.userFacility });

      case completed(actions.CHANGE_USER_FACILITY):
        return _.assign(state, { userFacility: action.userFacility });
    }
  }
);

FacilityStore.defaultState = defaultState;

export default FacilityStore;
