import React, { useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import { getLanguage } from "../../strings";
import { HeaderBlockWithBreadcrumbs } from "../components/common";
import { PAGES } from "../../model/vars";
import { Layout } from "../components/layout";
import M from "../../strings";
import config from "../../framework/config";
import { connect } from "../../utils/aj-react";
import BookingsCalendarStore from "../../stores/bookingsCalendar";
import {
  closeBookingDetails,
  forceCancelBooking,
  loadBookings,
  loadExperiences,
  openBookingDetails,
} from "../../actions/bookingsCalendar";
import { toLocalDate } from "../../utils/date";
import moment from "moment";
import _ from "underscore";
import AttachmentImage from "../components/attachmentImage";
import { BookingDetails } from "./bookingDetails";
import { uuid } from "../../utils/lang";

function bookingsToEvents(bookings) {
  return bookings?.map((booking) => {
    const start = new Date(
      booking.start[0],
      booking.start[1] - 1,
      booking.start[2],
      booking.slot ? booking.slot[0] : null,
      booking.slot ? booking.slot[1] : null,
      booking.slot ? booking.slot[2] ?? 0 : null
    );
    const end = !!booking.slot
      ? moment(start)
          .add(booking.slot[0], "hours")
          .add(booking.slot[1], "minutes")
          .add(booking.slot[2] ?? 0, "seconds")
          .toDate()
      : new Date(booking.end[0], booking.end[1] - 1, booking.end[2]);

    return {
      id: booking.id === "channel-manager" ? uuid() : booking.id,
      resourceId: booking.experienceId,
      start,
      title: booking.customerFullName,
      end,
      extendedProps: {
        status: booking.status,
        fromChannelManager: booking.id === "channel-manager",
      },
    };
  });
}

function experiencesToResources(experiences = []) {
  return experiences?.map((experience, index) => ({
    id: experience.id,
    title: experience.name,
    order: index,
    /*children: range(0, experience.numberOfResources).map((i) => ({
      id: `${experience.id}-${i}`,
      title: `${M("resource")} ${i + 1}`,
      order: i,
      children:
        experience.peopleOccupationMode === "Partial"
          ? range(0, experience.numberOfPeoplePerResource).map((j) => ({
              id: `${experience.id}-${i}-${j}`,
              title: `${M("person")} ${j + 1}`,
              order: j,
            }))
          : undefined,
    })),*/
  }));
}

function Sidebar({ onClose, title, children, open }) {
  if (!open) {
    return null;
  }

  return (
    <div className="bookings-calendar__sidebar">
      <div className="bookings-calendar__sidebar__header">
        <div className="bookings-calendar__sidebar__header__title">{title}</div>
        <div className="bookings-calendar__sidebar__header__close-button">
          <a href="#" onClick={onClose}>
            <i className="zmdi zmdi-close" />
          </a>
        </div>
      </div>

      <div className="bookings-calendar__sidebar__content">{children}</div>
    </div>
  );
}

function getEventClassNames(arg) {
  const status = arg.event.extendedProps.status;
  const fromChannelManager = arg.event.extendedProps.fromChannelManager;
  const classNames = ["bookings-calendar__event"];

  if (fromChannelManager) {
    classNames.push("bookings-calendar__event--channel-manager");
  } else if (status === "Waiting" || status === "Confirmed") {
    classNames.push("bookings-calendar__event--basket");
  } else if (status === "NotPaid") {
    classNames.push("bookings-calendar__event--not-paid");
  }

  return classNames;
}

function _BookingsCalendar({
  bookings,
  experiences,
  pricings,
  onLoadBookings,
  onCloseBookingDetails,
  onOpenBookingDetails,
  onForceCancel,
  bookingDetails,
}) {
  const actions = [];

  const resources = useMemo(
    () => experiencesToResources(experiences),
    [experiences]
  );
  const events = useMemo(() => bookingsToEvents(bookings), [bookings]);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  function getResourceLabelContent(args) {
    const experience = experiences.find((e) => e.id === args.resource.id);
    return (
      <div className="bookings-calendar__experience">
        <AttachmentImage
          entity="experience"
          entityId={experience.id}
          property="mainImage"
          attachmentId={experience.mainImage.id}
          size={"36x36"}
          className={"bookings-calendar__experience__image"}
        />
        <div className="bookings-calendar__experience__descriptions">
          <div className="bookings-calendar__experience__name">
            {experience.name}
          </div>
          <div className="bookings-calendar__experience__people">
            {`${experience.numberOfResources} ${M("resources")}, ${
              experience.numberOfPeoplePerResource
            } ${M("peoplePerResource")}`}
          </div>
        </div>
      </div>
    );
  }

  function onEventClick(args) {
    if (args.event.extendedProps.fromChannelManager) {
      alert("Channel manager booking");
      return;
    }

    setSidebarOpen(true);
    onOpenBookingDetails(args.event.id);
  }

  function onCloseSidebar() {
    setSidebarOpen(false);
    onCloseBookingDetails();
  }

  async function onCancel(bookingId) {
    try {
      await onForceCancel(bookingId);
      onCloseSidebar();
    } catch (e) {
      //managed in action
    }
  }

  return (
    <Layout
      page={PAGES.CALENDAR}
      activeMenuItem={PAGES.CALENDAR}
      hideFooter={true}
    >
      <div className="full-width-content-body bookings-calendar">
        <FullCalendar
          schedulerLicenseKey={config.get("fullcalendar.license")}
          headerToolbar={{
            left: "today prev,next",
            center: "title",
            right:
              "resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth",
          }}
          resourcesInitiallyExpanded={false}
          initialView="resourceTimelineWeek"
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          locale={getLanguage()}
          firstDay={1}
          height={"calc(100vh - 64px)"}
          resourceAreaHeaderContent={M("experiences")}
          resourceAreaWidth={"20%"}
          resources={resources}
          resourceOrder={"order"}
          datesSet={(info) => {
            onLoadBookings(toLocalDate(info.start), toLocalDate(info.end));
          }}
          events={events}
          eventClassNames={getEventClassNames}
          stickyHeaderDates={true}
          resourceLabelContent={getResourceLabelContent}
          eventClick={onEventClick}
        />

        <Sidebar
          open={sidebarOpen}
          onClose={onCloseSidebar}
          title={
            !bookingDetails
              ? `${M("loading")}...`
              : bookingDetails.experienceName
          }
        >
          {bookingDetails && (
            <BookingDetails
              bookingDetails={bookingDetails}
              onCancel={onCancel}
            />
          )}
        </Sidebar>
      </div>
    </Layout>
  );
}

const BookingsCalendar = connect(_BookingsCalendar).to(
  BookingsCalendarStore,
  (state) => ({
    bookings: state.bookings,
    experiences: state.experiences,
    bookingDetails: state.bookingDetails,
  }),
  () => ({
    onInit: () => loadExperiences(),
    onLoadBookings: _.debounce((from, to) => loadBookings({ from, to }), 500),
    onCloseBookingDetails: () => closeBookingDetails(),
    onOpenBookingDetails: (bookingId) => openBookingDetails({ bookingId }),
    onForceCancel: (bookingId) => forceCancelBooking({ bookingId }),
  })
);

export default BookingsCalendar;
