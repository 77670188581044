import React from "react";
import ReactDOM from "react-dom";
import { Index } from "./components/layout";
import Login from "./screens/login";
import Register from "./screens/register";
import Recover from "./screens/recover";
import Home from "./screens/home";
import RegistrationOk from "./screens/registrationOk";
import Confirm from "./screens/confirm";
import * as ui from "./utils/ui";
import * as plugins from "./pluginsimpl";
import { resumeSession } from "../actions/session";
import * as keyboard from "./utils/keyboard";
import { SessionStore } from "../stores/session";
import {
  EntitiesGrid,
  EntityForm,
  RevisionGrid,
  LicenseForm,
  NoMenuEntityForm,
} from "./screens/entities";
import { getUserProfileImage } from "../actions/ui";
import {
  reloadMenu,
  reloadTopMenu,
  setupMenu,
  setupTopMenu,
} from "../actions/menu";
import * as NotificationCenter from "../utils/notificationCenter";
import Settings from "./screens/settings";
import { getUserFacility } from "../actions/facility";
import ExperiencePricing from "./screens/entities/experiencePricing";
import BookingsCalendar from "./screens/bookingsCalendar";

export default function main() {
  /* Register plugins */
  plugins.register();

  ui.addRoute(
    "/entities/section/services/experience/:experienceId/pricing",
    (params) =>
      ui.changeScreen(
        <ExperiencePricing
          key={params.experienceId}
          experienceId={params.experienceId}
          section={"configServices"}
          params={params}
        />
      )
  );

  ui.addRoute("/entities/section/services/experience/:experienceId", (params) =>
    ui.changeScreen(
      <NoMenuEntityForm
        key={params.entity}
        entity={"experience"}
        entityId={params.experienceId}
        section={"configServices"}
        params={params}
      />
    )
  );

  /* Admin routes */
  ui.addRoute("/entities/:entity", (params) =>
    ui.changeScreen(<EntitiesGrid key={params.entity} entity={params.entity} />)
  );
  ui.addRoute("/entities/:entity/:entityId", (params) =>
    ui.changeScreen(
      <EntityForm
        key={params.entity}
        entity={params.entity}
        entityId={params.entityId}
        params={params}
      />
    )
  );
  ui.addRoute("/entities/single/:entity", (params) =>
    ui.changeScreen(
      <EntityForm
        key={params.entity}
        entity={params.entity}
        entityId="_"
        params={params}
      />
    )
  );
  ui.addRoute("/revision/:entity/:entityId", (params) =>
    ui.changeScreen(
      <RevisionGrid
        key={params.entity}
        entityId={params.entityId}
        entity={params.entity}
        params={params}
      />
    )
  );
  ui.addRoute("/recover", (params) => ui.changeScreen(<Recover />));
  ui.addRoute("/settings", (params) =>
    ui.changeScreen(<Settings params={params} />)
  );

  ui.addRoute("/entities/section/:section", (params) =>
    ui.changeScreen(
      <Settings
        key={params.section}
        menu={params.section}
        section={params.section}
        params={params}
      />
    )
  );

  ui.addRoute("/entities/section/:section/:entity", (params) =>
    ui.changeScreen(
      <EntitiesGrid
        key={params.entity}
        entity={params.entity}
        menu={params.section}
        section={params.section}
      />
    )
  );

  ui.addRoute("/entities/section/:section/:entity/:entityId", (params) =>
    ui.changeScreen(
      <EntityForm
        key={params.entity}
        entity={params.entity}
        entityId={params.entityId}
        menu={params.section}
        section={params.section}
        params={params}
      />
    )
  );

  //license company

  ui.addRoute("/entities/section/company/license/:entityId", (params) =>
    ui.changeScreen(
      <LicenseForm
        key={"license"}
        entity={"license"}
        entityId={params.entityId}
        menu={"license"}
        section={"company"}
        params={params}
      />
    )
  );

  /* Account routes */
  ui.addRoute("/login", (params) => ui.changeScreen(<Login />));
  ui.addRoute("/register", (params) => ui.changeScreen(<Register />));
  ui.addRoute("/recover", (params) => ui.changeScreen(<Recover />));
  ui.addRoute("/registrationComplete", (params) =>
    ui.changeScreen(<RegistrationOk />)
  );

  ui.addRoute("/confirm", (params) =>
    ui.changeScreen(<Confirm activationCode={params.activationCode} />)
  );

  /* home route */
  ui.addRoute("/", (params) => ui.changeScreen(<Home />));

  /* Attach keyboard for global key bindings */
  keyboard.attach();

  /* render main index page into dom */
  ReactDOM.render(<Index />, document.getElementById("entry-point"));

  /** session actions catching */
  setupMenu();
  setupTopMenu();
  NotificationCenter.addObserver("login", (user) => {
    reloadMenu();
    reloadTopMenu();
    if (!user.needToChangePassword) {
      getUserProfileImage();
    }
    getUserFacility();
  });
  NotificationCenter.addObserver("logout", () => {
    reloadMenu();
    reloadTopMenu();
  });

  /* Avoid going in screens that require login before trying session resume */
  let owner = {};
  SessionStore.subscribe(owner, (state) => {
    if (state.resumeComplete) {
      SessionStore.unsubscribe(owner);
      ui.startNavigation();
    }
  });

  /* automatic login, if possible */
  resumeSession();
}
