import _ from "underscore";
import {
  CLEAN_DIALOG_QUEUE,
  HIDE_DIALOG,
  ON_DIALOG_HIDDEN,
  SHOW_DIALOG,
  UPDATE_DIALOG,
} from "../actions/types";
import * as aj from "../aj";
import { DIALOG } from "./types";

const defaultState = function () {
  return {
    hidden: true,
    options: {},
    queue: [],
  };
};

const DialogStore = aj.createStore(DIALOG, (state = defaultState(), action) => {
  switch (action.type) {
    case HIDE_DIALOG:
      console.log("[DialogStore] " + HIDE_DIALOG + ": closing dialog");
      return _.assign(state, { hidden: true });
    case ON_DIALOG_HIDDEN:
      if (state.queue.length) {
        console.log(
          "[DialogStore] " +
            ON_DIALOG_HIDDEN +
            ": dialog queue is not empty. Opening next dialog"
        );
        return _.assign(state, {
          hidden: false,
          options: state.queue[0],
          queue: state.queue.slice(1),
        });
      } else {
        console.log(
          "[DialogStore] " +
            ON_DIALOG_HIDDEN +
            ": dialog queue is empty. Performing cleanup"
        );
        return _.assign(state, { hidden: true, options: {} });
      }
    case CLEAN_DIALOG_QUEUE:
      console.log(
        "[DialogStore] " + CLEAN_DIALOG_QUEUE + ": force-purge of dialog queue"
      );
      return _.assign(state, { queue: [] });
    case SHOW_DIALOG:
      if (_.isEmpty(state.options)) {
        console.log(
          "[DialogStore] " +
            SHOW_DIALOG +
            ": no current dialog open. Opening new one"
        );
        return _.assign(state, { hidden: false, options: action.options });
      } else {
        console.log(
          "[DialogStore] " +
            SHOW_DIALOG +
            ": dialog already opened. Appending to queue"
        );
        return _.assign(state, { queue: [...state.queue, action.options] });
      }
    case UPDATE_DIALOG:
      console.log(
        "[DialogStore] " + UPDATE_DIALOG + ": updating dialog options"
      );
      return _.assign(state, {
        options: _.extend({}, state.options, action.options),
      });
  }
});

export default DialogStore;
