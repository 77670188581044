import { check, sanitize } from "../../libs/validator";
import M from "../../strings";
import { EntitiesSelectContainer } from "../components/containers";
import { DateTime, Number, Text } from "../components/forms";
import { DateCell, TextCell } from "../components/grids";
import MultiTenantArea from "./multiTenantArea";
import multiTenantCells from "./multiTenantCells";

export default () => ({
  grid: {
    title: M("unavailability"),
    quickSearchEnabled: true,
    descriptor: {
      columns: [
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "experience.name",
          header: M("experience"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "start",
          header: M("start"),
          cell: DateCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "end",
          header: M("end"),
          cell: DateCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "numberOfResources",
          header: M("numberOfResources"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        ...multiTenantCells(),
      ],
    },
  },
  form: {
    title: M("editUnavailability"),
    descriptor: {
      areas: [
        MultiTenantArea,
        {
          title: M("unavailability"),
          fields: [
            {
              property: "name",
              control: Text,
              label: M("name"),
              placeholder: M("name"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "experienceId",
              control: EntitiesSelectContainer,
              label: M("experience"),
              props: {
                id: "experienceId_unavailability",
                entity: "experience",
                allowNull: false,
                searchEnabled: true,
                multiple: false,
              },
            },
            {
              property: "start",
              control: DateTime,
              label: M("start"),
              validator: (value) => check(value).notEmpty(),
              props: {
                isLocalDate: true,
              },
            },
            {
              property: "end",
              control: DateTime,
              label: M("end"),
              validator: (value) => check(value).notEmpty(),
              props: {
                isLocalDate: true,
              },
            },
            {
              property: "numberOfResources",
              control: Number,
              label: M("numberOfResources"),
            },
          ],
        },
      ],
    },
  },
});
