import {
  registerMenu,
  setupMenu,
  unregisterMenu,
} from "../../../../actions/menu";
import { UserPermission } from "../../../../model/enums";
import { PAGES } from "../../../../model/vars";
import M from "../../../../strings";
import EntityForm from "../entityForm";

export default class LicenseForm extends EntityForm {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    registerMenu({
      menu: "license",
      menuData: this.getCustomMenu(this.props.params),
    });
    setupMenu({ menu: "license" });
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    unregisterMenu({ menu: "license" });
  }

  getCustomMenu(params) {
    return {
      backUrl:
        "/entities/section/" +
        PAGES.COMPANY +
        "/company/" +
        params.parentEntityId,
      items: [
        {
          id: PAGES.LICENSE,
          children: [
            {
              id: "company",
              icon: "domain",
              text: M("company"),
              href: "/entities/section/" + PAGES.COMPANY + "/company",
              permissions: UserPermission.ADMIN_SUPERUSER,
            },
            {
              id: "facility",
              icon: "holiday_village",
              text: M("facility"),
              href: "/entities/section/" + PAGES.COMPANY + "/facility",
              permissions: [
                UserPermission.ADMIN_SUPERUSER,
                UserPermission.COMPANY_USER,
              ],
            },
          ],
        },
      ],
    };
  }
}
