import React from "react";
import * as _query from "../framework/query";
import { isEmpty } from "../framework/utils";
import _ from "underscore";
import facilityDescriptor from "./descriptors/facilityDescriptor";
import adminUserDescriptor from "./descriptors/adminUserDescriptor";
import experienceDescriptor from "./descriptors/experienceDescriptor";
import pricingRuleDescriptor from "./descriptors/pricingRuleDescriptor";
import experiencePricingDescriptor from "./descriptors/experiencePricingDescriptor";
import companyUserDescriptor from "./descriptors/companyUserDescriptor";
import customerDescriptor from "./descriptors/customerDescriptor";
import facilitySubCategoryDescriptor from "./descriptors/facilitySubCategoryDescriptor";
import experienceCategoryDescriptor from "./descriptors/experienceCategoryDescriptor";
import experienceStyleDescriptor from "./descriptors/experienceStyleDescriptor";
import experienceCharacteristicDescriptor from "./descriptors/experienceCharacteristicDescriptor";
import experienceSubCategoryDescriptor from "./descriptors/experienceSubCategoryDescriptor";
import companyDescriptor from "./descriptors/companyDescriptor";
import availabilityDescriptor from "./descriptors/availabilityDescriptor";
import facilityCategoryDescriptor from "./descriptors/facilityCategoryDescriptor";
import licenseDescriptor from "./descriptors/licenseDescriptor";
import extraDescriptor from "./descriptors/extraDescriptor";
import unavailabilityDescriptor from "./descriptors/unavailabilityDescriptor";
import customerOrderDescriptor from "./descriptors/customerOrderDescriptor";
import bookingDescriptor from "./descriptors/bookingDescriptor";
import paymentsDescriptor from "./descriptors/paymentsDescriptor";
import mappedPropertyDescriptor from "./descriptors/mappedPropertyDescriptor";

const entities = {
  adminUser: adminUserDescriptor(),
  companyUser: companyUserDescriptor(),
  customer: customerDescriptor(),
  facility: facilityDescriptor(),
  experience: experienceDescriptor(),
  pricingRule: pricingRuleDescriptor(),
  experiencePricing: experiencePricingDescriptor(),
  facilityCategory: facilityCategoryDescriptor(),
  facilitySubCategory: facilitySubCategoryDescriptor(),
  experienceCharacteristic: experienceCharacteristicDescriptor(),
  experienceStyle: experienceStyleDescriptor(),
  experienceCategory: experienceCategoryDescriptor(),
  experienceSubCategory: experienceSubCategoryDescriptor(),
  company: companyDescriptor(),
  license: licenseDescriptor(),
  availability: availabilityDescriptor(),
  unavailability: unavailabilityDescriptor(),
  extra: extraDescriptor(),
  customerOrder: customerOrderDescriptor(),
  booking: bookingDescriptor(),
  payment: paymentsDescriptor(),
  mappedProperty: mappedPropertyDescriptor(),
};

export function getEntityData(entityName) {
  const data = entities[entityName];
  if (data) {
    return data;
  } else {
    new Error("[entities] getData: could not find entity data");
  }
}

export function withEntityDescriptor(Component) {
  class WrappedComponent extends React.Component {
    getEntityData() {
      const entity = this.props.entity;
      if (isEmpty(entity)) {
        throw new Error(
          "[entities] withEntityDescriptor: entity prop is not defined"
        );
      }
      return getEntityData(entity);
    }

    render() {
      const descriptorData = this.getEntityData(),
        entityGrid = descriptorData.grid,
        entityForm = descriptorData.form;

      return (
        <Component
          entityGrid={entityGrid}
          entityForm={entityForm}
          {...this.props}
        />
      );
    }
  }

  return WrappedComponent;
}
