import _, { property } from "underscore";
import { OrderStatus, Status } from "../../model/enums";
import M from "../../strings";
import { ReadOnlyText } from "../components/forms";
import { ActiveCell, DateCell, TextCell } from "../components/grids";
import EntitiesGridControl from "../components/controls/entitiesGridControl";
import * as _query from "../../framework/query";
import { isNotEmpty } from "../../framework/utils";
import { ACTION_TYPES } from "../components/actions";
import multiTenantCells from "./multiTenantCells";
import { entitySearchForm } from "../screens/entities/commonFields";
import { getSymbol } from "../../utils/currency";
import { SEARCH_FORM_DATE_DESCRIPTOR, SEARCH_FORM_NUMBER_DESCRIPTOR, SEARCH_FORM_SELECT_DESCRIPTOR } from "../../model/searchForms";
import { CompanyServiceSubCategoriesControl } from "../components/controls/companyServiceSubCategoriesControl";
import { SELECT } from "../../stores/types";
import { fromEnum } from "../../utils/datasource";

export default () => ({
  grid: {
    quickSearchEnabled: true,
    title: M("customerOrder"),
    canCreate: false,
    canSave: false,
    canDelete: false,
    canSelectAll: false,
    initialQuery: (m) =>
      _query.create().sort("createdAt", true).setPage(1).setRowsPerPage(20),
    descriptor: {
      columns: [
        {
          property: "id",
          header: M("order"),
          cell: TextCell,
          sortable: true,
          searchable: true,

        },
        ...multiTenantCells(),
        
        {
          property: "createdAt",
          header: M("creationDate"),
          cell: DateCell,
          sortable: true,
          searchable: true,
          searchForm: SEARCH_FORM_DATE_DESCRIPTOR("createdAt")
        },
        {
          property: "customerFullName",
          header: M("customer"),
          cell: TextCell,
          sortable: true,
          searchable: true,
          searchForm: entitySearchForm(
            "customerFullName",
            M("customer"),
            "customer",
            true,
            true
          ),
        },
        {
          property: "total",
          header: M("total"),
          cell: TextCell,
          sortable: true,
          searchable: true,
          props: {
            formatter: (value) =>
              `${getSymbol(value.currencyCode)} ${value.value.toFixed(2)}`,
          },
        },
        {
          property: "status",
          header: M("status"),
          cell: ActiveCell,
          sortable: true,
          searchable: true,
          searchForm: SEARCH_FORM_SELECT_DESCRIPTOR("status", fromEnum(OrderStatus)),
          props: {
            items: [
              {
                value: OrderStatus.CANCELLED,
                className: "disabled",
              },
              {
                value: OrderStatus.CONFIRMED,
                className: "active",
              },
              {
                value: OrderStatus.PAID,
                className: "paid",
              },
              {
                value: OrderStatus.NOT_PAID,
                className: "not-paid",
              },
              {
                value: OrderStatus.EXPRIRED,
                className: "closed",
              },
              {
                value: OrderStatus.WAITING,
                className: "waiting",
              },
            ],
          },
        },
      ],
    },
  },
  form: {
    getTitle(data, params) {
      return [{ title: M("customerOrder") }];
    },
    canSave: () => false,
    showFloatingSaveBtn: false,
    subtitle: null,
    descriptor: {
      onModelLoad: (model) => {
        model.on("property:change", (property, value) => {
          if (_.isEqual(property, "id")) {
            model.invalidateForm();
          }
        });
      },
      areas: [
        {
          title: M("order"),
          actions: (model) => {
            if (model.get("status") === "Paid") {
              return [
                {
                  action: () => {},
                  title: M("Paid"),
                  type: ACTION_TYPES.BUTTON,
                  disabled: true,
                  className: "btn-success",
                },
              ];
            } else if (model.get("status") === "NotPaid") {
              return [
                {
                  action: () => {},
                  title: M("NotPaid"),
                  type: ACTION_TYPES.BUTTON,
                  disabled: true,
                  className: "btn-warning",
                },
              ];
            } else if (model.get("status") === "Expired") {
              return [
                {
                  action: () => {},
                  title: M("Expired"),
                  type: ACTION_TYPES.BUTTON,
                  disabled: true,
                  className: "btn-secondary",
                },
              ];
            } else if (model.get("status") === "Cancelled") {
              return [
                {
                  action: () => {},
                  title: M("Cancelled"),
                  type: ACTION_TYPES.BUTTON,
                  disabled: true,
                  className: "btn-danger",
                },
              ];
            } else if (model.get("status") === "Confirmed") {
              return [
                {
                  action: () => {},
                  title: M("Confirmed"),
                  type: ACTION_TYPES.BUTTON,
                  disabled: true,
                  className: "btn-primary",
                },
              ];
            } else if (model.get("status") === "Waiting") {
              return [
                {
                  action: () => {},
                  title: M("Waiting"),
                  type: ACTION_TYPES.BUTTON,
                  disabled: true,
                  className: "btn-info",
                },
              ];
            }
          },
          fields: [
            {
              property: "customerFullName",
              label: M("customer"),
              size: "col-sm-4",
              control: ReadOnlyText,
              sortable: true,
              searchable: true,
            },
            {
              property: "bookings",
              label: M("facility"),
              size: "col-sm-4",
              control: ReadOnlyText,
              sortable: true,
              searchable: true,
              placeholder: "Nessuna prenotazione",
              props: {
                id: "facility",
                formatter: (value) => {
                  if (!_.isEmpty(value)) {
                    return value.map((v) => v.experience.facilityName).join(", ");
                  }
                },
              },
            },
            {
              property: "bookings",
              label: M("experienceName"),
              size: "col-sm-4",
              control: ReadOnlyText,
              sortable: true,
              searchable: true,
              placeholder: "Nessuna prenotazione",
              props: {
                id: "experienceName",
                formatter: (value) => {
                  if (!_.isEmpty(value)) {
                    return value
                      .map((v) => v.experience.name)
                      .join(", ");
                  }
                },
              },
            },
          ],
        },
        {
          visibility: (model) => isNotEmpty(model.get("id")),
          fields: [
            {
              property: "bookings",
              control: EntitiesGridControl,
              props: {
                entity: "booking",
                parentEntity: "customerOrder",
                parentProperty: "customerOrderId",
                tableClassName: "table table-hover table-striped",
                getEditUrl: (data) => {
                  let url =
                    "/entities/section/orders/booking/" + data.id;
                  const params = {
                      parentEntity: "customerOrder",
                      parentEntityId: data.customerOrderId,
                    },
                    paramsString = _.chain(params)
                      .map((v, k) => k + "=" + v)
                      .value()
                      .join("&");

                  url += "?" + paramsString;

                  return url;
                },
              },
            },
          ],
        },
        {
          visibility: (model) => isNotEmpty(model.get("id")),
          fields: [
            {
              property: "payment",
              control: EntitiesGridControl,
              props: {
                entity: "payment",
                parentEntity: "customerOrder",
                parentProperty: "referenceId",
                tableClassName: "table table-hover table-striped",
                getEditUrl: (data) => {
                  let url =
                    "/entities/section/orders/payment/" + data.id;
                  const params = {
                      parentEntity: "customerOrder",
                      parentEntityId: data.referenceId,
                    },
                    paramsString = _.chain(params)
                      .map((v, k) => k + "=" + v)
                      .value()
                      .join("&");

                  url += "?" + paramsString;

                  return url;
                },
              },
            },
          ],
        },
      ],
    },
  },
});
