import { withCustomMenu } from "../../components/customMenu";
import { secureScreen } from "../../components/secure";
import _EntitiesGrid from "./entitiesGrid";
import _EntityForm from "./entityForm";
import _RevisionGrid from "./revisionsGrid";
import _LicenseForm from "./customScreen/licenseForm";

export const EntitiesGrid = secureScreen(withCustomMenu(_EntitiesGrid));
export const EntityForm = secureScreen(withCustomMenu(_EntityForm));
export const NoMenuEntityForm = secureScreen(_EntityForm);
export const RevisionGrid = secureScreen(withCustomMenu(_RevisionGrid));
export const LicenseForm = secureScreen(_LicenseForm);
