import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Layout } from "../../components/layout";
import { ACTION_TYPES } from "../../components/actions";
import M, { getLanguage } from "../../../strings";
import { HeaderBlockWithBreadcrumbs } from "../../components/common";
import { Form } from "../../components/forms";
import experiencePricingDescriptor from "../../descriptors/experiencePricingDescriptor";
import { hideDialog, showDialog } from "../../../actions/dialog";
import { DIALOG_RESULT_OK } from "../../components/dialogs";
import {
  addObserver,
  emit,
  removeObserver,
} from "../../../utils/notificationCenter";
import { connect } from "../../../utils/aj-react";
import ExperiencePricingsStore from "../../../stores/experiencePricing";
import {
  clearExperiencePricings,
  createExperiencePricing,
  deleteExperiencePricing,
  getExperienceBaseTariff,
  loadExperiencePricings,
  saveExperiencePricings,
  updateExperiencePricing,
} from "../../../actions/experiencePricings";
import { useExperienceMenu } from "../../descriptors/experienceDescriptor";
import { optional } from "../../../utils/lang";
import _ from "underscore";
import { toast } from "../../../plugins";
import { getSymbol } from "../../../utils/currency";
import {
  isDateBetween,
  localDateToIsoString,
  toLocalDate,
} from "../../../utils/date";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

const EXPERIENCE_PRICING_DESCRIPTOR =
  experiencePricingDescriptor().form.descriptor;
const SUBMIT_EVENT = "experience-pricing-dialog.submit";

const CalendarDay = ({ info, pricings, baseTariff }) => {
  const pricing = pricings?.find((e) =>
    isDateBetween(
      info.date,
      localDateToIsoString(e.validityPeriodStart),
      localDateToIsoString(e.validityPeriodEnd)
    )
  );

  if (!pricing && !baseTariff) {
    return null;
  }

  if (pricing) {
    return (
      <>
        <div className="experience-pricings__day-content">
          <span>{info.dayNumberText}</span>
          {baseTariff && (
            <div className="experience-pricings__day-content__price changed">
              {pricing.baseTariffValue}{" "}
              {getSymbol(pricing.baseTariffCurrencyCode)}
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="experience-pricings__day-content">
          <span>{info.dayNumberText}</span>
          {baseTariff && (
            <div className="experience-pricings__day-content__price">
              {baseTariff?.value} {getSymbol(baseTariff?.currencyCode)}
            </div>
          )}
        </div>
      </>
    );
  }
};

const ExperiencePricingForm = ({ experiencePricing, onSubmit }) => {
  const form = useRef(null);
  const submit = useCallback(() => form.current.submit());

  useEffect(() => {
    addObserver(SUBMIT_EVENT, submit);

    return () => {
      removeObserver(SUBMIT_EVENT, submit);
    };
  });

  return (
    <Form
      ref={form}
      descriptor={EXPERIENCE_PRICING_DESCRIPTOR}
      data={experiencePricing}
      onSubmit={onSubmit}
    />
  );
};

function toEvent(pricing) {
  return {
    id: pricing.id,
    start: new Date(
      pricing.validityPeriodStart[0],
      pricing.validityPeriodStart[1] - 1,
      pricing.validityPeriodStart[2]
    ),
    end: new Date(
      pricing.validityPeriodEnd[0],
      pricing.validityPeriodEnd[1] - 1,
      pricing.validityPeriodEnd[2] + 1
    ),
    title: pricing.pricingRule?.name ?? "Periodo",
    color: pricing.pricingRule?.colorCode ?? "#FF6600",
    allDay: true,
  };
}

function lpw(localDate) {
  return localDate[0] * 10000 + localDate[1] * 100 + localDate[2];
}

function hasPeriodOverlap(pricing, pricings) {
  const start = lpw(pricing.validityPeriodStart);
  const end = lpw(pricing.validityPeriodEnd);

  return (
    pricings.filter((p) => {
      const pstart = lpw(p.validityPeriodStart);
      const pend = lpw(p.validityPeriodEnd);

      return (
        (p.id !== pricing.id &&
          ((start >= pstart && start <= pend) ||
            (end >= pstart && end <= pend))) ||
        (start <= pstart && end >= pend)
      );
    }).length > 0
  );
}

const ExperiencePricing = ({
  experienceId,
  pricings,
  baseTariff,
  onCreateExperiencePricing,
  onUpdateExperiencePricing,
  onDeleteExperiencePricing,
  onLoadPeriods,
  onSavePrices: onSavePricings,
  onClose,
}) => {
  useExperienceMenu(experienceId);
  const calendar = useRef();

  const view = useMemo(() => {
    return {
      calendar: { labels: true },
    };
  }, []);

  async function onSubmit(pricing) {
    if (hasPeriodOverlap(pricing, pricings)) {
      toast(M("periodCannotOverlaps"));
      return false;
    }

    if (pricing.id) {
      await onUpdateExperiencePricing(pricing);
    } else {
      await onCreateExperiencePricing(pricing);
    }

    hideDialog();
  }

  async function onDelete(pricing) {
    await onDeleteExperiencePricing(pricing);
    hideDialog();
  }

  function showPricingDialog(pricing) {
    showDialog({
      getContent: () => (
        <ExperiencePricingForm
          experiencePricing={pricing}
          onSubmit={onSubmit}
        />
      ),
      large: true,
      title: !pricing.id ? M("addDailyTariff") : M("editDailyTariff"),
      buttons: [
        {
          text: pricing.id ? M("delete") : M("cancel"),
          dialogResult: DIALOG_RESULT_OK,
          action: async () => {
            await onDeleteExperiencePricing(pricing);
            hideDialog();
          },
        },
        {
          text: "OK",
          dialogResult: DIALOG_RESULT_OK,
          action: () => emit(SUBMIT_EVENT),
        },
      ],
    });
  }

  function onEventCreate(e) {
    const start = e.start;
    const end = e.end;
    const newPricing = {
      id: null,
      validityPeriodStart: toLocalDate(start),
      validityPeriodEnd: toLocalDate(moment(end).subtract(1, "day").toDate()),
      baseTariffCurrencyCode: baseTariff.currencyCode,
      baseTariffValue: baseTariff.value,
    };

    if (hasPeriodOverlap(newPricing, pricings)) {
      toast(M("periodCannotOverlaps"));
      return;
    }

    showPricingDialog(newPricing);
  }

  function onEventChange(info) {
    const oldPricing = pricings.find((p) => p.id === info.event.id);
    if (!oldPricing) {
      return;
    }

    const start = info.event.start;
    const end = moment(info.event.end).subtract(1, "day").toDate();

    const newPricing = {
      ...oldPricing,
      validityPeriodStart: [
        start.getFullYear(),
        start.getMonth() + 1,
        start.getDate(),
      ],
      validityPeriodEnd: [end.getFullYear(), end.getMonth() + 1, end.getDate()],
    };

    if (hasPeriodOverlap(newPricing, pricings)) {
      info.revert();
      toast(M("periodCannotOverlaps"));
      return;
    }

    onUpdateExperiencePricing(newPricing);

    return false;
  }

  const actions = useMemo(() => {
    return [
      {
        id: "save",
        type: ACTION_TYPES.BUTTON,
        icon: "save",
        title: M("save"),
        action: () => {
          onSavePricings(experienceId);
        },
      },
      {
        id: "add",
        type: ACTION_TYPES.ICON,
        icon: "add",
        title: M("add"),
        action: () => {
          const start = new Date();
          const end = new Date();
          const newPricing = {
            id: null,
            validityPeriodStart: [
              start.getFullYear(),
              start.getMonth() + 1,
              start.getDate(),
            ],
            validityPeriodEnd: [
              end.getFullYear(),
              end.getMonth() + 1,
              end.getDate(),
            ],
            baseTariffCurrencyCode: baseTariff.currencyCode,
            baseTariffValue: baseTariff.value,
          };

          showPricingDialog(newPricing);
        },
      },
    ];
  }, [baseTariff]);

  function onDatesSet(e, instance) {
    onLoadPeriods(experienceId, toLocalDate(e.start), toLocalDate(e.end));
  }

  const onDayCellContent = useCallback(
    (info) => {
      return (
        <CalendarDay info={info} pricings={pricings} baseTariff={baseTariff} />
      );
    },
    [(baseTariff, pricings)]
  );

  function onEventClick(e) {
    const pricing = pricings.find((p) => p.id === e.event.id);
    if (!pricing) {
      return;
    }
    showPricingDialog(pricing);
  }

  const events = useMemo(() => optional(pricings, []).map(toEvent), [pricings]);

  return (
    <Layout page="services" activeMenuItem="pricing" hideFooter={true}>
      <HeaderBlockWithBreadcrumbs
        className="entity-form__subheader entity-form__subheader--fixed"
        title={M("dailyTariffs")}
        actions={actions}
      />
      <div className="experience-pricing full-width-content-body">
        <FullCalendar
          ref={calendar}
          events={events}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          dayCellContent={onDayCellContent}
          editable={true}
          selectable={true}
          locale={getLanguage()}
          height={"auto"}
          datesSet={onDatesSet}
          select={onEventCreate}
          selectOverlap={false}
          eventClick={onEventClick}
          eventChange={onEventChange}
          firstDay={1}
        />
      </div>
    </Layout>
  );
};

const stateMapper = (state) => ({
  baseTariff: state.baseTariff,
  pricings: state.pricings,
});

const actionsMapper = () => ({
  onInit: ({ experienceId }) => getExperienceBaseTariff(experienceId),
  onDestroy: () => clearExperiencePricings(),
  onCreateExperiencePricing: (experiencePricing) =>
    createExperiencePricing(experiencePricing),
  onUpdateExperiencePricing: (experiencePricing) =>
    updateExperiencePricing(experiencePricing),
  onDeleteExperiencePricing: (experiencePricing) =>
    deleteExperiencePricing(experiencePricing),
  onLoadPeriods: _.debounce(
    (experienceId, from, to) =>
      loadExperiencePricings({ experienceId, from, to }),
    500
  ),
  onSavePrices: (experienceId, pricings) =>
    saveExperiencePricings({ experienceId }),
  onClose: () => clearExperiencePricings(),
});

export default connect(ExperiencePricing).to(
  ExperiencePricingsStore,
  stateMapper,
  actionsMapper
);
