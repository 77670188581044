"use strict";

import * as config from "../framework/config";
import * as utils from "./utils";
import { addToken } from "./utils";
import _, { head } from "underscore";
import * as http from "../aj/http";
import * as responses from "./responses";

export function find(entity, query) {
  return load(entity, query);
}

export function load(entity, query) {
  let url = config.get("entities.url") + "/" + entity + "/find";
  return utils.postJson(url, query.cleaned());
}

export function delete_(entity, ids) {
  if (!_.isArray(ids)) {
    throw new Error("ids is not an array");
  }

  let data = [];
  for (let i = 0; i < ids.length; i++) {
    data.push(`${ids[i]}`);
  }

  let url = config.get("entities.url") + "/" + entity + "/delete";
  return utils.post(url, { ids: data.join() });
}

export async function save(entity, data, parts = []) {
  let url = config.get("entities.url") + "/" + entity;
  const json = typeof data === "string" ? data : JSON.stringify(data);
  const formData = new FormData();
  formData.append("entity", new Blob([json], { type: "application/json" }));
  parts.forEach((p) => formData.append(p.id, p.file));

  let headers = {};
  const ret = await fetch(url, {
    headers: addToken(headers),
    method: "POST",
    body: formData,
  });

  const response = await ret.json();
  if (response.responseCode !== "ok") {
    throw response;
  }

  return response;
}

export function get(entity, id, params) {
  let url = config.get("entities.url") + "/" + entity + "/" + id;
  return utils.get(url, params);
}

export function checkRevisionEnableStatus(entity) {
  let url = config.get("revision.url") + "/checkStatus/" + entity;
  return utils.get(url);
}
