import React from "react";
import { isSuperuser } from "../../api/session";
import M from "../../strings";
import { EntitiesSelectContainer } from "../components/containers";
import { Area } from "../components/forms";

const MultiTenantArea = {
  title: "Multi tenancy (Admin only)",
  component: Area,
  visibility: (_) => isSuperuser(),
  fields: [
    {
      label: M("company"),
      property: "companyId",
      control: EntitiesSelectContainer,
      size: "col-md-6",
      props: {
        id: "multitenant_company",
        allowNull: false,
        searchEnabled: true,
        entity: "company",
      },
    },
    {
      label: M("facility"),
      property: "facilityId",
      control: EntitiesSelectContainer,
      size: "col-md-6",
      props: {
        id: "multitenant_facility",
        allowNull: false,
        searchEnabled: true,
        entity: "facility",
        parentProperty: "companyId",
      },
    },
  ],
};

export default MultiTenantArea;
