import * as _ from "underscore";

let language = "it";

let languages = ["it", "en"];

let strings = {};
// strings["en"] = {
//     appName: "Cov-ID",
//     registering: "Registering...",
//     ooops: "Ooops...",
//     badLogin: "Cannot login! Please check your email address or password!",
//     welcome: "Welcome",
//     congratulations: "Congratulations",
//     welcomeMessage: "Hi {0}, your registration is complete.\nA confirmation link was sent to {1}.\nPlease confirm before login",
//     continue: "Continue",
//     register: "Register",
//     alreadyRegistered: "Already registered? Login",
//     notRegistered: "Not registered? Register",
//     forgotPassword: "Forgot password",
//     signIn: "Sign in",
//     mailAddress: "Mail Address",
//     name: "Name",
//     password: "Password",
//     accountConfirmText: "Insert activation code that we sent to your mailbox to confirm your account",
//     accountConfirmed: "Your account is confirmed. You can login now",
//     mailAndPasswordRequired: "Email and password are required",
//     nameMailAndPasswordRequired: "Name, email and password are required",
//     mailRequired: "Email is required",
//     activationCodeRequired: "Activation code required",
//     accountRecoverText: "Please insert your email address to recover password. We will send a new password in your mailbox!",
//     problemOccoured: "There is a problem",
//     sendMail: "Send mail",
//     accountRecovered: "A new password was sent to {0}",
//     pleaseSpecifyId: "Please specify an ID",
//     pleaseSpecifyQuery: "Please specify a query",
//     pleaseSpecifyEntity: "Please specify the entity",
//     search: "Search",
//     close: "Close",
//     selectFilterType: "Select filter type",
//     filterType: "Filter type",
//     typeValueToSearch: "Type value to search",
//     value: "Value",
//     filters: "Filters",
//     pagination: "Showing {0} to {1} of {2}",
//     noResults: "there are no results with the specified criteria",
//     selectAll: "Select all",
//     delete: "Delete",
//     create: "Create",
//     refresh: "Refresh",
//     confirm: "Confirm",
//     entityDeleteConfirm: "Are you sure to delete {0} entities?",
//     submit: "Submit",
//     cancel: "Cancel",
//     add: "Add",
//     pleaseSpecifyData: "Please specify data",
//     ok: "OK",
//     security: "Security",
//     users: "Users",
//     roles: "Roles",
//     setup: "Setup",
//     categories: "Categories",
//     nElementsSelected: "{0} elements selected",
//     oneElementSelected: "1 element selected",
//     nothingSelected: "Nothing selected",
//     usersListDescription: "Create, edit or delete system users",
//     mail: "Email",
//     active: "Active",
//     editUser: "Edit user",
//     editUserDescription: "Use this form to edit user informations",
//     generalInformations: "General informations",
//     rolesListDescription: "A role is an entity that gives to user authorization to do something",
//     nameOfRole: "Name of role",
//     role: "Role",
//     permissions: "Permissions",
//     selectPermissions: "Select permissions for role",
//     back: "Back",
//     save: "Save",
//     saveAndGoBack: "Save and go back",
//     revisions: "Revisions",
//     image: "Image",
//     cover: "Cover",
//     saveComplete: "Save complete",
//     articles: "Articles",
//     articlesListDescription: "Articles must exists in Gamma system. Commodo only extends Gamma articles",
//     company: "Company",
//     id: "ID",
//     description: "Description",
//     companies: "Companies",
//     companiesListDescription: "List of companies, read only!",
//     components: "Components",
//     componentsListDescription: "Components are base elements of a \"bill of materials\"",
//     characteristic: "Characteristic",
//     characteristics: "Characteristics",
//     nameOfComponent: "Name of component",
//     editComponent: "Edit component",
//     editComponentDescription: "Use this form to edit component informations",
//     optionValue: "Option value",
//     nameOfCharacteristic: "Name of characteristic",
//     addCharacteristic: "Add characteristic",
//     newOption: "New option",
//     areYouSureToRemoveCharacteristic: "Are you sure to remove characteristic '{0}'?",
//     editArticle: "Edit article",
//     editArticleDescription: "Not all article informations are editable in Commodo because is connected to TeamSystem Gamma",
//     article: "Article",
//     select: "Select",
//     component: "Component",
//     pleaseSpecifyComponentId: "Please specify component id",
//     pleaseSelectComponent: "Please select component",
//     characteristicValues: "Characteristic values",
//     selectedComponent: "Selected component",
//     noComponentSelected: "No component selected",
//     versions: "Versions",
//     version: "Version",
//     versionsListDescription: "Use versions to create configurable associations with models",
//     editVersion: "Edit version",
//     editVersionDescription: "Use this form to edit version informations",
//     nameOfVersion: "Name of version",
//     collections: "Collections",
//     collection: "Collection",
//     collectionsListDescription: "Collections are used in models",
//     editCollection: "Edit collection",
//     editCollectionDescription: "Use this form to edit collection informations",
//     nameOfCollection: "Name of collection",
//     countries: "Countries",
//     country: "Country",
//     countriesListDescription: "Countries are used in models",
//     editCountry: "Edit country",
//     editCountryDescription: "Use this form to edit country informations",
//     nameOfCountry: "Name of country",
//     design: "Design",
//     state: "State",
//     model: "Model",
//     models: "Models",
//     nameOfModel: "Name of model",
//     modelsListDescription: "Models are base entities to create a sofa",
//     editModel: "Edit model",
//     editModelDescription: "Use this form to edit model informations",
//     code: "Code",
//     extraSize: "Extra size",
//     destinationCountry: "Destination country",
//     revision: "Revision",
//     lastUpdate: "Last update",
//     editedBy: "Edited by",
//     yes: "Yes",
//     no: "No",
//     notes: "Notes",
//     makeACopy: "Make a copy",
//     associateVersion: "Associate version",
//     pleaseSpecifyVersion: "Please specify version",
//     versionAlreadyAssociated: "Version already associated",
//     areYouSureToRemoveVersion: "Are you sure to remove version '{0}'?",
//     duplicate: "Duplicate",
//     edit: "Edit",
//     pleaseSaveTheModel: "Please save the model to continue",
//     configurables: "Configurables",
//     configurablesListDescription: "List of versions associated to models. Use Models registry to make new associations",
//     nameOfConfigurable: "Name of configurable",
//     addComponent: "Add component",
//     editRole: "Edit role",
//     editRoleDescription: "Use role to manage what an user can do in system",
//     unableToExcludeDefaultArticle: "Unable to exclude an article marked as default",
//     addArticleToComponent: "Add article to component {0}",
//     selectByArticle: "Select by article",
//     removeThisComponent: "Remove this component",
//     addArticle: "Add article",
//     confirmRemoveConfigurableComponent: "Do you want to remove {0}?",
//     editConfigurable: "Edit configurable",
//     editConfigurableDescription: "A configurable is the base object for a sofa customization. Use this area to design a model-version in all of its parts",
//     noArticlesSelected: "No articles selected for component {0}",
//     pleaseSelectDefaultArticleForComponent: "Please select default article for component {0}",
//     invalidDefaultArticleSelectedForComponent: "Invalid default article selected for component {0}",
//     accessories: "Accessories",
//     accessoriesListDescription: "Accessories list",
//     editAccessory: "Edit accessory",
//     editAccessoryDescription: "Use this form to edit accessory informations",
//     nameOfAccessory: "Name of accessory",
//     unitOfMeasurements: "Unit of measurements",
//     unitOfMeasurementsListDescription: "Unit of measurements list",
//     shortName: "Short name",
//     conversionFactor: "Conversion factor",
//     status: "Status",
//     quantity: "Quantity",
//     remove: "Remove",
//     unitOfMeasurement: "Unit of measurements",
//     parts: "Parts",
//     partsListDescription: "Represents a coverable part of a sofa",
//     editPart: "Edit part",
//     editPartDescription: "Use this form to edit part informations",
//     nameOfPart: "Name of part",
//     covers: "Covers",
//     addPart: "Add part",
//     type: "Type",
//     coverTypes: "Cover types",
//     coverTypesListDescription: "Types of coverings used for cover sofa",
//     nameOfCoverType: "Name of cover type",
//     editCoverType: "Edit cover type",
//     editCoverTypeDescription: "Use this form to edit cover type informations",
//     colors: "Colors",
//     colorsListDescription: "List of colors used in your systems",
//     nameOfColor: "Name of color",
//     editColor: "Edit color",
//     editColorDescription: "Use this form to edit color informations",
//     removeThisPart: "Remove this part",
//     coverOptions: "Cover options",
//     addCoverOption: "Add cover option",
//     removeThisCoverOption: "Remove this cover option",
//     analogousColorArticles: "Analogous articles",
//     complementaryColorArticles: "Complementary articles",
//     addComplementaryArticleToCoverOption: "Add complementary article to cover option {0}",
//     addAnalogousArticleToCoverOption: "Add analogous article to cover option {0}",
//     coverType: "Cover type",
//     color: "Color",
//     characteristicsDisabledForCoverOptions: "Characteristics disabled for cover options",
//     compositions: "Compositions",
//     customers: "Customers",
//     customersListDescription: "Create system customers",
//     paymentCode: "Payment code",
//     fiscalCode: "Fiscal code",
//     editCustomer: "Edit customer",
//     firstName: "First name",
//     lastName: "Last name",
//     companyName: "Company name",
//     cityCode: "City code",
//     countryCode: "Country code",
//     tel1: "Telephonic number 1",
//     tel2: "Telephonic number 2",
//     fax: "Fax",
//     cellNumber: "Cellular number",
//     pec: "Pec",
//     vatCode: "Vat",
//     componentsAccessoriesCovers: "Components accessories covers",
//     removeThisPhase: "Remove this phase",
//     phases: "Phases",
//     addPhase: "Add phase",
//     addComponentToPhase: "Add component to phase",
//     workingTime: "Working time",
//     address: "Address",
//     phasesListDescription: "Phases list description",
//     defaultTime: "default time",
//     editPhase: "Edit phase",
//     editPhaseDescription: "Edit phase description",
//     nameOfPhase: "Name of phase",
//     production: "Production",
//     customer: "Customer",
//     coverings: "Coverings",
//     allCoverings: "All coverings",
//     allAccessories: "All accessories",
//     confirmRemoveConfigurablePhase: "Confirm remove configurable phase",
//     addArticleToPart: "Add article to part",
//     email: "Email",
//     website: "Web site",
//     zipCode: "Zip code",
//     city: "City",
//     coverOptionColorConfiguration: "Cover option color configuration",
//     addComplementaryArticleForColor: "Add complementary article for {0}",
//     addAnalogousArticleForColor: "Add analogous article for {0}",
//     formChangeAlert: "All unsaved data will be lost. Continue?",
// }

strings["it"] = {
  hello: "Ciao!",
  asProceed: "Come vuoi procedere?",
  businessOrPrivate:
    "il tuo account non è stato associato ad alcuna Azienda censita nel sistema. Vuoi procedere come utente privato o come utente business? Se si procede come utente businees sarà necessario creare l'anagrafica dell'azienda",
  business: "Business",
  private: "Private",
  dateFormat: "DD/MM/YYYY",
  dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
  autogenerated: "Autogenerato",
  appName: "Cov-ID",
  registering: "Registrazione...",
  ooops: "Ooops...",
  requiredField: "Campo obbligatorio",
  enterNumber: "Inserire un numero",
  enterNumberMin: "Inserire un numero <= di {0}",
  enterNumberMax: "Inserire un numero >= di {0}",
  enterNumberRange: "Inserire un numero >= di {0} e <= di {1}",
  enterMail: "Inserisci e-mail",
  invalidNumber: "Inserire un numero",
  enterDecimal: "Inserire un numero decimale",
  enterInteger: "Inserire un numero intero",
  enterDate: "Inserire una data valida",
  dateCanBeforeToday: "La data deve essere <= a quella odierna",
  adultDateRequired: "Devi essere maggiorenne",
  enterFiscalCode: "Inserire un codice fiscale valido",
  invalidCreditCard: "Carda di credito non valida",
  invalidEmail: "Email non valida",
  invalidUrl: "Url non valido",
  invalidIP: "IP non valido",
  stringTooSmall: "Il campo deve contenere almeno {0} caratteri",
  stringTooLarge: "Il campo può avere massimo {0} caratteri",
  invalidCharacters: "Caratteri non validi",
  badLogin:
    "Non riesco ad accedere! Per favore controlla il tuo indirizzo email o password!",
  welcome: "Benvenuto",
  congratulations: "Congratulazioni",
  welcomeMessage:
    "Ciao {0}, la tua registrazione è completa.\nUn link per la conferma è stato inviato a {1}.\nPer favore conferma prima di effettuare l'accesso",
  continue: "Continuare",
  register: "Registrati",
  alreadyRegistered: "Sei già registrato? Login",
  notRegistered: "Non sei registrato? Registrati",
  forgotPassword: "Password dimenticata?",
  signIn: "Rgistrati",
  mailAddress: "Indirizzo mail",
  name: "Nome",
  password: "Password",
  accountConfirmText:
    "Inserisci il codice di attivazione che abbiamo inviato alla tua casella mail per confermare il tuo account",
  accountConfirmed:
    "Il tuo account è confermato. Puoi effettuare l'accesso ora",
  mailAndPasswordRequired: "Email e password sono richieste",
  nameMailAndPasswordRequired: "Nome, email e password sono richieste",
  mailRequired: "Email è richiesta",
  activationCodeRequired: "Codice di attivazione richiesto",
  accountRecoverText:
    "Per favore inserisci il tuo indirizzo email per recuperare la password. Ti invieremo una nuova password al tuo indirizzo mail!",
  problemOccoured: "C'è un problema",
  sendMail: "Invia mail",
  accountRecovered: "Una nuova password è stata inviata a {0}",
  pleaseSpecifyId: "Per favore specifica il tuo ID",
  pleaseSpecifyQuery: "Per favore specifica la domanda",
  pleaseSpecifyEntity: "Per favore specifica l'entità",
  search: "Ricerca",
  close: "Chiudi",
  selectFilterType: "Seleziona il tipo di filtro",
  filterType: "Tipo di filtro",
  typeValueToSearch: "Tipo di valore da cercare",
  value: "Valore",
  filters: "Filtri",
  pagination: "Record da {0} a {1} di {2} totali",
  noResults: "Non ci sono risultati con i criteri specificati",
  selectAll: "Seleziona tutto",
  delete: "Rimuovi",
  create: "Crea",
  refresh: "Ricarica",
  confirm: "Conferma",
  entityDeleteConfirm: "Sei sicuro di voler eliminare {0} entità?",
  submit: "Invia",
  cancel: "Annulla",
  add: "Aggiungi",
  pleaseSpecifyData: "Per favore specifica la data",
  ok: "OK",
  security: "Sicurezza",
  users: "Utenti",
  roles: "Ruoli",
  setup: "Setup",
  categories: "Categorie",
  nElementsSelected: "{0} elementi selezionati",
  oneElementSelected: "1 elemento selezionato",
  nothingSelected: "Niente selezionato",
  usersList: "Lista utenti",
  usersListDescription: "Creare, modificare o eliminare gli utenti di sistema",
  mail: "Email",
  active: "Attivo",
  notActive: "Non attivo",
  editUser: "Modifica utente",
  editUserDescription:
    "Usa questo modulo per modificare le informazioni dell'utente",
  generalInformations: "Informazioni generali",
  rolesList: "Lista ruoli",
  rolesListDescription:
    "Un ruolo è un'entità che da all'utente l'autorizzazione per fare qualcosa",
  nameOfRole: "Nome del ruolo",
  role: "Ruolo",
  permissions: "Permessi",
  selectPermissions: "Seleziona i premessi per il ruolo",
  back: "Indietro",
  save: "Salva",
  saveAndGoBack: "Salva e torna alla lista",
  revisions: "Revisioni",
  image: "Immagine",
  cover: "Rivestimenti",
  saveComplete: "Salvataggio completato",
  articles: "Articoli",
  articlesListDescription:
    "Gli articoli devono essere presenti sul sistema Gamma. In commodo gli articoli vengono estesi per aggiungere funzionalità richiesta solo a Commodo",
  company: "Azienda",
  id: "ID",
  description: "Descrizione",
  companies: "Aziende",
  article: "Articolo",
  select: "Seleziona",
  component: "Componente",
  selectedComponent: "Componente selezionato",
  noComponentSelected: "Nessun componente selezionato",
  versions: "Versioni",
  version: "Versione",
  countries: "Paesi",
  country: "Paese",
  design: "Design",
  state: "Stato",
  model: "Modello",
  models: "Modelli",
  code: "Codice",
  extraSize: "Extra size",
  destinationCountry: "Paese di destinazione",
  revision: "Revisione",
  lastUpdate: "Ultimo aggiornamento",
  editedBy: "Modificato da",
  yes: "Si",
  no: "No",
  notes: "Appunti",
  makeACopy: "Crea una copia",
  duplicate: "Duplica",
  edit: "Modifica",
  editRole: "Modifica ruolo",
  editRoleDescription:
    "Usa un ruolo per gestire cosa può fare un utente nel sistema",
  shortName: "Nome breve",
  lastname: "Cognome",
  conversionFactor: "Fattore di conversione",
  status: "Stato",
  quantity: "Quantità",
  remove: "Rimuovi",
  unitOfMeasurement: "Unità di misura",
  colors: "Colori",
  color: "Colore",
  compositions: "Composizioni",
  customers: "Clienti",
  companyName: "Nome dell'Azienda",
  paymentCode: "Codice di pagamento",
  zipCode: "CAP",
  fiscalCode: "Codice fiscale",
  removeThisPhase: "Rimuovi questa fase",
  phases: "Fasi",
  workingTime: "Tempo di lavoro",
  address: "Indirizzo",
  city: "Città",
  defaultTime: "Tempo predefinito",
  customer: "Cliente",
  formChangeAlert: "Tutti i dati non salvati verranno persi. Continuare?",
  profiles: "Profili",
  tests: "Tamponi",
  settings: "Impostazioni",
  profilesListDescription:
    "Lista dei profili inseriti dall'app. In questa lista sono presenti anche i familiari",
  firstName: "Nome",
  lastName: "Cognome",
  familyMember: "Familiare",
  resultUnknown: "Tampone non effettuato",
  resultPositive: "Positivo al Covid-19",
  resultNegative: "Negativo al Covid-19",
  deferred: "differito",
  codeValidationText:
    "Per favore inserisci il il codice di verifica che hai ricevuto",
  recoveryCodeSent: "Codice di verifica inviato",
  validationCodeRequired: "Codice di validazione obbligatorio",
  newPasswordText: "Inserisci una nuova password",
  changePasswordDescription:
    "Devi impostare una nuova password prima di poter continuare",
  passwordConfirm: "Conferma password",
  currentPassword: "Password attuale",
  passwordSuccessfulChanged: "Password cambiata con successo",
  showRevisions: "Mostra revisioni",
  noSelection: "Nessuna selezione",
  FILTER_LIKE: "contenuto in",
  FILTER_GT: "maggiore di",
  FILTER_NE: "diverso da",
  FILTER_GTE: "maggiore o uguale a",
  FILTER_LT: "minore di",
  FILTER_LTE: "minore o uguale a",
  FILTER_EQ: "uguale a",
  FILTER_IN: "è presente in",
  FILTER_NIN: "non è presente in",
  FILTER_ID: "id",
  FILTER_OR: "or",
  FILTER_AND: "and",
  FILTER_RANGE: "intervallo (da - a)",
  FILTER_EXACT: "essattamente uguale a",
  insertValueAndPressEnter:
    "Inserisci il valore e premi invio oppure clicca su ricerca",
  typeForSearching: "Inizia a scrivere per cercare",
  searching: "Sto cercando ...",
  registrationDate: "Data registrazione",
  forbiddenScreenGenericMessage:
    "Non hai i permessi per accedere a questa pagina",
  logoutConfirmMessage: "Sei sicuro di voler uscire?",
  home: "Home",
  permissions: "Permessi",
  logout: "Logout",
  more: "Altro",
  phoneNumber: "Numero di telefono",
  pec: "PEC",
  street: "Indirizzo",
  generalInfo: "Informazioni generali",
  license: "Licenza",
  maxFacilities: "Numero massimo di Facility abilitabili",
  serviceSubCategoriesAbilitated: "Sottocategorie esperienze abilitati",
  serviceSubCategory: "Sottocategorie esperienze",
  paymentAbilitated: "Abilitato al pagamento",
  noItems: "Nessun elemento",
  creationDate: "Data di creazione",
  active: "Attiva",
  disabled: "Disattiva",
  vatCode: "Partita IVA",
  sdi: "SDI",
  feeCrossSelling: "Fee Cross Selling",
  feePlatform: "Fee Platform",
  facility: "Facility",
  closedLicense: "Licenze scadute",
  startDate: "Data di inizio validità",
  endDate: "Data di fine validità",
  companyAdministrators: "Amministratori aziende",
  companyAdministrator: "Amministratore azienda",
  subCategoryService: "Sottocategoria esperienze ",
  cancelType: "Tipologia di cancellazione",
  timeType: "Tipologia di prenotazione",
  timeSelection: "Tipo di selezione",
  peopleSelection: "Selezione persone",
  time: "Tempo",
  people: "Persone",
  peopleMaxSize: "Massimo numero di persone",
  experienceCategory: "Categoria Esperienze",
  facilityCategory: "Categoria Facility",
  facilitySubCategory: "Sottocategoria Facility",
  experienceSubCategory: "Sottocategoria Esperienze",
  experienceCharacteristic: "Caratteristica Esperienze",
  enabledServicesSubCategory: "Sottocategoria esperienze abilitabili",
  experienceStyle: "Stile Esperienze",
  administrators: "Amministratori",
  entityAlreadyAssociated: "Entità già assocciata",
  orders: "Ordini",
  configServices: "Conf. Esperienze",
  enabled: "Abilitata",
  experienceSubCategoriesEnabled: "Sottocategorie esperienze abilitabili",
  currentLicense: "Licenza attuale",
  Active: "Attivo",
  goToLicense: "Vai alla licenza",
  validTo: "Valida fino a",
  facilitiesNumber: "N. di Facility associate",
  maxServiceForSingleFacility: "N. massimo di servizi per Facility",
  noCurrentLicense: "Nessuna licenza attuale",
  Draft: "In allestimento",
  bankAccount: "Conto IBAN",
  language: "Lingua",
  shortDescription: "Descrizione breve",
  completeDescription: "Descrizione completa",
  logoImage: "Immagine logo",
  mainImage: "Immagine principale",
  position: "Posizione",
  latitude: "Latitudine",
  longitude: "Longitudine",
  editFacility: "Modifica Facility",
  Inactive: "Disattivo",
  goToLicense: "Vai alla licenza",
  validTo: "Valida fino a",
  facilitiesNumber: "N. di Facility associate",
  maxServiceForSingleFacility: "N. massimo di esperienze per Facility",
  noCurrentLicense: "Nessuna licenza attuale",
  addNewLicense: "Nuova licenza",
  expired: "Scaduto",
  notActiveYet: "Non ancora attivo",
  errorModifiedLicense: "Errore licenza",
  cannotModifiedLicense: "Non puoi entrare in una licenza chiusa",
  licenses: "Licenze",
  start: "Inizio",
  end: "Fine",
  peopleSelectionModes: "Modalità di selezione numero di persone",
  Draft: "In allestimento",
  bankAccount: "Conto IBAN",
  language: "Lingua",
  shortDescription: "Descrizione breve",
  completeDescription: "Descrizione completa",
  logoImage: "Immagine logo",
  mainImage: "Immagine principale",
  position: "Posizione",
  latitude: "Latitudine",
  longitude: "Longitudine",
  editFacility: "Modifica Facility",
  Inactive: "Disattivo",
  goToLicense: "Vai alla licenza",
  validTo: "Valida fino a",
  facilitiesNumber: "N. di Facility associate",
  maxServiceForSingleFacility: "N. massimo di esperienze per Facility",
  noCurrentLicense: "Nessuna licenza attuale",
  addNewLicense: "Nuova licenza",
  expired: "Scaduto",
  notActiveYet: "Non ancora attivo",
  errorModifiedLicense: "Errore licenza",
  cannotModifiedLicense: "Non puoi entrare in una licenza chiusa",
  licenses: "Licenze",
  start: "Inizio",
  end: "Fine",
  peopleSelectionModes: "Modalità di selezione numero di persone",
  dropzoneTooltip: "Trascina i files quì, o clicca per selezionare",
  dropzoneOnDragTooltip: "Rilascia i files quì...",
  gallery: "Galleria immagini",
  statusLandingPage: "Stato Homepage",
  experiences: "Esperienze",
  numberOfOfferedExperiences: "N° esperienze offerte",
  numberOfResources: "N° di risorse",
  experienceChoice: "Scelta Esperienza",
  availability: "Disponibilità",
  cancellable: "Cancellabile",
  insertNotesHere: "Inserire qui eventuali appunti...",
  timeSelect: "Selezione Tempo",
  occupationTime: "Occupazione Tempo",
  numberOfDayHour: "N° di Giorni/Ore",
  maxNumberOfDayHour: "N° massimo di Giorni/Ore",
  occupationMode: "Occupazione posti",
  cancellationTimeUnit: "Unità di Tempo cancellazione",
  hoursMinutesBeforeDelete: "Ore/Minuti prima della cancellazione",
  experience: "Esperienza",
  optionList: "Opzioni di listino",
  tariffApplication: "Applicazione tariffa",
  tariff: "Tariffa",
  baseTariff: "Tariffa base",
  tariffs: "Tariffe",
  ivaValue: "Valore IVA",
  subCategory: "Sottocategoria",
  category: "Categoria",
  dispositionMode: "Modalità disposizione",
  subGridControlValidationError: "Riga {0} - {1}: {2}",
  noItemToDeleteAlert: "Attenzione. Nessun elemento da eliminare.",
  peopleMaxNumber: "N° massimo persone",
  peoplePreCompiledValue: "Valore precompilato persone",
  peopleSelectionMode: "Modalità di selezione",
  numberOfPeoplePerResource: "Numero di persone per risorsa",
  peopleSelectionChoices: "Selezione persone",
  Precompiled: "Precompilata",
  Compilable: "Compilabile",
  Choice: "Scelta",
  Daily: "Giornaliero",
  Slot: "Fascia oraria",
  duration: "Durata",
  totalPerson: "Persone Totali",
  Exclusive: "Occupazione Esclusiva",
  Partial: "Occupazione Parziale",
  Hours: "Ore",
  Days: "Giorni",
  Night: "Notte",
  Minutes: "Minuti",
  SameResource: "Stessa Risorsa",
  AllResources: "Tutte le Risorse",
  Time: "Tempo",
  Person: "Persone",
  TimeAndPerson: "Tempo e Persone",
  service: "Servizi",
  calendar: "Calendario",
  availability: "Disponibilità",
  unavailability: "Indisponibilità",
  relatedServices: "Servizi Correlati",
  extraService: "Servizio extra",
  listingRules: "Regole di Listino",
  order: "Ordine",
  reservation: "Prenotazione",
  payment: "Pagamento",
  cancellationRequest: "Richiesta Cancellazione",
  complaint: "Reclami",
  trip: "Viaggio",
  type: "Tipologia",
  availabilities: "Disponibilità",
  availability: "Disponibilità",
  period: "Periodo",
  periods: "Periodi",
  profile: "Profilo",
  internationalization: "Internazionalizzazione",
  language: "Lingua",
  defaultLanguage: "Linguaggio primario",
  allowedLanguages: "Linguaggi supportati",
  timezone: "Fuso orario",
  descriptions: "Descrizioni",
  price: "Prezzo",
  cancellation: "Cancellazione",
  cancellableTimeBefore: "Cancellabile prima di...",
  changeFacility: "Cambia facility",
  pricingRules: "Regole di prezzo",
  pricingRule: "Regola di prezzo",
  services: "Servizi",
  timeBasedVariations: "Variazioni in base al tempo",
  peopleBasedVariations: "Variazioni in base alle persone",
  timeUnit: "Unità di misura del tempo",
  variationType: "Tipo variazione",
  variation: "Variazione",
  myCompany: "La mia azienda",
  myFacility: "La mia facility",
  addDailyTariff: "Aggiungi tariffa giornaliera",
  dailyTariffs: "Tariffe giornaliere",
  style: "Stile",
  companyAdministratorsList: "Lista amministratori di aziende",
  periodCannotOverlaps:
    "I periodi non possono sovrapporsi. Selezionare un periodo differente",
  experienceCategories: "Categorie Esperienze",
  percentageVariation: "Variazione percentuale",
  fixedPrice: "Prezzo fisso",
  fixedPriceVariation: "Variazione prezzo fisso",
  experienceSubCategories: "Sottocategorie esperienze",
  experienceCategories: "Categorie esperienze",
  currency: "Valuta",
  quantityCalculationMode: "Modalità di calcolo quantità",
  isQuantityEditable: "Quantità modificabile",
  extra: "Extra",
  extraServices: "Servizi extra",
  region: "Regione",
  facilityPaymentAccountNotConnectedText:
    "Il tuo account non è connesso a Stripe e non è possibile ricevere pagamenti. Per farlo clicca su 'Collega account di pagamento' nell'area 'Pagamenti'",
  connectPaymentAccount: "Collega account di pagamento",
  paymentAccountConnection: "Pagamenti",
  currentPaymentAccountStatus: "Stato attuale account di pagamento",
  goToPaymentAccount: "Vai all'account di pagamento",
  doYouWantToDeleteThisSlot: "Vuoi eliminare questa fascia oraria?",
  deleteItem: "Elimina",
  goToFacilityFrontend: "Vai al sito della facility",
  visualizationPriority: "Priorità di visualizzazione",
  night: "Notte",
  daysCalculationMode: "Modalità di calcolo giorni",
  selectedFacility: "Facility selezionata",
  customerOrder: "Ordini",
  bookings: "Prenotazioni",
  Paid: "Pagato",
  NotPaid: "Non pagato",
  Cancelled: "Cancellato",
  Waiting: "In attesa",
  Expired: "Scaduto",
  Confirmed: "Confermato",
  experienceName: "Nome esperienza",
  numberOfPeople: "Numero di persone",
  servicePrice: "Prezzo del servizio",
  extrasPrice: "Prezzo extra",
  totalPrice: "Prezzo totale",
  booking: "Prenotazione",
  pricinsSavedSuccessfully: "Prezzi salvati con successo",
  selectNoResults: "Nessun risultato",
  resource: "Risorsa",
  resources: "Risorse",
  person: "Persona",
  peoplePerResource: "Persone per risorsa",
  products: "Prodotti",
  PartiallyCompleted: "Parzialmente completato",
  Failed: "Fallito",
  Complete: "Completato",
  Started: "Iniziato",
  Created: "Creato",
  Closed: "Chiuso",
  days: "Giorni",
  total: "Totale",
  vatIncluded: "Iva inclusa",
  loading: "Caricamento",
  waitingForPayment: "In attesa di pagamento",
  paymentCancelled: "Pagamento annullato",
  cancelBooking: "Annulla prenotazione",
  confirmCancelBooking: "Conferma annullamento prenotazione",
  confirmCancelBookingDescription:
    "Sei sicuro di voler annullare la prenotazione? L'operazione non è reversibile e i pagamenti non verranno rimborsati automaticamente",
  payments: "Pagamenti",
  startedAt: "Iniziato il",
  endedAt: "Finito il",
  priceCalculationMode: "Modalità di calcolo prezzo",
  PerExperience: "Per esperienza",
  PerPeople: "Per persona",
  channelManagerEnabled: "Channel manager abilitato",
  channelManager: "Channel manager",
  mappedProperty: "Mappaggio con proprietà Booking.com",
  mappedProperties: "Mappaggio proprietà",
  booking_comPropertyId: "ID proprietà Booking.com",
  propertyId: "ID proprietà",
  mappedRooms: "Mappaggio camere",
  room: "Camera",
  facilityMapping: "Mappaggio facility",
  roomId: "Id camera",
  PAID: "Pagato",
  NOT_PAID: "Non pagato",
  CANCELLED: "Cancellato",
  WAITING: "In attesa",
  EXPRIRED: "Scaduto",
  CONFIRMED: "Confermato",
  PARTIALLY_COMPLETED: "Parzialmente completato",
  FAILED: "Fallito",
  COMPLETE: "Completato",
  STARTED: "Iniziato",
  CREATED: "Creato",
  CLOSED: "Chiuso",
  stripeId: "Id Stripe",
  recipients: "Recipienti",
  gender: "Genere",
  birthDate: "Data di nascita",
  platformFee: "Scontrino",
  editCompany: "Modifica azienda",
};

export function setLanguage(language_) {
  language = language_;
}
7;

export function getLanguage() {
  return language;
}

export function setLanguages(_languages) {
  languages = _languages;
}

export function getLanguages() {
  return languages;
}

export function hasLabel(key) {
  if (strings[language] && strings[language][key]) {
    return true;
  } else return false;
}

export default function M(key) {
  if (!_.isArray(key)) {
    if (strings[language] && strings[language][key]) {
      return strings[language][key];
    } else {
      logger.w("String not found for language " + language + ":", key);
      return key;
    }
  } else return _.map(key, (a) => M(a)).join(" ");
}
