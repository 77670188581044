import React from "react";

export default function Stack({
  children,
  direction = "column",
  gap = 0,
  className,
  align = "start",
  justify = "start",
}) {
  return (
    <div
      className={`
        stack stack--${direction} gap-${gap} ${className ?? ""}
        stack--align-${align} stack--justify-${justify}
      `}
    >
      {children}
    </div>
  );
}
