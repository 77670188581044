import M from "../../strings";
import {
  EntitiesSelectContainer,
  ValuesSelectContainer,
} from "../components/containers";
import Price from "../components/controls/price";
import { DateTime, Number } from "../components/forms";
import { TextCell } from "../components/grids";
import MultiTenantArea from "./multiTenantArea";

export default () => ({
  grid: {
    title: M("experiencePricing"),
    quickSearchEnabled: true,
    descriptor: {
      columns: [
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "colorCode",
          header: M("color"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "baseTariff",
          header: M("baseTariff"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
      ],
    },
  },
  form: {
    title: M("editExperiencePricing"),
    descriptor: {
      showInCard: false,
      fields: [
        {
          property: "validityPeriodStart",
          placeholder: M("from"),
          control: DateTime,
          size: "col-sm-4",
          label: M("startDate"),
          props: {
            isLocalDate: true,
          },
        },
        {
          property: "validityPeriodEnd",
          placeholder: M("to"),
          control: DateTime,
          size: "col-sm-4",
          label: M("endDate"),
          props: {
            isLocalDate: true,
          },
        },
        {
          property: "baseTariffValue",
          control: Price,
          size: "col-sm-4",
          label: M("baseTariff"),
          placeholder: M("tariff"),
          props: {
            getCurrencyCode: (model) => model.get("baseTariffCurrencyCode"),
          },
        },
        {
          property: "pricingRuleId",
          control: EntitiesSelectContainer,
          size: "col-sm-12",
          label: M("pricingRule"),
          placeholder: M("pricingRule"),
          props: {
            id: "experience_currency",
            allowNull: false,
            entity: "pricingRule",
            multiple: false,
            searchEnabled: true,
          },
        },
      ],
    },
  },
});
