import React from "react";
import _ from "underscore";
import { isEmpty, isNotEmpty } from "../../framework/utils";
import { EntitiesStore } from "../../stores/entities";
import { connectDiscriminated } from "../../utils/aj-react";
import { uuid } from "../../utils/lang";
import * as _query from "../../framework/query";
import {
  freeEntities,
  loadEntities,
  updateQuery,
} from "../../actions/entities";
import { Grid, resultToGridData } from "./grids";
import { getEntityData } from "../entities";

class StandaloneEntityGrid extends React.Component {
  constructor(props) {
    super(props);

    this.discriminator = this.props.discriminator;
  }

  getEntity() {
    return this.props.entity;
  }

  getData() {
    return resultToGridData(this.props.result);
  }

  getEntityGrid() {
    const entity = this.getEntity();
    return getEntityData(entity)?.grid ?? {};
  }

  getDescriptor() {
    const descriptor = this.props.descriptor;

    if (isNotEmpty(descriptor)) {
      return this.props.descriptor;
    } else {
      const grid = this.getEntityGrid();
      return grid.descriptor ?? {};
    }
  }

  render() {
    const descriptor = this.getDescriptor(),
      data = this.getData(),
      defaultProps = {
        filtersVisible: true,
      };

    return (
      <Grid
        {...defaultProps}
        {...this.props}
        descriptor={descriptor}
        discriminator={this.discriminator}
        data={data}
        showInCard={false}
      />
    );
  }
}

export default withLoadEntities(StandaloneEntityGrid);

export class EntityLoader extends React.Component {
  constructor(props) {
    super(props);

    if (isEmpty(this.props.entity)) {
      throw new Error(
        "[withLoadEntities] constructor: please provide an entity prop"
      );
    }

    const query = this.getInitialQuery();
    this.onQueryChanged = _.bind(this._onQueryChanged, this);

    query.on("change", this.onQueryChanged);

    this.discriminator = this.props.discriminator ?? uuid();
    this.state = {
      query: query,
    };

    connectDiscriminated(this.discriminator, this, EntitiesStore, this.state);
  }

  componentDidMount() {
    this.onQueryChanged();
  }

  componentWillUnmount() {
    this.state.query.off("change", this.onQueryChanged);

    freeEntities({ discriminator: this.discriminator });
  }

  getInitialQuery() {
    const initialQuery = this.props.initialQuery ?? this.props.query,
      query = _.isFunction(initialQuery) ? initialQuery() : initialQuery;
    let validQuery = isNotEmpty(query) ?? query instanceof _query.Query;

    return validQuery ? query : _query.create();
  }

  _onQueryChanged() {
    const discriminator = this.discriminator,
      query = this.state.query,
      entity = this.getEntity();

    updateQuery({
      discriminator: discriminator,
      query: query,
    });
    loadEntities({
      discriminator: discriminator,
      entity: entity,
      query: query,
    });
  }

  getEntity() {
    return this.props.entity;
  }

  render() {
    const state = this.state ?? {},
      mapper = _.isFunction(this.props.stateMapper)
        ? this.props.stateMapper
        : (state) => _.pick(state, "result", "query"),
      additionalProps = {
        discriminator: this.discriminator,
        ...mapper(state),
      },
      Component = React.Children.only(this.props.children);

    return React.cloneElement(Component, additionalProps);
  }
}

export function withLoadEntities(Component, stateMapper) {
  return function WrappedComponent(props) {
    const { discriminator, entity, query, initialQuery } = props;

    return (
      <EntityLoader
        discriminator={discriminator}
        entity={entity}
        query={query}
        initialQuery={initialQuery}
        stateMapper={stateMapper}
      >
        <Component {...props} />
      </EntityLoader>
    );
  };
}
