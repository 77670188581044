import React from "react";
import _ from "underscore";
import { optional } from "../../../utils/lang";
import { Control } from "../forms";
import { MaterialSymbol } from "../icons";

export class Percentage extends Control {
  render() {
    const field = this.props.field;
    const icon = optional(field.icon, null);
    let style = {};
    const validationResult = this.props.validationResult;
    let inputClassName = "form-control input-sm ";
    if (!validationResult.valid && !_.isEmpty(validationResult.message)) {
      inputClassName += "is-invalid";
    }
    const addonText = "%";

    const Input = (
      <>
        <input
          type="number"
          className={inputClassName}
          id={field.property}
          data-property={field.property}
          placeholder={field.placeholder}
          style={style}
          value={optional(this.props.model.get(field.property), 0)}
          onChange={this.onValueChange.bind(this)}
          maxLength={3}
        />
        <i className="form-group__bar"></i>
        {!validationResult.valid && !_.isEmpty(validationResult.message) && (
          <div className="invalid-feedback">{validationResult.message}</div>
        )}
      </>
    );

    return (
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">%</span>
        </div>
        {Input}
      </div>
    );
  }
}

export default Percentage;
