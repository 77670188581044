import React from "react";
import ReactDOM from "react-dom";
import { forceBoolean, optional, parseBoolean } from "../../utils/lang";
import _ from "underscore";

export const DIALOG_RESULT_OK = 0;
export const DIALOG_RESULT_CANCEL = 1;

export class Dialog extends React.Component {
  constructor(props) {
    super(props);

    this.opened = false;
    this.dialogResult = DIALOG_RESULT_CANCEL;
    this.modalOptions = this.getModalOptions();
  }

  componentDidMount() {
    this.initModal();
  }

  componentDidUpdate() {
    const currentModalOptions = _.omit(this.modalOptions, "show"),
      updatedModalOptions = _.omit(this.getModalOptions(), "show"),
      shouldUpdateOptions = !_.isEqual(
        currentModalOptions,
        updatedModalOptions
      );

    if (shouldUpdateOptions) {
      const me = ReactDOM.findDOMNode(this);
      $(me).modal("dispose");
      this.initModal();
    } else {
      this.checkVisibility();
    }
  }

  initModal() {
    const modalOptions = this.getModalOptions(),
      me = ReactDOM.findDOMNode(this);

    $(me)
      .modal(modalOptions)
      .on("show.bs.modal", () => {
        this.opened = true;
        if (_.isFunction(this.props.onShow)) {
          this.props.onShow();
        }
      })
      .on("shown.bs.modal", () => {
        this.opened = true;
        this.checkVisibility();
        if (_.isFunction(this.props.onShown)) {
          this.props.onShown();
        }
      })
      .on("hide.bs.modal", (e) => {
        if (forceBoolean(this.props.notHide)) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        } else if (_.isFunction(this.props.onClosing)) {
          this.props.onClosing();
        }
      })
      .on("hidden.bs.modal", (e) => {
        this.opened = false;
        if (_.isFunction(this.props.onClose)) {
          this.props.onClose(this.dialogResult);
        }
      });

    this.modalOptions = modalOptions;
    this.checkVisibility();
  }

  getModalOptions() {
    return _.extend(_.pick(this.props, "backdrop", "keyboard", "focus"), {
      show: !this.props.hidden,
    });
  }

  checkVisibility() {
    if (!this.props.hidden) {
      if (!this.opened) {
        this.opened = true;
        this.show();
      }
    } else {
      if (this.opened) {
        this.opened = false;
        this.hide();
      }
    }
  }

  show() {
    let me = ReactDOM.findDOMNode(this);
    $(me).modal("show");
  }

  hide() {
    let me = ReactDOM.findDOMNode(this);
    $(me).modal("hide");
  }

  runButtonAction(button) {
    this.dialogResult = optional(button.dialogResult, DIALOG_RESULT_CANCEL);
    button.action(this);
  }

  render() {
    let buttons = optional(this.props.buttons, []).map((b) => (
      <button
        key={b.text}
        type="button"
        className={"btn btn-link " + optional(b.extraClassName, "")}
        onClick={this.runButtonAction.bind(this, b)}
      >
        {b.text}
      </button>
    ));
    let style = {
      //display: this.props.hidden ? "none" : "block"
    };

    let headerHidden = parseBoolean(optional(this.props.headerHidden, false));
    let bodyStyle = {
      padding: this.props.noPadding ? "0px" : undefined,
    };

    let modalDialogClassName = "modal-dialog";
    modalDialogClassName += parseBoolean(this.props.large) ? " modal-lg" : "";

    //ho tolto tabIndex="-1"

    return (
      <div className="modal fade" role="dialog" style={style}>
        <div className={modalDialogClassName}>
          <div className="modal-content">
            {!headerHidden && (
              <div className="modal-header">
                <h5 className="modal-title">{this.props.title}</h5>
              </div>
            )}
            <div className="modal-body" style={bodyStyle}>
              {this.props.children}
            </div>
            <div className="modal-footer">{buttons}</div>
          </div>
        </div>
      </div>
    );
  }
}
