import React from "react";
import { EntitiesStore } from "../../../stores/entities";
import AbstractEntitiesGrid from "./abstractEntitiesGrid";
import M from "../../../strings";
import { HeaderBlockWithBreadcrumbs } from "../../components/common";
import { ActionsMatcher } from "../../components/actions";
import * as ui from "../../utils/ui";
import { connectDiscriminated } from "../../../utils/aj-react";
import { withEntityDescriptor } from "../../entities";

class RevisionsGrid extends AbstractEntitiesGrid {
  constructor(props) {
    super(props);

    this.state.query.eq("entity", props.entity);
    if (props.entityId) {
      this.state.query.eq("entityId", props.entityId);
    }

    this.state.query.sort("date", true);

    this.state.query.on("change", () => {
      this.onQueryChanged();
    });

    this.discriminator = "entity_grid_" + this.getEntity();

    connectDiscriminated(this.discriminator, this, [EntitiesStore]);
  }

  generateEntityUrl() {
    return "/entities/" + this.props.entity + "/" + this.props.entityId;
  }

  goBack() {
    ui.navigate(this.generateEntityUrl());
  }

  hideFilters() {
    return true;
  }

  getActions() {
    let defaultActions = [
      {
        id: "back",
        type: "icon",
        icon: "arrow-back",
        tooltip: M("back"),
        action: () => {
          this.goBack();
        },
      },
    ];

    let grid = this.getEntityGrid();
    let matcher = new ActionsMatcher(defaultActions);
    return matcher.match(grid.actions);
  }

  getEntity() {
    return "revision";
  }

  canEdit() {
    return false;
  }

  canCreate() {
    return false;
  }

  canDelete() {
    return false;
  }

  generateTitleItems() {
    let items = [];
    items.push({ title: M(this.props.entity), url: this.generateEntityUrl() });
    items.push({ title: this.getTitle() });
    return items;
  }

  generateHeaderBlock() {
    let subtitle = this.getSubtitle();
    let title = this.generateTitleItems();
    let actions = this.getActions();

    return (
      <HeaderBlockWithBreadcrumbs
        title={title}
        subtitle={subtitle}
        actions={actions}
      />
    );
  }
}

export default withEntityDescriptor(RevisionsGrid);
