import _ from "underscore";
import services from "../../api/services";
import { getCurrency } from "../../api/session";
import { check, sanitize } from "../../libs/validator";
import M from "../../strings";
import { getSymbol } from "../../utils/currency";
import { DataSource } from "../../utils/datasource";
import {
  EntitiesSelectContainer,
  ValuesSelectContainer,
} from "../components/containers";
import Percentage from "../components/controls/percentage";
import Price from "../components/controls/price";
import DescriptionsControl from "../components/descriptionsControl";
import { Number, Text, TextArea, YesNo } from "../components/forms";
import { TextCell } from "../components/grids";
import facilityLanguagesDataSource from "./facilityLanguagesDataSource";
import MultiTenantArea from "./multiTenantArea";
import multiTenantCells from "./multiTenantCells";

export default () => ({
  grid: {
    title: M("extra"),
    quickSearchEnabled: true,
    descriptor: {
      columns: [
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "percentage",
          header: M("percentageVariation"),
          cell: TextCell,
          sortable: true,
          searchable: false,
          props: { formatter: (v, m) => v + "" + "%" },
        },
        {
          property: "fixedPriceValue",
          header: M("fixedPriceVariation"),
          cell: TextCell,
          sortable: true,
          searchable: false,
          props: {
            formatter: (v, m) => v + " " + getSymbol(m.fixedPriceCurrencyCode),
          },
        },
        {
          property: "quantityCalculationMode",
          header: M("quantityCalculationMode"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        ...multiTenantCells(),
      ],
    },
  },
  form: {
    title: M("editExtra"),
    descriptor: {
      onModelLoadFirstTime: async (model) => {
        if (_.isEmpty(model.get("fixedPriceCurrencyCode"))) {
          model.set("fixedPriceCurrencyCode", getCurrency());
        }
      },
      areas: [
        MultiTenantArea,
        {
          title: M("generalInfo"),
          fields: [
            {
              property: "name",
              control: Text,
              label: M("name"),
              placeholder: M("name"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: null,
              control: DescriptionsControl,
              size: "col-sm-12",
              props: {
                getFields: (l) => [
                  {
                    property: "description",
                    label: `${M("description")} (${l})`,
                    placeholder: M("description"),
                    control: TextArea,
                    validate: (v) => check(v).notEmpty(),
                    sanitizer: (v) => sanitize(v).trim(),
                  },
                ],
                getDataSource: facilityLanguagesDataSource,
              },
            },
            {
              property: "experienceCategoryIds",
              control: EntitiesSelectContainer,
              label: M("experienceCategories"),
              placeholder: M("experienceCategories"),
              props: {
                entity: "experienceCategory",
                multiple: true,
                allowNull: false,
                id: "extra_experienceCategoryIds",
                searchEnabled: true,
              },
            },
            {
              property: "percentage",
              control: Percentage,
              label: M("percentageVariation"),
              placeholder: M("percentage"),
            },
            {
              property: "fixedPriceValue",
              control: Price,
              label: M("fixedPriceVariation"),
              placeholder: M("fixedPrice"),
              props: {
                getCurrencyCode: (model) =>
                  model.get("fixedPriceCurrencyCode") ?? getCurrency(),
              },
            },
            {
              property: "quantityCalculationMode",
              control: ValuesSelectContainer,
              label: M("quantityCalculationMode"),
              placeholder: M("quantityCalculationMode"),
              props: {
                collection: "quantity-calculation-modes",
                allowNull: false,
                id: "extra_quantityCalculationMode",
                multiple: false,
                searchEnabled: true,
              },
            },
            {
              property: "quantityEditable",
              control: YesNo,
              label: M("isQuantityEditable"),
            },
          ],
        },
      ],
    },
  },
});
