import React from "react";
import { TextCell } from "../components/grids";
import { Text } from "../components/forms";
import { EntitiesSelectContainer } from "../components/containers";
import M from "../../strings";
import * as _query from "../../framework/query";
import { check, sanitize } from "../../libs/validator";
import _ from "underscore";

export default () => ({
  grid: {
    title: M("facilitySubCategory"),
    descriptor: {
      columns: [
        {
          property: "description",
          header: M("description"),
          cell: TextCell,
          sortable: false,
          searchable: true,
        },
      ],
    },
  },
  form: {
    title: M("editFacilitySubCategory"),
    subtitle: null,
    showFloatingSaveBtn: false,
    descriptor: {
      fields: [
        {
          property: "description",
          control: Text,
          label: M("description"),
          placeholder: M("description"),
          validator: (value) => check(value).notEmpty(),
          sanitizer: (value) => sanitize(value).trim(),
        },
        {
          property: "facilityCategoryId",
          control: EntitiesSelectContainer,
          label: M("facilityCategory"),
          props: {
            id: "facilitySubCategoryId_facilityCategory",
            entity: "facilityCategory",
            allowNull: false,
            multiple: false,
          },
        },
      ],
    },
  },
});
