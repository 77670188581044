import React from "react";
import { useModelValue } from "../../utils/model";
import M from "../../../strings";
import cs from "classnames";
import { OK } from "../../../api/responses";
import services from "../../../api/services";
import { alert, toast } from "../../../plugins";
import { msg } from "../../../api/responses";
import _ from "underscore";

export const PaymentAccountStatusControl = ({ model, field }) => {
  const [value] = useModelValue(model, field.property);
  const [id] = useModelValue(model, "id");

  async function onConnect(e) {
    try {
      const response = await services.get(
        `facilities/${id}/payment-account/connect-url`
      );
      if (response.redirectUrl) {
        location.href = response.redirectUrl;
      } else {
        location.reload();
      }
    } catch (e) {
      alert(
        msg(e.responseCode),
        e.paymentMessage ?? M("pleaseContactSupport"),
        "error"
      );
    }
  }

  return (
    <div className="payment-account-connection-control">
      {id && !_.isEqual(value, "Connected") && (
        <button
          className="btn btn-light btn--icon-text"
          style={{ float: "right" }}
          type="button"
          onClick={onConnect}
        >
          <i className="zmdi zmdi-square-right"></i>
          {M("connectPaymentAccount")}
        </button>
      )}

      <p>
        {M("currentPaymentAccountStatus")}: &nbsp;
        <span
          className={cs("badge badge-pill", {
            "badge-success": value === "Connected",
            "badge-danger": value !== "Connected",
          })}
        >
          {value}
        </span>
      </p>
    </div>
  );
};

export default function paymentAccountAreaDescriptor() {
  return {
    title: M("paymentAccountConnection"),
    fields: [
      {
        property: "paymentAccountStatus",
        control: PaymentAccountStatusControl,
      },
    ],
  };
}
