var serviceBase = require("./service-url");
var frontendUrl = require("./frontend-url");

function getVersion() {
  return "1.0";
}

function getCopyright() {
  return "Only4U SRL, 2023";
}

module.exports = {
  "service.url": `${serviceBase}`,
  "frontend.url": `${frontendUrl}`,
  "fs.url": `${serviceBase}fs`,
  "login.url": `${serviceBase}iam/login`,
  "tokenRefresh.url": `${serviceBase}iam/fresh-token`,
  "tokenLogin.url": `${serviceBase}iam/token-login`,
  "account.url": `${serviceBase}profile`,
  "account.register.url": `${serviceBase}iam/register`,
  "account.recover.url": `${serviceBase}iam/recover`,
  "account.reset.url": `${serviceBase}iam/reset`,
  "account.confirm.url": `${serviceBase}iam/activate`,
  "grids.url": `${serviceBase}grids`,
  "entities.url": `${serviceBase}entities`,
  "entities.delete.url": `${serviceBase}entities/delete`,
  "values.url": `${serviceBase}values`,
  "values.entities.url": `${serviceBase}values/entities`,
  "attachment.url": `${serviceBase}attachment`,
  "account.requestRecoveryCode.url": `${serviceBase}iam/recover`,
  "account.validateRecoveryCode.url": `${serviceBase}iam/recover`,
  "account.resetPassword.url": `${serviceBase}iam/reset`,
  "account.changePassword.url": `${serviceBase}iam/change-password`,
  "account.resetUserPassword.url": "".concat(serviceBase, "iam/recover"),

  "company.getCollaboratorByUserId.url": `${serviceBase}company/get-collaborator-by-userid`,
  "company.createPrivateCollaborator.url": `${serviceBase}company/create-private-collaborator`,

  "system.url": `${serviceBase}actuator`,
  "revision.url": `${serviceBase}revision`,
  backendVersion: getVersion(),
  copyrightInfos: getCopyright(),

  "fullcalendar.license": "0528464858-fcs-1655455713",
};
