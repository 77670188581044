import _ from "underscore";
import { getCurrency, isSuperuser } from "../../api/session";
import { check, sanitize } from "../../libs/validator";
import {
  CancellationTimeUnit,
  Status,
  TypeTimeAndPerson,
} from "../../model/enums";
import M from "../../strings";
import { ACTION_TYPES } from "../components/actions";
import {
  EntitiesSelectContainer,
  ValuesSelectContainer,
} from "../components/containers";
import { GoogleMaps } from "../components/customForms";
import {
  Area,
  Attachment,
  Number,
  ReadOnlyText,
  Text,
  TextArea,
  YesNo,
} from "../components/forms";
import { TextCell } from "../components/grids";
import DescriptionsControl from "../components/descriptionsControl";
import multiTenantCells from "./multiTenantCells";
import { LocalizedTextCell } from "../components/cells/localizedCell";
import Price from "../components/controls/price";
import MultiTenantArea from "./multiTenantArea";
import Services from "../../api/services";
import { DataSource } from "../../utils/datasource";
import { PAGES } from "../../model/vars";
import { useEffect } from "react";
import { registerMenu, setupMenu, unregisterMenu } from "../../actions/menu";
import facilityLanguagesDataSource from "./facilityLanguagesDataSource";
import Percentage from "../components/controls/percentage";
import { entitySearchForm } from "../screens/entities/commonFields";

export function experienceMenu(experienceId) {
  return {
    id: "entity_experience_menu",
    backUrl: "/entities/section/" + PAGES.SERVICES + "/experience",
    items: [
      {
        id: "experience",
        icon: "kayaking",
        text: M("experience"),
        href:
          "/entities/section/" + PAGES.SERVICES + "/experience/" + experienceId,
      },
      {
        id: "pricing",
        icon: "price_change",
        text: M("dailyTariffs"),
        href:
          "/entities/section/" +
          PAGES.SERVICES +
          "/experience/" +
          experienceId +
          "/pricing",
      },
    ],
  };
}

export function useExperienceMenu(experienceId) {
  useEffect(() => {
    registerMenu({
      menu: "experience",
      menuData: experienceMenu(experienceId),
    });
    setupMenu({ menu: "experience" });

    return () => {
      unregisterMenu({ menu: "experience" });
      setupMenu();
    };
  });
}

export default () => ({
  grid: {
    title: M("experiences"),
    quickSearchEnabled: true,
    descriptor: {
      columns: [
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "status",
          header: M("status"),
          cell: TextCell,
          sortable: false,
          searchable: true,
        },
        {
          property: "availability.name",
          header: M("availability"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          searchForm: entitySearchForm(
            "availabilityId",
            M("availability"),
            "availability"
          ),
        },
        {
          property: "category.description",
          header: M("category"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          searchForm: entitySearchForm(
            "categoryId",
            M("category"),
            "experienceCategory"
          ),
        },
        {
          property: "subCategory.description",
          header: M("subCategory"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          searchForm: entitySearchForm(
            "subCategoryId",
            M("subCategory"),
            "experienceSubCategory"
          ),
        },
        {
          property: "style.description",
          header: M("style"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          searchForm: entitySearchForm(
            "styleId",
            M("style"),
            "experienceStyle"
          ),
        },
        ...multiTenantCells(),
      ],
    },
  },
  form: {
    title: M("editExperience"),
    subtitle: null,
    showFloatingSaveBtn: false,
    customMenuData: (model) => experienceMenu(model.get("id")),
    descriptor: {
      onModelLoadFirstTime: (model) => {
        if (_.isEmpty(model.get("id"))) {
          model.set("status", Status.DRAFT);
          model.set("baseTariffCurrencyCode", getCurrency());
          model.set("extras", []);
        }
      },
      onModelLoad: (model) => {
        model.on("property:change", (property, value) => {
          if (
            _.isEqual(property, "cancellable") ||
            _.isEqual(property, "subCategoryId") ||
            _.isEqual(property, "facilityId") ||
            _.isEqual(property, "subCategoryId")
          ) {
            model.invalidateForm();
          }
        });
      },
      areas: [
        MultiTenantArea,
        
        {
          title: M("experience"),
          fields: [
            {
              property: "name",
              label: M("name"),
              control: Text,
              size: "col-md-4",
            },
            {
              property: "status",
              label: M("status"),
              size: "col-md-4",
              control: ValuesSelectContainer,
              props: {
                id: "status_experience",
                collection: "experience-statuses",
                allowNull: false,
                mode: "single",
                getSingleItemLabel: (value) => {
                  return M(value.label);
                },
              },
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              property: "availabilityId",
              label: M("availability"),
              size: "col-md-4",
              control: EntitiesSelectContainer,
              props: {
                id: "availability_experience",
                entity: "availability",
                allowNull: false,
                mode: "single",
                parentProperty: "facilityId",
              },
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              label: M("category"),
              property: "categoryId",
              control: EntitiesSelectContainer,
              size: "col-md-4",
              props: {
                id: "experience_category",
                allowNull: true,
                searchEnabled: true,
                entity: "experienceCategory",
              },
              validator: (v) => check(v).notEmpty(),
            },
            {
              label: M("subCategory"),
              property: "subCategoryId",
              control: EntitiesSelectContainer,
              size: "col-md-4",
              props: {
                id: "experience_subcategory",
                allowNull: false,
                searchEnabled: true,
                entity: "experienceSubCategory",
                parentProperty: "categoryId",
                queryProperty: "experienceCategoryId",
              },
              validator: (v) => check(v).notEmpty(),
            },
            {
              property: "styleId",
              control: EntitiesSelectContainer,
              size: "col-md-4",
              label: M("experienceStyle"),
              props: {
                id: "styleId_experience",
                entity: "experienceStyle",
                allowNull: false,
                multiple: false,
              },
              validator: (v) => check(v).notEmpty(),
            },
            {
              property: "numberOfResources",
              placeholder: M("numberOfResources"),
              label: M("numberOfResources"),
              size: "col-sm-4",
              control: Number,
              validator: (v) => check(v).notEmpty(),
            },
            
            {
              property: "extras",
              control: EntitiesSelectContainer,
              size: "col-md-8",
              label: M("extra"),
              props: {
                  id: "extra_experience",
                  entity: "extra",
                  allowNull: false,
                  multiple: true,
                  parentProperty: "facilityId",
              },
            }          
          ],
        },
        
        {
          title: M("price"),
          fields: [
            {
              property: "priceCalculationMode",
              control: ValuesSelectContainer,
              size: "col-sm-4",
              label: M("priceCalculationMode"),
              props: {
                id: "priceCalculationMode_experience",
                collection: "price-calculation-modes",
                mode: "single",
                allowNull: false,
                getSingleItemLabel: (value) => {
                  return M(value.label);
                },
              },
            },
            {
              property: "baseTariffValue",
              control: Price,
              size: "col-sm-4",
              label: M("baseTariff"),
              placeholder: M("tariff"),
              props: {
                getCurrencyCode: (model) =>
                  model.get("baseTariffCurrencyCode") ?? getCurrency(),
              },
            },
            {
              property: "vat",
              control: Percentage,
              size: "col-sm-4",
              label: M("iva"),
              placeholder: M("iva"),
              validator: (v) => {
                check(v).notEmpty();

                let number = parseInt(v);
                if (!_.isNumber(number) || _.isNaN(number)) {
                  throw new Error("value must be a number");
                }
                if (number < 0 || number > 100) {
                  throw new Error("Value must be between 0 and 100");
                }
              },
            },
          ],
        },
        /*
        {
          title: M("cancellation"),
          collapsible: false,
          fields: [
            {
              property: "cancellable",
              control: YesNo,
              size: "col-sm-4",
              label: M("cancellable"),
            },
            {
              property: "cancellableUntil",
              control: Number,
              size: "col-sm-4",
              visibility: (model) => {
                return model.get("cancellable");
              },
              size: "col-sm-4",
              label: M("cancellableTimeBefore"),
            },
            {
              property: "cancellationTimeUnit",
              control: ValuesSelectContainer,
              size: "col-sm-4",
              visibility: (model) => {
                return model.get("cancellable");
              },
              props: {
                id: "cancellationTimeUnit_experience",
                collection: "cancellation-time-units",
                mode: "single",
                allowNull: false,
                getSingleItemLabel: (value) => {
                  return M(value.label);
                },
              },
              label: M("cancellationTimeUnit"),
            },
          ],
        },
        */
       
        {
          title: M("people"),
          collapsible: false,
          fields: [
            {
              property: "peopleSelectionMode",
              control: ValuesSelectContainer,
              size: "col-sm-4",
              label: M("peopleSelectionMode"),
              props: {
                id: "peopleSelectionMode_experience",
                collection: "people-selection-modes",
                mode: "single",
                allowNull: false,
                getSingleItemLabel: (value) => {
                  return M(value.label);
                },
              },
            },
            {
              property: "peopleDispositionMode",
              control: ValuesSelectContainer,
              size: "col-sm-4",
              label: M("dispositionMode"),
              props: {
                id: "dispositionMode_experience",
                collection: "people-disposition-modes",
                mode: "single",
                allowNull: false,
                getSingleItemLabel: (value) => {
                  return M(value.label);
                },
              },
            },
            {
              property: "peopleOccupationMode",
              control: ValuesSelectContainer,
              size: "col-sm-4",
              label: M("occupationMode"),
              props: {
                id: "occupationMode_experience",
                collection: "people-occupation-modes",
                mode: "single",
                allowNull: false,
                getSingleItemLabel: (value) => {
                  return M(value.label);
                },
              },
            },
            {
              property: "numberOfPeoplePerResource",
              placeholder: M("numberOfPeoplePerResource"),
              label: M("numberOfPeoplePerResource"),
              size: "col-sm-4",
              control: Number,
            },
            {
              property: "peoplePreCompiledValue",
              placeholder: M("peoplePreCompiledValue"),
              label: M("peoplePreCompiledValue"),
              size: "col-sm-4",
              visibility: (model) => {
                return _.isEqual(
                  model.get("peopleSelectionMode"),
                  TypeTimeAndPerson.PRECOMPILED
                );
              },
              control: Number,
            },
          ],
        },
        {
          title: M("profile"),
          collapsible: false,
          fields: [
            {
              property: "mainImage",
              control: Attachment,
              size: "col-sm-12",
              props: {
                preview: true,
                accept: {
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
                },
              },
              label: M("mainImage"),
            },
            {
              property: "gallery",
              control: Attachment,
              size: "col-sm-12",
              props: {
                preview: true,
                accept: {
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
                },
                multiple: true,
              },
              label: M("gallery"),
            },
          ],
        },
        {
          title: M("descriptions"),
          collapsible: false,
          fields: [
            {
              property: null,
              control: DescriptionsControl,
              size: "col-sm-12",
              props: {
                getFields: (l) => [
                  {
                    property: "summary",
                    label: `${M("summary")} (${l})`,
                    placeholder: M("summary"),
                    control: Text,
                    validate: (v) => check(v).notEmpty(),
                    sanitizer: (v) => sanitize(v).trim(),
                  },
                  {
                    property: "description",
                    label: `${M("description")} (${l})`,
                    placeholder: M("description"),
                    control: TextArea,
                    validate: (v) => check(v).notEmpty(),
                    sanitizer: (v) => sanitize(v).trim(),
                  },
                  {
                    property: "tags",
                    label: `${M("tags")} (${l})`,
                    placeholder: M("tags"),
                    control: TextArea,
                    validate: (v) => check(v).notEmpty(),
                    sanitizer: (v) => sanitize(v).trim(),
                    props: {
                      linesArray: true,
                    },
                  },
                ],
                getAllowedLanguages: (model) =>
                  model.get("allowedLanguages") ?? [],
                getDataSource: facilityLanguagesDataSource,
              },
            },
          ],
        },
      ],
    },
  },
});
