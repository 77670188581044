import React from "react";
import { CheckCell, DateCell, TextCell } from "../components/grids";
import { Mail, ReadOnlyText, Text, YesNo } from "../components/forms";
import M from "../../strings";
import { getLoggedUser, hasRole } from "../../api/session";
import * as ui from "../utils/ui";
import * as _query from "../../framework/query";
import { ACTION_TYPES } from "../components/actions";
import _ from "underscore";
import { SEARCH_FORM_DATE_DESCRIPTOR } from "../../model/searchForms";
import { activeSearchForm } from "../screens/entities/commonFields";
import { confirm } from "../../plugins";

export default () => ({
  grid: {
    quickSearchEnabled: true,
      canDelete: false,
    canSelectAll: false,
        canCreate:false,
    title: M("usersList"),
    //subtitle: M("usersListDescription"),
    descriptor: {
      columns: [
        
        {
          property: "firstName",
          header: M("firstName"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "lastName",
          header: M("lastName"),
          cell: TextCell,
          sortable: false,
          searchable: false,
        },
        {
          property: "email",
          header: M("mailAddress"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        
      ],
    },
  },
  form: {
    getTitle(data, params) {
      return [
        { title: M("usersList"), url: "/entities/adminUser" },
        {
          title:
            !data || !data.id
              ? M(["create", "adminUser"])
              : M(["edit", "adminUser"]) + " <b>" + data.email + "</b>",
        },
      ];
    },
    subtitle: null,
    getActions(data) {
      let actions = ["save", "back", "save-go-back", "revisions"];
      if (hasRole("ROLE_ADMIN")) {
        if (data && data.id) {
          actions.push({
            type: ACTION_TYPES.ICON,
            icon: "brush",
            title: "Reset password",
            action: () => {
              confirm(
                M("confirm"),
                "Verrà impostata una nuova password ed inviata all'indirizzo mail dell'utente"
              )
                .then(() => {
                  resetUserPassword(data);
                  if (data.id === getLoggedUser().id) {
                    confirm(
                      M("confirm"),
                      "La tua password è stata resettata. Dovrai eseguire un nuovo accesso"
                    ).then((res) => {
                      logout();
                      ui.navigate("/login");
                    });
                  }
                })
                .catch((e) => {
                  logger.i(e);
                });
            },
          });
        }
      }
      return actions;
    },
    descriptor: {
      onModelLoadFirstTime: (model) => {
        model.on("property:change", (property, value) => {
          if (property === "active" || property === "roles") {
            model.invalidateForm();
          }
        });
      },

      areas: [
        {
          title: M("generalInformations"),
          subtitle: null,
          collapsible: false,
          fields: [
            {
              property: "firstName",
              control: Text,
              label: M("firstName"),
              placeholder: M("firstName"),
              size: "col-sm-4",
            },
           {
              property: "lastName",
              control: Text,
              label: M("lastName"),
              placeholder: M("lastName"),
              size: "col-sm-4",
            },
            {
              property: "gender",
              control: Text,
              label: M("gender"),
              placeholder: M("gender"),
              size: "col-sm-4",
            },
            {
              property: "phoneNumber",
              control: Text,
              label: M("phoneNumber"),
              placeholder: M("phoneNumber"),
              size: "col-sm-4",
            },
            {
              property: "birthDate",
              control: ReadOnlyText,
              label: M("birthDate"),
              placeholder: M("birthDate"),
              size: "col-sm-4",
              props: {
                formatter: (value) => {
                  if (value) {
                    const date = new Date(value);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = date.getFullYear();
                    return `${day}/${month}/${year}`;
                  }
                  return "Nessuna data";
                }
                
                }
            },           
          ],
        },
        {
          title: "Account",
          collapsible: false,
          fields: [
            {
              property: "email",
              control: Mail,
              label: M("mailAddress"),
              placeholder: M("mailAddress"),
            },
            
          ],
        },
      ],
    },
  },
});
