import React from "react";
import _ from "underscore";
import { hasCrudPermissions, isSuperuser } from "../../api/session";
import { check, sanitize } from "../../libs/validator";
import { Status } from "../../model/enums";
import M from "../../strings";
import {
  EntitiesSelectContainer,
  ValuesSelectContainer,
} from "../components/containers";
import { GoogleMaps } from "../components/customForms";
import {
  Area,
  Attachment,
  ReadOnlyText,
  Text,
  TextArea,
} from "../components/forms";
import { TextCell } from "../components/grids";
import DescriptionsControl from "../components/descriptionsControl";
import multiTenantCells from "./multiTenantCells";
import { modelPropertySource } from "../../utils/datasource";
import paymentAccountAreaDescriptor from "../components/areas/paymentAccountAreaDescriptor";
import { useModelValue } from "../utils/model";
import { ACTION_TYPES, Actions } from "../components/actions";
import * as config from "../../framework/config";

const PaymentAccountStatusAlert = ({ model }) => {
  const [paymentAccountStatus] = useModelValue(model, "paymentAccountStatus");

  if (paymentAccountStatus === "Disconnected") {
    return (
      <div
        className="alert alert-danger"
        style={{ marginTop: 71, marginBottom: -50 }}
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
        {M("facilityPaymentAccountNotConnectedText")}
      </div>
    );
  }

  return null;
};

export default () => ({
  grid: {
    title: M("facility"),
    quickSearchEnabled: true,
    canCreate: () => hasCrudPermissions("facility:new"),
    canDelete: () => hasCrudPermissions("facility:delete"),
    descriptor: {
      columns: [
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: false,
          searchable: true,
        },
        {
          property: "facilityCategory.description",
          header: M("facilityCategory"),
          cell: TextCell,
          sortable: false,
          searchable: true,
        },
        {
          property: "facilitySubCategory.description",
          header: M("facilitySubCategory"),
          cell: TextCell,
          sortable: false,
          searchable: true,
        },
        multiTenantCells()[0],
      ],
    },
  },
  form: {
    title: M("editFacility"),
    subtitle: null,
    showFloatingSaveBtn: false,
    canGoBack: () => isSuperuser(),
    extraContentBeforeForm: (model) => (
      <PaymentAccountStatusAlert model={model} />
    ),
    descriptor: {
      onModelLoadFirstTime: (model) => {
        if (_.isEmpty(model.get("id"))) {
          model.set("status", Status.DRAFT);
          model.invalidateForm();
        }
      },
      onModelLoad: (model) => {
        model.on("property:change", (property, value) => {
          if (_.isEqual("location", property)) {
            model.invalidateForm();
          }
        });
      },
      areas: [
        {
          title: "Multi tenancy (Admin only)",
          component: Area,
          visibility: (_) => isSuperuser(),
          fields: [
            {
              label: M("company"),
              property: "companyId",
              control: EntitiesSelectContainer,
              //size: "col-md-6",
              props: {
                id: "multitenant_company",
                allowNull: false,
                searchEnabled: true,
                entity: "company",
              },
            },
          ],
        },
        {
          title: M("facility"),
          actions: (model) => [
            {
              title: M("goToFacilityFrontend"),
              icon: "zmdi zmdi-link",
              type: ACTION_TYPES.CUSTOM,
              component: () =>
                model.get("id") ? (
                  <a
                    href="#"
                    className="small"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(
                        config.get("frontend.url") +
                          `?_mag_f=${model.get("id")}`,
                        "_blank"
                      );
                      
                    }}
                  >
                    <i className="zmdi zmdi-open-in-new"></i>
                    &nbsp;
                    {M("goToFacilityFrontend")}
                  </a>
                ) : null,
            },
          ],
          fields: [
            {
              property: "name",
              placeholder: M("name"),
              label: M("name"),
              size: "col-sm-4",
              control: Text,
              validator: (v) => check(v).notEmpty(),
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              property: "facilityCategoryId",
              control: EntitiesSelectContainer,
              label: M("facilityCategory"),
              size: "col-sm-4",
              props: {
                id: "facilityCategory_facility",
                entity: "facilityCategory",
                allowSearch: true,
              },
            },
            {
              property: "facilitySubCategoryId",
              control: EntitiesSelectContainer,
              label: M("facilitySubCategory"),
              size: "col-sm-4",
              props: {
                id: "facilitySubCategory_facility",
                entity: "facilitySubCategory",
                allowSearch: true,
                parentProperty: "facilityCategoryId",
              },
            },
            {
              property: "email",
              placeholder: M("mail"),
              label: M("mail"),
              size: "col-sm-4",
              control: Text,
              validator: (v) => check(v).notEmpty(),
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              property: "phoneNumber",
              control: Text,
              size: "col-sm-4",
              label: M("phoneNumber"),
              placeholder: M("phoneNumber"),
              validator: (v) => {
                if (_.isEmpty(v) || _.isUndefined(v)) {
                  throw new Error("Field mustn't be empty");
                }
                let number = parseInt(v);
                if (_.isNaN(number)) {
                  throw new Error("Field must be a number");
                }
              },
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              property: "address.country",
              control: Text,
              size: "col-sm-4",
              label: M("country"),
              placeholder: M("country"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "address.state",
              control: Text,
              size: "col-sm-4",
              label: M("region"),
              placeholder: M("region"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "address.city",
              control: Text,
              size: "col-sm-4",
              label: M("city"),
              placeholder: M("city"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "address.street",
              control: Text,
              size: "col-sm-4",
              label: M("street"),
              placeholder: M("street"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "address.zipCode",
              control: Text,
              size: "col-sm-4",
              label: M("zipCode"),
              placeholder: M("zipCode"),
              validator: (v) => {
                if (_.isEmpty(v) || _.isUndefined(v)) {
                  throw new Error("Field mustn't be empty");
                }
                let number = parseInt(v);
                if (_.isNaN(number)) {
                  throw new Error("Field must be a number");
                }
              },
              sanitizer: (value) => sanitize(value).trim(),
            },
          ],
        },
        paymentAccountAreaDescriptor(),
        {
          title: M("internationalization"),
          fields: [
            {
              property: "allowedLanguages",
              label: M("allowedLanguages"),
              size: "col-sm-4",
              control: EntitiesSelectContainer,
              props: {
                id: "allowedLanguages_internationalization",
                entity: "language",
                allowNull: false,
                multiple: "true",
              },
            },
            {
              property: "defaultLanguage",
              label: M("defaultLanguage"),
              size: "col-sm-4",
              control: EntitiesSelectContainer,
              props: {
                id: "defaultLanguage_internationalization",
                entity: "language",
                allowNull: false,
              },
              sanitizer: (v) => sanitize(v).trim(),
            },
            {
              property: "timezone",
              label: M("timezone"),
              size: "col-sm-4",
              control: ValuesSelectContainer,
              props: {
                id: "timezone_internationalization",
                collection: "time-zones",
                allowNull: false,
                mode: "single",
                searchEnabled: true,
              },
              sanitizer: (v) => sanitize(v).trim(),
            },
          ],
        },
        {
          title: M("descriptions"),
          collapsible: false,
          fields: [
            {
              property: null,
              control: DescriptionsControl,
              size: "col-sm-12",
              props: {
                getFields: (l) => [
                  {
                    property: "summary",
                    label: `${M("summary")} (${l})`,
                    placeholder: M("summary"),
                    control: Text,
                    validate: (v) => check(v).notEmpty(),
                    sanitizer: (v) => sanitize(v).trim(),
                  },
                  {
                    property: "description",
                    label: `${M("description")} (${l})`,
                    placeholder: M("description"),
                    control: TextArea,
                    validate: (v) => check(v).notEmpty(),
                    sanitizer: (v) => sanitize(v).trim(),
                  },
                ],
                getDataSource: (model) =>
                  modelPropertySource(model, "allowedLanguages"),
              },
            },
          ],
        },
        {
          title: M("profile"),
          collapsible: false,
          fields: [
            {
              property: "logoImage",
              control: Attachment,
              size: "col-sm-6",
              props: {
                preview: true,
                accept: {
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
                },
              },
              label: M("logoImage"),
            },
            {
              property: "mainImage",
              control: Attachment,
              size: "col-sm-6",
              props: {
                preview: true,
                accept: {
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
                },
              },
              label: M("mainImage"),
            },
            {
              property: "gallery",
              control: Attachment,
              size: "col-sm-12",
              props: {
                preview: true,
                accept: {
                  "image/png": [".png"],
                  "image/jpeg": [".jpg"],
                },
                multiple: true,
              },
              label: M("gallery"),
            },
          ],
        },
        {
          title: M("position"),
          collapsible: false,
          fields: [
            {
              property: "location",
              control: GoogleMaps,
              label: null,
              size: "col-md-12",
              props: {
                height: "480px",
              },
            },
            {
              property: "location.lat",
              control: ReadOnlyText,
              label: M("latitude"),
              size: "col-sm-6",
              placeholder: M("latitude"),
            },
            {
              property: "location.lng",
              control: ReadOnlyText,
              label: M("longitude"),
              size: "col-sm-6",
              placeholder: M("longitude"),
            },
          ],
        },
      ],
    },
  },
});
