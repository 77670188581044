import React, { useEffect, useState } from "react";
import _ from "underscore";
import { uuid as getUuid } from "../../utils/lang";

export function useUuid(uuid) {
  const [_uuid, setUuid] = useState(
    !_.isEmpty(uuid) && _.isString(uuid) ? uuid : getUuid()
  );

  return _uuid;
}

export function useRefDimensions(ref) {
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  useEffect(() => {
    function updateDimensions() {
      const boundingRect = ref.current.getBoundingClientRect(),
        { width, height } = boundingRect;

      setDimensions({
        width: width,
        height: height,
      });
    }
    const resizeObserver = new ResizeObserver(updateDimensions);

    if (!_.isEmpty(ref?.current)) {
      resizeObserver.observe(ref.current);
      updateDimensions();
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return dimensions;
}
