import _ from "underscore";
import { OrderStatus } from "../../model/enums";
import M from "../../strings";
import { DateTime, ReadOnlyText } from "../components/forms";
import { ActiveCell, DateCell, TextCell } from "../components/grids";
import EntitiesGridControl from "../components/controls/entitiesGridControl";
import { getSymbol } from "../../utils/currency";
import Price from "../components/controls/price";
import { entitySearchForm } from "../screens/entities/commonFields";
import { getUrlParameter } from "../utils/ui";
import { SEARCH_FORM_DATE_DESCRIPTOR, SEARCH_FORM_NUMBER_DESCRIPTOR, SEARCH_FORM_SELECT_DESCRIPTOR } from "../../model/searchForms";
import { fromEnum } from "../../utils/datasource";

export default () => ({
  
  grid: {
    quickSearchEnabled: true,
    canDelete: false,
    canSelectAll: false,
    title: M("bookings"),
    canCreate: false,
    descriptor: {
      columns: [
        {
          property: "customerName",
          header: M("customer"),
          cell: TextCell,
          sortable: true,
          searchable: true,
          searchForm: entitySearchForm(
            "customerId.value",
            M("customer"),
            "customer",
            true,
            true
          ),
        },
        {
          property: "experience.name",
          header: M("experience"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          searchForm: entitySearchForm(
            "experienceId.value",
            M("experience"),
            "experience",
            true,
            true
          ),
        },
        {
          property: "experience.facilityName",
          header: M("facility"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          searchForm: entitySearchForm(
            "facilityId.value",
            M("facility"),
            "facility",
            true,
            true
          ),
        },
        {
          property: "start",
          header: M("startDate"),
          cell: DateCell,
          sortable: true,
          searchable: true,
          searchForm: SEARCH_FORM_DATE_DESCRIPTOR("start")
        },
        {
          property: "end",
          header: M("endDate"),
          cell: DateCell,
          sortable: true,
          searchable: true,
          searchForm: SEARCH_FORM_DATE_DESCRIPTOR("end")
        },
        {
          property: "numberOfPeople",
          header: M("numberOfPeople"),
          cell: TextCell,
          sortable: false,
          searchable: true,
        },
        {
          property: "price",
          header: M("servicePrice"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          props: {
            formatter: (v) => v.value + " " + getSymbol(v.currencyCode),
          },
        },
        {
          property: "extrasPrice",
          header: M("extrasPrice"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          props: {
            formatter: (v) => v.value + " " + getSymbol(v.currencyCode),
          },
        },
        {
          property: "total",
          header: M("totalPrice"),
          cell: TextCell,
          sortable: false,
          searchable: true,
          props: {
            formatter: (v) => v.value + " " + getSymbol(v.currencyCode),
          },
        },
        {
          property: "status",
          header: M("status"),
          cell: ActiveCell,
          sortable: true,
          searchable: true,
          searchForm: SEARCH_FORM_SELECT_DESCRIPTOR("status", fromEnum(OrderStatus)),
          props: {
            items: [
              {
                value: OrderStatus.CANCELLED,
                className: "disabled",
              },
              {
                value: OrderStatus.CONFIRMED,
                className: "active",
              },
              {
                value: OrderStatus.PAID,
                className: "paid",
              },
              {
                value: OrderStatus.NOT_PAID,
                className: "not-paid",
              },
              {
                value: OrderStatus.EXPRIRED,
                className: "closed",
              },
              {
                value: OrderStatus.WAITING,
                className: "waiting",
              },
            ],
          },
        },
      ],
    },
  },
  form: {
    getTitle(data, params) {
      return [{ title: M("booking") }];
    },
    canSave: () => false,
    showFloatingSaveBtn: false,
    subtitle: null,
    descriptor: {
      fields: [
        {
          property: "customerName",
          label: M("customer"),
          size: "col-sm-4",
          control: ReadOnlyText,
          sortable: true,
          searchable: true,
        },
        {
          property: "experience.name",
          label: M("experience"),
          size: "col-sm-4",
          control: ReadOnlyText,
          sortable: true,
          searchable: true,
        },
        {
          property: "extras",
          label: M("extra"),
          size: "col-sm-4",
          control: ReadOnlyText,
          sortable: true,
          searchable: true,
          props: {
            formatter: (value) => {
              if (!_.isEmpty(value)) {
                return value.map( v => v.description).join(", ");
              }
              else{
                return "Nessun extra";
              }
            }
          },
        },
        {
          property: "start",
          label: M("startedAt"),
          size: "col-sm-4",
          control: ReadOnlyText,
          sortable: true,
          searchable: false,
          props: {
            formatter: (value) => {
              if (value) {
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
              }
              return "Nessuna data";
            }
            }
        },
        {
          property: "end",
          label: M("endedAt"),
          size: "col-sm-4",
          control: ReadOnlyText,
          sortable: true,
          searchable: false,
          props: {
            formatter: (value) => {
              if (value) {
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
              }
              return "Nessuna data";
            }
            
            }
        },
        {
          property: "numberOfPeople",
          label: M("numberOfPeople"),
          size: "col-sm-4",
          control: ReadOnlyText,
          sortable: false,
          searchable: false,
        },
        {
          property: "price.value",
          label: M("servicePrice"),
          size: "col-sm-4",
          control: Price,
          sortable: false,
          searchable: false,
          props: {
            getCurrencyCode: (model) =>
              model.get("currencyCode") ?? getCurrency(),
            disabled: true,
          },
        },
        {
          property: "extrasPrice.value",
          label: M("extrasPrice"),
          size: "col-sm-4",
          control: Price,
          sortable: false,
          searchable: false,
          props: {
            getCurrencyCode: (model) =>
              model.get("currencyCode") ?? getCurrency(),
            disabled: true,
          },
        },
        {
          property: "total.value",
          label: M("totalPrice"),
          size: "col-sm-4",
          control: Price,
          sortable: false,
          searchable: false,
          props: {
            getCurrencyCode: (model) =>
              model.get("currencyCode") ?? getCurrency(),
            disabled: true,
          },
        },
      ],
    },
  },
});
