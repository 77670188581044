import * as http from "../aj/http";
import * as preferences from "../framework/preferences";
import * as config from "../framework/config";
import _ from "underscore";
import * as responses from "./responses";
import { safeGet } from "../utils/lang";
import { isNotEmpty } from "../framework/utils";
import * as NotificationCenter from "../utils/notificationCenter";
import * as aj from "../aj";
import * as Actions from "../actions/types";
import { addToken } from "./utils";

const TOKEN_REFRESH_TIMEOUT = 4 * 60 * 1000;

export const AUTH_TYPES = {
  MAIL: "mail",
  TOKEN: "token",
};

let _loggedUser;
let _crudPermissions = [];
let _allowedLanguages = [];
let _language = "IT";
let _currency = "EUR";
let _sessionToken;
let _tokenRefreshTimeout;

const STOP_OBJ = {};

function stop() {
  return STOP_OBJ;
}

function wrap(r, fn) {
  if (r == STOP_OBJ) {
    return STOP_OBJ;
  } else {
    return fn(r);
  }
}

export function start(data) {
  return new Promise((resolve, reject) => {
    _loggedUser = null;
    _sessionToken = null;
    _crudPermissions = [];
    _allowedLanguages = [];
    _language = "IT";
    _currency = "EUR";

    preferences
      .load()
      .then(() => {
        return login(data);
      })
      .then((response) => {
        const {
          token,
          user,
          crudPermissions,
          allowedLanguages,
          language,
          currency,
        } = response;

        preferences.set("session.type", data.type);
        preferences.set("session.token", token);

        _sessionToken = token;
        _loggedUser = user;
        _crudPermissions = crudPermissions;
        _allowedLanguages = allowedLanguages;
        _language = language;
        _currency = currency;

        return preferences.save();
      })
      .then((r) => {
        startTokenUpdate();
        resolve(_loggedUser);
      })
      .catch((e) => {
        destroy();
        reject(e);
      });
  });
}

export function resume() {
  return new Promise((resolve, reject) => {
    _loggedUser = null;
    _sessionToken = null;
    _crudPermissions = [];
    _allowedLanguages = [];
    _language = "IT";
    _currency = "EUR";

    preferences
      .load()
      .then(() => {
        const type = preferences.get("session.type"),
          token = preferences.get("session.token");

        if (
          (type === AUTH_TYPES.MAIL || type === AUTH_TYPES.TOKEN) &&
          isNotEmpty(token)
        ) {
          return start({
            type: AUTH_TYPES.TOKEN,
            token: token,
          });
        } else {
          reject(responses.ERROR);
          return stop();
        }
      })
      .then((r) => {
        return wrap(r, () => {
          resolve(r);
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function destroy() {
  _loggedUser = null;
  _sessionToken = null;
  _crudPermissions = [];
  _allowedLanguages = [];
  _language = "IT";
  _currency = "EUR";

  if (_tokenRefreshTimeout) {
    clearTimeout(_tokenRefreshTimeout);
    _tokenRefreshTimeout = null;
  }

  return preferences
    .load()
    .then(() => {
      preferences.set("session.type", null);
      preferences.set("session.token", null);
      return preferences.save();
    })
    .catch((e) => {
      logger.e(e);
    });
}

function login(data = {}) {
  let url, authData;

  switch (data.type) {
    case AUTH_TYPES.TOKEN:
      url = config.get("tokenLogin.url");
      authData = _.pick(data, "token");
      break;
    case AUTH_TYPES.MAIL:
    default:
      url = config.get("login.url");
      authData = _.pick(data, "mail", "password");
  }

  return new Promise((resolve, reject) => {
    http
      .post(url, authData)
      .then((json) => {
        if (_.isEmpty(json)) {
          reject(responses.ERROR);
        } else {
          let response = JSON.parse(json);

          if (responses.OK != response.responseCode) {
            reject(response.responseCode);
          } else {
            resolve(response);
          }
        }
      })
      .catch((e) => {
        logger.e("Error logging in:", e);
        reject(responses.ERROR);
      });
  });
}

function refreshToken() {
  const url = config.get("tokenRefresh.url"),
    token = _sessionToken;
  return new Promise((resolve, reject) => {
    http
      .get(url, undefined, addToken({}))
      .then((responseJson) => {
        if (_.isEmpty(responseJson)) {
          reject(responses.ERROR);
        } else {
          let response = JSON.parse(responseJson);

          if (responses.OK != response.responseCode) {
            reject(response.responseCode);
          } else {
            resolve(response);
          }
        }
      })
      .catch((e) => {
        logger.e("Error refreshing token:", e);
        reject(responses.ERROR);
      });
  });
}

function startTokenUpdate() {
  _tokenRefreshTimeout = setTimeout(() => {
    refreshToken()
      .then((response) => {
        const token = response.token;
        if (token) {
          return updateSessionToken(token);
        } else {
          throw new Error(
            "Error in session token update: no token returned from server"
          );
        }
      })
      .then(() => {
        startTokenUpdate();
      })
      .catch((e) => {
        destroy();
        aj.dispatch({
          type: Actions.LOGOUT,
        });
        NotificationCenter.invoke("logout");
      });
  }, TOKEN_REFRESH_TIMEOUT);
}

export function getLoggedUser() {
  return _loggedUser;
}

export function isLoggedIn() {
  return _loggedUser != null;
}

export function getSessionToken() {
  return _sessionToken;
}

export function updateLoggedUser(user) {
  _loggedUser = user;
}

export function updateSessionToken(token) {
  _sessionToken = token;
  return preferences
    .load()
    .then(() => {
      preferences.set("session.token", _sessionToken);
      return preferences.save();
    })
    .catch((e) => {
      logger.e(e);
    });
}

/**
 * Check if user has permissions
 * @param permissions --> array string ex: [document:list, document:new]
 */

export function isSuperuser() {
  const user = getLoggedUser();
  if (!user) {
    return false;
  }

  const roles = user.roles || [];

  return _.any(roles, (r) => r === "ROLE_ADMIN");
}

export function hasRole(rolesToCheck) {
  if (_.isEmpty(rolesToCheck)) return true;

  if (!_.isArray(rolesToCheck)) {
    rolesToCheck = [rolesToCheck];
  }

  const user = getLoggedUser();
  if (!user) {
    return false;
  }

  const roles = user.roles || [];
  const und = _;

  return und.any(roles, (r) => und.any(rolesToCheck, (p) => p == r));
}

export function hasCrudPermissions(crudPermissionsToCheck) {
  if (_.isEmpty(crudPermissionsToCheck) || isSuperuser()) return true;

  const user = getLoggedUser();
  if (!user) {
    return false;
  }

  const crudPermissions = _crudPermissions || [];

  return _.any(crudPermissions, (r) =>
    _.any(crudPermissionsToCheck, (p) => p == r)
  );
}

export function getLanguage() {
  return _language;
}

export function getCurrency() {
  return _currency;
}

export function getAllowedLanguages() {
  return _allowedLanguages;
}

export const Permission = {
  LIST: "list",
  NEW: "new",
  EDIT: "edit",
  DELETE: "delete",
  SAVE: "save",
};
