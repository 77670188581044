import { check, sanitize } from "../../libs/validator";
import M from "../../strings";
import ColorCell from "../components/cells/colorCell";
import { ValuesSelectContainer } from "../components/containers";
import Percentage from "../components/controls/percentage";
import SubGridControl from "../components/controls/subGridControl";
import { Color, Number, Text } from "../components/forms";
import { DateCell, TextCell } from "../components/grids";
import MultiTenantArea from "./multiTenantArea";
import multiTenantCells from "./multiTenantCells";

export default () => ({
  grid: {
    title: M("pricingRule"),
    quickSearchEnabled: true,
    descriptor: {
      columns: [
        {
          property: "name",
          header: M("name"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "colorCode",
          header: M("color"),
          cell: ColorCell,
          sortable: true,
          searchable: true,
        },
        ...multiTenantCells(),
      ],
    },
  },
  form: {
    title: M("editPricingRule"),
    descriptor: {
      areas: [
        MultiTenantArea,
        {
          title: M("generalInfo"),
          fields: [
            {
              property: "name",
              control: Text,
              label: M("name"),
              placeholder: M("name"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
            {
              property: "colorCode",
              control: Color,
              label: M("color"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
          ],
        },
        {
          title: M("timeBasedVariations"),
          fields: [
            {
              property: "timeBasedVariations",
              control: SubGridControl,
              title: "",
              props: {
                isSelectionWithCheck: false,
                gridDescriptor: {
                  tableClassName: "table table-hover table-striped",
                  columns: [
                    {
                      property: "timeUnit",
                      header: M("timeUnit"),
                      cell: TextCell,
                    },
                    { property: "time", header: M("time"), cell: TextCell },
                    {
                      property: "variationType",
                      header: M("variationType"),
                      cell: TextCell,
                    },
                    {
                      property: "variation",
                      header: M("variation"),
                      cell: TextCell,
                    },
                  ],
                },
                formDescriptor: {
                  fields: [
                    {
                      property: "timeUnit",
                      control: ValuesSelectContainer,
                      label: M("timeUnit"),
                      size: "col-md-6",
                      props: {
                        id: "pricingrule_timeunit",
                        collection: "time-based-variation-time-units",
                        mode: "single",
                        allowNull: false,
                        multiple: false,
                      },
                      validator: (value) => check(value).notEmpty(),
                      sanitizer: (value) => sanitize(value).trim(),
                    },
                    {
                      property: "time",
                      size: "col-md-6",
                      control: Number,
                      label: M("time"),
                      placeholder: M("time"),
                    },
                    {
                      property: "variationType",
                      control: ValuesSelectContainer,
                      label: M("variationType"),
                      props: {
                        id: "pricingrule_variationType",
                        collection: "pricing-rule-variation-types",
                        mode: "single",
                        allowNull: false,
                        multiple: false,
                      },
                      validator: (value) => check(value).notEmpty(),
                      sanitizer: (value) => sanitize(value).trim(),
                    },
                    {
                      property: "variation",
                      control: Percentage,
                      label: M("variation"),
                      placeholder: M("variation"),
                    },
                  ],
                },
              },
            },
          ],
        },
        {
          title: M("peopleBasedVariations"),
          noPadding: true,
          fields: [
            {
              property: "peopleBasedVariations",
              title: "",
              control: SubGridControl,
              props: {
                isSelectionWithCheck: false,
                gridDescriptor: {
                  tableClassName: "table table-hover table-striped",
                  columns: [
                    {
                      property: "numberOfPeople",
                      header: M("numberOfPeople"),
                      cell: TextCell,
                    },
                    {
                      property: "variationType",
                      header: M("variationType"),
                      cell: TextCell,
                    },
                    {
                      property: "variation",
                      header: M("variation"),
                      cell: TextCell,
                    },
                  ],
                },
                formDescriptor: {
                  fields: [
                    {
                      property: "numberOfPeople",
                      control: Number,
                      label: M("numberOfPeople"),
                      placeholder: M("numberOfPeople"),
                    },
                    {
                      property: "variationType",
                      control: ValuesSelectContainer,
                      label: M("variationType"),
                      props: {
                        id: "pricingrule_variationType",
                        collection: "pricing-rule-variation-types",
                        mode: "single",
                        allowNull: false,
                        multiple: false,
                      },
                      validator: (value) => check(value).notEmpty(),
                      sanitizer: (value) => sanitize(value).trim(),
                    },
                    {
                      property: "variation",
                      control: Percentage,
                      label: M("variation"),
                      placeholder: M("variation"),
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
  },
});
