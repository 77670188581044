import * as aj from "../aj/index";
import { completed, failed } from "../utils/ajex";
import {
  LOAD_EXPERIENCE_PRICINGS,
  CREATE_EXPERIENCE_PRICING,
  UPDATE_EXPERIENCE_PRICING,
  DELETE_EXPERIENCE_PRICING,
  SAVE_EXPERIENCE_PRICINGS,
  GET_EXPERIENCE_BASE_TARIFF,
  CLEAN_EXPERIENCE_PRICINGS_CHANGES,
  CLEAR_EXPERIENCE_PRICINGS,
} from "../actions/types";
import _ from "underscore";
import { EXPERIENCE_PRICINGS } from "./types";
import { updatedList } from "../utils/lang";

const defaultState = function () {
  return {
    baseTariff: null,
    pricings: null,
    created: [],
    updated: [],
    deleted: [],
  };
};

export const ExperiencePricingsStore = aj.createStore(
  EXPERIENCE_PRICINGS,
  (state = defaultState(), action) => {
    switch (action.type) {
      case completed(LOAD_EXPERIENCE_PRICINGS):
        return _.assign(state, { pricings: action.pricings });

      case completed(GET_EXPERIENCE_BASE_TARIFF):
        return _.assign(state, { baseTariff: action.baseTariff });

      case CREATE_EXPERIENCE_PRICING:
        return _.assign(state, {
          created: [...state.created, action.pricing],
          pricings: updatedList(
            state.pricings,
            (p) => p.id === action.pricing.id,
            (p) => _.assign({}, p, action.pricing),
            true
          ),
        });

      case UPDATE_EXPERIENCE_PRICING: {
        const isCreated = state.created.some((p) => p.id === action.pricing.id);
        return _.assign(state, {
          created: isCreated
            ? [
                ...state.created.filter((p) => p.id !== action.pricing.id),
                action.pricing,
              ]
            : state.created,
          updated: !isCreated
            ? [
                ...state.updated.filter((p) => p.id !== action.pricing.id),
                action.pricing,
              ]
            : state.updated,
          pricings: updatedList(
            state.pricings,
            (p) => p.id === action.pricing.id,
            (p) => _.assign({}, p, action.pricing)
          ),
        });
      }

      case DELETE_EXPERIENCE_PRICING: {
        const isCreated = state.created.some((p) => p.id === action.pricing.id);
        return _.assign(state, {
          created: [
            ...state.created.filter((p) => p.id !== action.pricing.id),
            action.pricing,
          ],
          updated: [
            ...state.updated.filter((p) => p.id !== action.pricing.id),
            action.pricing,
          ],
          deleted: isCreated
            ? state.deleted
            : [...state.deleted, action.pricing],
          pricings: state.pricings.filter((p) => p.id !== action.pricing.id),
        });
      }

      case CLEAN_EXPERIENCE_PRICINGS_CHANGES:
        return _.assign(state, { created: [], updated: [], deleted: [] });

      case CLEAR_EXPERIENCE_PRICINGS:
        return defaultState();
    }
  }
);

ExperiencePricingsStore.defaultState = defaultState;

export default ExperiencePricingsStore;
