"use strict";

import * as config from "../framework/config";
import { download, get, post } from "./utils";

export function register(name, mail, password) {
  return post(config.get("account.register.url"), { name, mail, password });
}

export function recover(email) {
  return post(config.get("account.recover.url"), { email });
}

export function confirm(activationCode) {
  return post(config.get("account.confirm.url"), { activationCode });
}

export function getCoverImage(userId) {
  return get(`${config.get("account.url")}/${userId}/cover`);
}

export function getProfileImage(userId) {
  return download(`${config.get("account.url")}/me/image`);
}

export function changePassword(currentPassword, password, passwordConfirm) {
  return post(config.get("account.changePassword.url"), {
    currentPassword: currentPassword ? currentPassword : "",
    password: password ? password : "",
    passwordConfirm: passwordConfirm ? passwordConfirm : "",
  });
}

export function resetUserPassword(email) {
  return post(config.get("account.resetUserPassword.url"), { email });
}

export function requestRecoveryCode(email) {
  return post(config.get("account.requestRecoveryCode.url"), { email });
}

export function validateRecoveryCode(mail, code) {
  return post(config.get("account.validateRecoveryCode.url"), { mail, code });
}

export function resetPassword(mail, code, password, passwordConfirm) {
  return post(config.get("account.resetPassword.url"), {
    mail,
    code,
    password,
    passwordConfirm,
  });
}
