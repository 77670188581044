import React from "react";
import { getSymbol } from "../../../utils/currency";
import { optional } from "../../../utils/lang";
import { useModelValue } from "../../utils/model";

const Price = ({
  model,
  field,
  disabled,
  validationResult,
  getCurrencyCode,
}) => {
  const property = field.property;
  const [value, setModelValue] = useModelValue(model, property);
  const placeholder = field.placeholder;
  const currencyCode = getCurrencyCode ? "EUR" : getCurrencyCode(model);
  const symbol = getSymbol(currencyCode);

  let inputClassName = "form-control input-sm ";
  if (!validationResult.valid && !_.isEmpty(validationResult.message)) {
    inputClassName += "is-invalid";
  }

  function onValueChange(e) {
    const value = e.target.value;

    if (
      value == "" ||
      value == "-" ||
      value.match(/^-?(\d+\.?\d{0,9}|\.\d{1,9})$/)
    ) {
      setModelValue(value);
    }
  }

  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <a>{symbol}</a>
        </span>
      </div>
      <input
        disabled={disabled}
        type="text"
        className={inputClassName}
        data-property={property}
        placeholder={placeholder}
        onChange={onValueChange}
        value={optional(value, "")}
      />
      <i className="form-group__bar"></i>
      {!validationResult.valid && !_.isEmpty(validationResult.message) && (
        <div className="invalid-feedback">{validationResult.message}</div>
      )}
    </div>
  );
};

export default Price;
