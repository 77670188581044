import React, { useCallback, useEffect } from "react";
import Stack from "../components/stack";
import M from "../../strings";
import { getSymbol } from "../../utils/currency";
import { formatLocalDate } from "../../utils/date";
import { formatLocalTime } from "../../utils/time";
import _ from "underscore";
import { navigate } from "../utils/ui";
import { confirm } from "../../plugins";

export function BookingDetails({ bookingDetails: booking, onCancel }) {
  const onPreCancel = useCallback(
    async function onPreCancel() {
      try {
        await confirm(
          M("confirmCancelBooking"),
          M("confirmCancelBookingDescription")
        );
        if (onCancel) {
          onCancel(booking.id);
        }
      } catch (e) {
        return;
      }
    },
    [booking, onCancel]
  );

  const onGoToOrder = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(
        `/entities/section/orders/customerOrder/${booking.customerOrderId}`,
        true
      );
    },
    [booking]
  );

  const onGoToCustomer = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(`/entities/section/orders/customer/${booking.customerId}`, true);
    },
    [booking]
  );

  return (
    <Stack className="experience-details animate animate-fade flex-1">
      {(booking.status === "Basket" || booking.status === "Confirmed") && (
        <div className="alert alert-warning">{M("waitingForPayment")}</div>
      )}
      {booking.status === "NotPaid" && (
        <div className="alert alert-danger">{M("paymentCancelled")}</div>
      )}
      {booking.id === "channel-manager" && (
        <div className="alert alert-info">from Booking.com</div>
      )}

      <div className="experience-details__link">
        <a href="#" onClick={onGoToOrder}>
          {M("order")} {booking.orderCode}
        </a>
      </div>
      <div className="experience-details__title">{booking.experienceName}</div>
      <div className="experience-details__title">
        {formatLocalDate(booking.start)}
        {!booking.slot && " > "}
        {!booking.slot && formatLocalDate(booking.end)}
        {booking.slot && " - "}
        {booking.slot && `${formatLocalTime(booking.slot)}`}
      </div>
      <div className="experience-details__title">
        <a href="#" onClick={onGoToCustomer}>
          {booking.customerFullName}
        </a>
      </div>
      <Stack
        direction="row"
        gap={2}
        align="center"
        className="experience-details__link"
      >
        <i className="zmdi zmdi-email" />
        <a href={`mailto:${booking.customerEmail}`}>{booking.customerEmail}</a>
      </Stack>
      <Stack
        direction="row"
        gap={2}
        align="center"
        className="experience-details__link"
      >
        <i className="zmdi zmdi-phone" />
        <a href={`tel:${booking.customerPhoneNumber}`}>
          {booking.customerPhoneNumber ?? "329 1234567"}
        </a>
      </Stack>
      <div className="experience-details__link">
        <Stack direction="row" gap={2} align="center">
          <i className="zmdi zmdi-assignment" />
          <span>
            Prenotazioni nello stesso ordine:{" "}
            {booking.numberOfBookingsInSameOrder}
          </span>
        </Stack>
      </div>
      <div className="experience-details__divider"></div>
      <div className="experience-details__receipt">
        <Stack direction="row" gap={1}>
          <div className="experience-details__receipt__title flex-1">
            {booking.experienceName}
          </div>
          <div className="experience-details__receipt__title">
            {getSymbol(booking.experiencePrice.currencyCode)}{" "}
            {booking.experiencePrice.value}
          </div>
        </Stack>
        <div className="experience-details__receipt__subtitle">
          {booking.numberOfDays} {M("days")}, {booking.numberOfPeople}{" "}
          {M("people")}
        </div>
        <Stack direction="row" align="center" gap={2}>
          <div
            className="experience-details__receipt__pricing-rule-color"
            style={{ backgroundColor: booking.pricingRuleColorCode }}
          ></div>
          <div className="experience-details__link flex-1">
            {booking.pricingRuleName}
          </div>
        </Stack>
        {!_.isEmpty(booking.extras) && (
          <div className="experience-details__divider"></div>
        )}

        {booking.extras.map((extra) => (
          <>
            <Stack key={extra.id} direction="row" gap={1}>
              <div className="experience-details__receipt__title flex-1">
                {extra.name}
              </div>
              <div className="experience-details__receipt__title">
                {getSymbol(extra.price.currencyCode)} {extra.price.value}
              </div>
            </Stack>
            <div className="experience-details__receipt__subtitle">
              Quantità x {extra.quantity}
            </div>
          </>
        ))}

        <div className="experience-details__divider"></div>
        <Stack direction="row" gap={1}>
          <div className="experience-details__receipt__total flex-1">
            {M("total")}
          </div>
          <div className="experience-details__receipt__total">
            {getSymbol(booking.total.currencyCode)} {booking.total.value}
          </div>
        </Stack>
        <div className="experience-details__receipt__subtitle">
          {M("vatIncluded")}
        </div>
      </div>
      <Stack justify="end" direction="column" className="flex-1">
        <button
          className="btn btn-secondary btn-outline btn-block"
          onClick={onPreCancel}
        >
          {M("cancelBooking")}
        </button>
      </Stack>
    </Stack>
  );
}
