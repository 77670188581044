import { isSuperuser } from "../../api/session";
import { Query } from "../../framework/query";
import { check, sanitize } from "../../libs/validator";
import M from "../../strings";
import { EntitiesSelectContainer } from "../components/containers";
import SubGridControl from "../components/controls/subGridControl";
import { Text } from "../components/forms";
import { TextCell } from "../components/grids";
import MultiTenantArea from "./multiTenantArea";

export default () => ({
  grid: {
    title: M("mappedProperties"),
    quickSearchEnabled: true,
    descriptor: {
      columns: [
        {
          property: "facilityId",
          header: M("facility"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "propertyId",
          header: M("property"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
      ],
    },
  },
  form: {
    title: M("channelManagerPropertyMapping"),
    canGoBack: () => false,
    descriptor: {
      areas: [
        MultiTenantArea,
        {
          title: M("facilityMapping"),
          fields: [
            {
              property: "propertyId",
              control: Text,
              label: M("booking_comPropertyId"),
              placeholder: M("propertyId"),
              validator: (value) => check(value).notEmpty(),
              sanitizer: (value) => sanitize(value).trim(),
            },
          ],
        },
        {
          title: null,
          visibility: (model) => !!model.get("propertyId"),
          fields: [
            {
              property: "mappedRooms",
              control: SubGridControl,
              props: {
                isSelectionWithCheck: false,
                gridDescriptor: {
                  tableClassName: "table table-hover table-striped",

                  columns: [
                    {
                      property: "experienceId",
                      header: M("experience"),
                      cell: TextCell,
                    },
                    { property: "roomId", header: M("roomId"), cell: TextCell },
                  ],
                },
                formDescriptor: {
                  fields: [
                    {
                      property: "experienceId",
                      control: EntitiesSelectContainer,
                      label: M("experience"),
                      validator: (value) => check(value).notEmpty(),
                      props: {
                        entity: "experience",
                        mode: "single",
                        allowNull: false,
                        multiple: false,
                      },
                    },
                    {
                      property: "roomId",
                      control: EntitiesSelectContainer,
                      label: M("room"),
                      validator: (value) => check(value).notEmpty(),
                      props: {
                        entity: "room",
                        mode: "single",
                        allowNull: false,
                        multiple: false,
                        query: (model) =>
                          new Query().eq(
                            "propertyId",
                            model.get("_parent.propertyId")
                          ),
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
  },
});
