import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";

export const Tabs = ({ children, onTabSelected }) => {
  const [active, setActive] = useState(children ? children[0] : null);

  useEffect(() => {
    if (children && !children.some((a) => a === active)) {
      setActive(children.find((c) => c.props.active) ?? children[0]);
    }
  }, [children]);

  function onSelectTab(tab) {
    if (onTabSelected) {
      onTabSelected(tab.props.id);
    }
  }

  return (
    <div className="tab-container">
      <ul className="nav nav-tabs">
        {children &&
          children.map((tab) => (
            <li className="nav-item">
              <a
                className={cn("nav-link", { active: active === tab })}
                onClick={() => onSelectTab(tab)}
              >
                {tab.props.title}
              </a>
            </li>
          ))}
      </ul>

      {active && (
        <div className="tab-content">
          <div className="tab-pane active">{active.props.children}</div>
        </div>
      )}
    </div>
  );
};

export const Tab = ({ id, title }) => {
  return <></>;
};
