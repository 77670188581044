"use strict";

import React from "react";
import M from "../../strings";
import _ from "underscore";

export class GoogleMaps extends React.Component {
  mapRef = React.createRef();
  searchBoxRef = React.createRef();
  map = null;
  searchBox = null;

  componentDidMount() {
    const apiKey = 'AIzaSyBcQ1vDmecuqBjIrO2f3l0isPTlPOPvcKI'; // Sostituisci con la tua chiave API di Google Maps

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;

    script.addEventListener('load', () => {
      this.initMap();
    });

    document.head.appendChild(script);
  }

  initMap() {
    let field = this.props.field;

    let options = {
      zoom: 15,
      center: { lat: 40.67188, lng: 16.568391 },
      streetViewControl: false,
    };

    this.map = new window.google.maps.Map(
      this.mapRef.current,
      options
    );

    this.searchBox = new window.google.maps.places.SearchBox(
      this.searchBoxRef.current
    );

    this.map.addListener("click", (e) => {
      let location = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      this.handleLocationChange(location);
    });

    this.searchBox.addListener("places_changed", () => {
      const places = this.searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place) => {
        if (place.geometry && place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else if (place.geometry && place.geometry.location) {
          bounds.extend(place.geometry.location);
        }
      });

      this.map.fitBounds(bounds);
    });

    // Inizializzare il marker sulla mappa
    this.marker = new window.google.maps.Marker({
      map: this.map,
    });

    const model = this.props.model;
    model.once("load", () => {
      this.updateMarker();
    });
  }

  handleLocationChange = (location) => {
    let field = this.props.field;
    let model = this.props.model;

    model.set(field.property, location);
    this.updateMarker();
  };

  updateMarker() {
    let field = this.props.field;
    let model = this.props.model;
    let location = model.get(field.property);

    if (!location || !location.lat || !location.lng) {
      // Se non c'è una posizione valida, nascondere il marker
      this.marker.setMap(null);
      return;
    }

    this.marker.setPosition(location);
    this.marker.setMap(this.map);

    // Centrare la mappa sulla posizione selezionata
    this.map.setCenter(location);
  }

  render() {
    let field = this.props.field;
    return (
      <div
        className="full-width"
        style={{
          display: "flex",
          height: this.props.height,
          flexDirection: "column",
        }}
      >
        <div className="quick-search-container">
          <div className="quick-search">
            <i className="zmdi zmdi-search pull-left"></i>
            <div className="quick-search-input-container">
              <input
                ref={this.searchBoxRef}
                type="text"
                placeholder={M("search")}
              />
              <div className="form-control__bar"></div>
            </div>
          </div>
        </div>
        <div ref={this.mapRef} id={"map_" + field.property} style={{ flex: "1" }} />
      </div>
    );
  }
}

