import * as aj from "../aj/index";
import { completed, failed } from "../utils/ajex";
import _ from "underscore";
import { BOOKINGS_CALENDAR } from "./types";
import moment from "moment";
import {
  CALENDAR_LOAD_BOOKINGS,
  CALENDAR_LOAD_AVAILABILITIES,
  CALENDAR_LOAD_EXPERIENCE_PRICINGS,
  CALENDAR_SET_PERIOD,
  CALENDAR_LOAD_EXPERIENCES,
  CALENDAR_OPEN_BOOKING_DETAILS,
  CALENDAR_CLOSE_BOOKING_DETAILS,
  CALENDAR_FORCE_CANCEL_BOOKING,
} from "../actions/types";

const defaultState = function () {
  return {
    bookings: null,
    availabilities: null,
    experiences: null,
    experiencePricings: null,
    period: {
      start: moment().toISOString(),
      end: moment().add(1, "week").toISOString(),
    },
    loading: false,
  };
};

export const BookingsCalendarStore = aj.createStore(
  BOOKINGS_CALENDAR,
  (state = defaultState(), action) => {
    switch (action.type) {
      case CALENDAR_LOAD_BOOKINGS:
        return _.assign(state, { loading: true });
      case completed(CALENDAR_LOAD_BOOKINGS):
        return _.assign(state, { bookings: action.bookings, loading: false });
      case failed(CALENDAR_LOAD_BOOKINGS):
        return _.assign(state, { loading: false });

      case CALENDAR_LOAD_AVAILABILITIES:
        return _.assign(state, { loading: true });
      case completed(CALENDAR_LOAD_AVAILABILITIES):
        return _.assign(state, {
          availabilities: action.availabilities,
          loading: false,
        });
      case failed(CALENDAR_LOAD_AVAILABILITIES):
        return _.assign(state, { loading: false });

      case CALENDAR_LOAD_EXPERIENCE_PRICINGS:
        return _.assign(state, { loading: true });
      case completed(CALENDAR_LOAD_EXPERIENCE_PRICINGS):
        return _.assign(state, {
          experiencePricings: action.pricings,
          loading: false,
        });
      case failed(CALENDAR_LOAD_EXPERIENCE_PRICINGS):
        return _.assign(state, { loading: false });

      case CALENDAR_LOAD_EXPERIENCES:
        return _.assign(state, { loading: true });
      case completed(CALENDAR_LOAD_EXPERIENCES):
        return _.assign(state, {
          experiences: action.experiences,
          loading: false,
        });
      case failed(CALENDAR_LOAD_EXPERIENCES):
        return _.assign(state, { loading: false });

      case CALENDAR_OPEN_BOOKING_DETAILS:
        return _.assign(state, { bookingDetails: null, loading: true });
      case completed(CALENDAR_OPEN_BOOKING_DETAILS):
        return _.assign(state, {
          bookingDetails: action.booking,
          loading: false,
        });
      case failed(CALENDAR_OPEN_BOOKING_DETAILS):
        return _.assign(state, { loading: false });

      case CALENDAR_FORCE_CANCEL_BOOKING:
        return _.assign(state, { loading: true });
      case completed(CALENDAR_FORCE_CANCEL_BOOKING):
        return _.assign(state, {
          loading: false,
          bookingDetails: null,
          bookings: state.bookings?.filter((b) => b.id !== action.bookingId),
        });
      case failed(CALENDAR_FORCE_CANCEL_BOOKING):
        return _.assign(state, { loading: false });

      case CALENDAR_CLOSE_BOOKING_DETAILS:
        return _.assign(state, { bookingDetails: null });

      case CALENDAR_SET_PERIOD:
        return _.assign(state, { period: action.period });
    }
  }
);

BookingsCalendarStore.defaultState = defaultState;

export default BookingsCalendarStore;
