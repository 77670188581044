import _ from "underscore";
import * as aj from "../aj/index";
import * as Actions from "./types";
import { isEmpty, isNotEmpty } from "../framework/utils";
import { MENUS, TYPES } from "../model/menu/menu";
import * as MenuUtils from "../utils/menu";

function isSystemMenu(menuId) {
  if (isEmpty(menuId)) {
    return;
  }

  return _.chain(TYPES).contains(menuId).value();
}

export const setupMenu = aj.createAction(Actions.SETUP_MENU, (data) => {
  const menu = data?.menu;

  aj.dispatch({
    type: Actions.SETUP_MENU,
    menu: menu,
  });
});

export const reloadMenu = aj.createAction(Actions.RELOAD_MENU, (data) => {
  aj.dispatch({
    type: Actions.RELOAD_MENU,
  });
});

export const setupTopMenu = aj.createAction(Actions.SETUP_TOP_MENU, (data) => {
  aj.dispatch({
    type: Actions.SETUP_TOP_MENU,
  });
});

export const reloadTopMenu = aj.createAction(
  Actions.RELOAD_TOP_MENU,
  (data) => {
    aj.dispatch({
      type: Actions.RELOAD_TOP_MENU,
    });
  }
);

export const registerMenu = aj.createAction(Actions.REGISTER_MENU, (data) => {
  const { menu, menuData } = data;

  if (isEmpty(menu) || !_.isString(menu)) {
    return;
  }

  if (isEmpty(menuData) || !_.isObject(menuData) || _.isArray(menuData)) {
    return;
  }

  if (isSystemMenu(menu)) {
    console.warn(
      "registerMenu: attempted to override system menu. Gracefully ignoring request."
    );
    return;
  }

  MENUS[menu] = MenuUtils.sanitizeMenu(menuData, false);
});

export const unregisterMenu = aj.createAction(
  Actions.UNREGISTER_MENU,
  (data) => {
    const { menu } = data;

    if (isEmpty(menu) || !_.isString(menu)) {
      return;
    }

    if (isSystemMenu(menu)) {
      console.warn(
        "unregisterMenu: attempted to delete system menu. Gracefully ignoring request."
      );
      return;
    }

    delete MENUS[menu];
  }
);
