import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import M, { getLanguage } from "../../../strings";

const DAYS = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

function monday() {
  return new Date(1983, 1, 7);
}

function tuestday() {
  return new Date(1983, 1, 8);
}

function wednesdays() {
  return new Date(1983, 1, 9);
}

function thursday() {
  return new Date(1983, 1, 10);
}

function friday() {
  return new Date(1983, 1, 11);
}

function saturday() {
  return new Date(1983, 1, 12);
}

function sunday() {
  return new Date(1983, 1, 13);
}

const WEEK_DATES = [
  monday(),
  tuestday(),
  wednesdays(),
  thursday(),
  friday(),
  saturday(),
  sunday(),
];

function daysToEvents(days) {
  if (!days) {
    return [];
  }

  const events = [];

  days.forEach((day) => {
    if (!day.timeSlots) {
      return;
    }

    const weekDate = WEEK_DATES[day.dayOfWeek - 1].getTime();
    day.timeSlots.forEach((slot) => {
      const start =
        weekDate + slot.start[0] * 60 * 60 * 1000 + slot.start[1] * 60 * 1000;
      const end = start + slot.duration * 1000;
      events.push({
        start: new Date(start),
        end: new Date(end),
        day,
        slot,
      });
    });
  });

  return events;
}

function convertWeekDayToMondayBased(weekDay) {
  if (weekDay == 0) {
    return 7;
  }

  return weekDay;
}

function convertWeekDayToSundayBased(weekDay) {
  if (weekDay == 7) {
    return 0;
  }

  return weekDay;
}

const WeeklyAvailabilityControl = (props) => {
  const model = props.model;
  const field = props.field;

  const [events, setEvents] = useState([]);
  const timegrid = useRef(null);

  useEffect(() => {
    setEvents(daysToEvents(model.get(field.property)));

    return () => setEvents([]);
  }, [props.model.data]);

  useEffect(() => {
    //force call resize to fix calendar width
    setTimeout(() => timegrid.current.getApi().render(), 500);
  }, []);

  function onSelect(info) {
    const dayOfWeek = convertWeekDayToMondayBased(info.start.getDay());
    const days = model.get(field.property) ?? [];
    let day = days.find((d) => d.dayOfWeek == dayOfWeek);
    if (!day) {
      day = {
        dayOfWeek: dayOfWeek,
        timeSlots: [],
      };
      days.push(day);
    }

    day.timeSlots.push({
      start: [info.start.getHours(), info.start.getMinutes(), 0],
      duration: (info.end.getTime() - info.start.getTime()) / 1000.0,
    });

    props.model.set(field.property, days);
    setEvents(daysToEvents(days));
  }

  function deleteSlot(day, slot) {
    const days = model.get(field.property) ?? [];
    const currentDay = days.find((d) => d.dayOfWeek == day.dayOfWeek);
    if (!day) {
      return;
    }
    currentDay.timeSlots = currentDay.timeSlots.filter((s) => s != slot);
    model.set(field.property, days);
    setEvents(daysToEvents(days));
  }

  function onEventUpdated(info) {
    if (info.event.start.getDay() != info.event.end.getDay()) {
      info.revert();
      return;
    }

    const days = model.get(field.property) ?? [];
    const day = days.find(
      (d) => d.dayOfWeek == info.event.extendedProps.day.dayOfWeek
    );
    if (!day) {
      return;
    }
    const slot = info.event.extendedProps.day.timeSlots.find(
      (s) => s == info.event.extendedProps.slot
    );
    if (!slot) {
      return;
    }
    slot.start = [
      info.event.start.getHours(),
      info.event.start.getMinutes(),
      0,
    ];
    slot.duration =
      (info.event.end.getTime() - info.event.start.getTime()) / 1000.0;
    model.set(field.property, days);
    setEvents(daysToEvents(days));
  }

  function onEventClick(info) {
    showSlotDialog(info.event.extendedProps.day, info.event.extendedProps.slot);
  }

  function onSelectAllow(info) {
    const start = info.start;
    const end = info.end;
    const day = start.getDay();
    const dayEnd = end.getDay();
    return day == dayEnd;
  }

  function showSlotDialog(day, slot) {
    /*showDialog({
      getContent: () => (
        <div>
          <p>
            {M("at")} {slot.start} {M("for")} {slot.duration / 60}{" "}
            {M("minutes")}
          </p>
          <p>{M("doYouWantToDeleteThisSlot")}?</p>
        </div>
      ),
      large: true,
      title: M("addDailyTariff"),
      buttons: [
        {
          text: DAYS[day.dayOfWeek - 1],
          dialogResult: DIALOG_RESULT_OK,
          action: () => {
            deleteSlot(day, slot);
            hideDialog();
          },
        },
        {
          text: "OK",
          dialogResult: DIALOG_RESULT_OK,
          action: () => hideDialog(),
        },
      ],
    });
    */
    if (confirm(M("doYouWantToDeleteThisSlot"))) {
      deleteSlot(day, slot);
    }
  }

  return (
    <div className="weekly-availability">
      <FullCalendar
        ref={timegrid}
        initialView="timeGridWeek"
        initialDate={monday()}
        locale={getLanguage()}
        editable={true}
        selectable={true}
        select={onSelect}
        //selectOverlap={false}
        selectAllow={onSelectAllow}
        eventChange={onEventUpdated}
        eventClick={onEventClick}
        events={events}
        plugins={[timeGridPlugin, interactionPlugin]}
        height={500}
        allDaySlot={false}
        headerToolbar={false}
        dayHeaderFormat={{ weekday: "long" }}
        firstDay={1}
        slotDuration={"00:15:00"}
        slotLabelInterval={"01:00:00"}
      />
    </div>
  );
};

export default WeeklyAvailabilityControl;
