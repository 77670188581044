import React from "react";
import { discriminated } from "./ajex";
import _ from "underscore";
import { isEmpty } from "../framework/utils";

function connectInternal(setState, component, stores, localState) {
  let singleStore = !_.isArray(stores);

  if (!_.isArray(stores)) {
    stores = [stores];
  }

  let originals = {
    componentDidMount: component.componentDidMount,
    componentWillUnmount: component.componentWillUnmount,
  };

  if (singleStore) {
    component.state = singleStore.state || localState;
  }

  component.componentDidMount = function () {
    let mergedState = {};

    _.each(stores, (store) => {
      store.subscribe(component, (state) => setState(component, state));

      mergedState = _.assign(mergedState, store.state);
    });

    if (_.isFunction(originals.componentDidMount)) {
      originals.componentDidMount.call(component);
    }

    setState(component, mergedState);
  };

  component.componentWillUnmount = function () {
    _.each(stores, (store) => {
      store.unsubscribe(component);
    });

    if (_.isFunction(originals.componentWillUnmount)) {
      originals.componentWillUnmount.call(component);
    }
  };
}

function _connect(component, stores, localState = {}) {
  if (_.isFunction(component) && stores === undefined) {
    return _connectPropsOf(component);
  }
  return connectInternal(
    (component, state) => component.setState(state),
    component,
    stores,
    localState
  );
}

export function connectDiscriminated(
  discriminator,
  component,
  stores,
  localState = {}
) {
  return connectInternal(
    (component, state) =>
      component.setState(discriminated(state, discriminator)),
    component,
    stores,
    localState
  );
}

function _connectPropsOf(Component) {
  function sanitizeStores(storeOrStores) {
    return _.isArray(storeOrStores) ? storeOrStores : [storeOrStores];
  }

  function getActions(actionMapper) {
    return _.isFunction(actionMapper) ? actionMapper() ?? {} : {};
  }

  function getStateMapper(stateMapper) {
    return _.isFunction(stateMapper) ? stateMapper : (state) => state;
  }

  class ConnectedComponent extends React.Component {
    constructor(props) {
      super(props);
      const stores = this.props.stores,
        mergedState = {};

      _.each(stores, (store) => {
        _.extend(mergedState, store.state);
      });

      this.state = mergedState;

      _.each(stores, (store) =>
        store.subscribe(this, (state) => {
          this.setState(state);
        })
      );
    }

    componentDidMount() {
      const actions = this.props.actions;

      if (_.isFunction(actions.onInit)) {
        actions.onInit(this.props, this.state);
      }
    }

    componentWillUnmount() {
      const { stores, actions } = this.props;

      stores.forEach((store) => store.unsubscribe(this));

      if (_.isFunction(actions.onDestroy)) {
        actions.onDestroy(this.props, this.state);
      }
    }

    render() {
      const children = this.props.children ?? [],
        { stateMapper, actions } = this.props;

      return (
        <>
          {React.Children.map(children, (Child) => {
            return React.isValidElement(Child)
              ? React.cloneElement(
                  Child,
                  _.extend(stateMapper(this.state), actions)
                )
              : Child;
          })}
        </>
      );
    }
  }

  return {
    to: function (storeOrStores, stateMapper, actionMapper) {
      return function (props) {
        return (
          <ConnectedComponent
            stores={sanitizeStores(storeOrStores)}
            stateMapper={getStateMapper(stateMapper)}
            actions={getActions(actionMapper)}
            {...props}
          >
            <Component {...props} />
          </ConnectedComponent>
        );
      };
    },

    toDiscriminated: function (
      storeOrStores,
      stateMapper,
      actionMapper,
      discriminator
    ) {
      return function (props) {
        const _discriminator = props.discriminator ?? discriminator,
          _stateMapper = (state) => {
            return getStateMapper(stateMapper)(
              discriminated(state, _discriminator)
            );
          },
          _actions = _.mapObject(getActions(actionMapper), (action) => {
            return (...args) => action(_discriminator, ...args);
          });

        if (isEmpty(_discriminator)) {
          throw new Error(
            "[_connectPropsOf] toDiscriminated: no discriminator provided"
          );
        }

        return (
          <ConnectedComponent
            stores={sanitizeStores(storeOrStores)}
            stateMapper={_stateMapper}
            actions={_actions}
          >
            <Component discriminator={_discriminator} {...props} />
          </ConnectedComponent>
        );
      };
    },
  };
}

export const connect = _connect;
export const connectPropsOf = _connectPropsOf;
