import M from "../../strings";
import { getUrlParameter } from "../../web/utils/ui";
import { UserPermission } from "../enums";
import { PAGES } from "../vars";

export const TYPES = {
  DEFAULT: "default",
  COMPANY_MENU: "company",
  USERS_MENU: "users",
  ORDERS_MENU: "orders",
  SERVICES: "services",
};

export const MENUS = {
  [TYPES.DEFAULT]: {
    //backUrl: "/",

    items: [
      /* {
                id: PAGES.HOME,
                icon: "home",
                text: "Home",
                href: "/",
                separator: true,
            },
            {
                id: PAGES.USERS,
                children: [
                    {
                        id: "adminUser",
                        icon: "supervisor_account",
                        text: M("administrators"),
                        href: "/entities/adminUser",
                        permissions: ["ROLE_ADMIN"]
                    },
                    {
                        id: "companyUser",
                        icon: "supervisor_account",
                        text: M("companyAdministrators"),
                        href: "/entities/companyUser",
                        permissions: ["ROLE_ADMIN", "ROLE_COMPANY"]
                    },
                    {
                        id: "customers",
                        icon: "supervisor_account",
                        text: M("customers"),
                        href: "/entities/customerUser",
                        permissions: ["ROLE_ADMIN", "ROLE_COMPANY"]
                    }
                ]
            },
            
            {
                id: PAGES.COMPANIES,
                children: [
                    {
                        id: "company",
                        icon: "domain",
                        text: M("company"),
                        href: "/entities/company",
                        permissions: ["ROLE_ADMIN"]
                    },
                    {
                        id: "facility",
                        icon: "holiday_village",
                        text: M("facility"),
                        href: "/entities/facility",
                        permissions: ["ROLE_ADMIN", "ROLE_COMPANY"]
                    },
                ],
                permissions: ["ROLE_ADMIN"]
            },*/
      {
        id: PAGES.COMPANY_SETTINGS,
        children: [
          {
            id: "company",
            icon: "domain",
            text: M("company"),
            href: "/entities/company",
            permissions: [UserPermission.ADMIN_SUPERUSER],
          },
          {
            id: "company",
            icon: "domain",
            text: M("myCompany"),
            href: "/entities/company/my",
            permissions: [UserPermission.COMPANY_USER],
          },
          {
            id: "facility",
            icon: "holiday_village",
            text: M("facility"),
            href: "/entities/facility",
            permissions: [UserPermission.ADMIN_SUPERUSER],
          },
          {
            id: "facility",
            icon: "holiday_village",
            text: M("myFacility"),
            href: "/entities/facility/my",
            permissions: [UserPermission.COMPANY_USER],
          },
          {
            id: "extra",
            icon: "add_task",
            text: M("extraServices"),
            href: "/entities/extra",
          },
          {
            id: "pricingRule",
            icon: "price_check",
            text: M("pricingRules"),
            href: "/entities/pricingRule",
          },
          {
            id: "mappedProperty",
            icon: "link",
            text: M("channelManager"),
            href: "/entities/mappedProperty/my",
          },
        ],
        permissions: [UserPermission.COMPANY_USER],
      },
      {
        id: PAGES.SETTINGS,
        children: [
          {
            id: "facilityCategory",
            icon: "category",
            text: M("facilityCategory"),
            href: "/entities/facilityCategory",
            permissions: UserPermission.ADMIN_SUPERUSER,
          },
          {
            id: "facilitySubCategory",
            icon: "layers",
            text: M("facilitySubCategory"),
            href: "/entities/facilitySubCategory",
            permissions: UserPermission.ADMIN_SUPERUSER,
          },
          {
            id: "experienceCategory",
            icon: "category",
            text: M("experienceCategory"),
            href: "/entities/experienceCategory",
            permissions: UserPermission.ADMIN_SUPERUSER,
          },
          {
            id: "experienceSubCategory",
            icon: "streetview",
            text: M("experienceSubCategory"),
            href: "/entities/experienceSubCategory",
            permissions: UserPermission.ADMIN_SUPERUSER,
          },
          {
            id: "experienceStyle",
            icon: "style",
            text: M("experienceStyle"),
            href: "/entities/experienceStyle",
          },
          {
            id: "extra",
            icon: "add_task",
            text: M("extraServices"),
            href: "/entities/extra",
          },
          {
            id: "pricingRules",
            icon: "price_check",
            text: M("pricingRules"),
            href: "/entities/pricingRule",
          },
        ],
        permissions: [UserPermission.ADMIN_SUPERUSER],
      },
    ],
  },

  [TYPES.COMPANY_MENU]: {
    items: [
      {
        id: PAGES.COMPANY,
        children: [
          {
            id: "company",
            icon: "domain",
            text: M("company"),
            href: "/entities/section/" + PAGES.COMPANY + "/company",
          },
          {
            id: "facility",
            icon: "holiday_village",
            text: M("facility"),
            href: "/entities/section/" + PAGES.COMPANY + "/facility",
          },
        ],
        permissions: [UserPermission.ADMIN_SUPERUSER],
      },
    ],
  },

  [TYPES.USERS_MENU]: {
    items: [
      {
        id: PAGES.USERS,
        children: [
          {
            id: "adminUser",
            icon: "supervisor_account",
            text: M("administrators"),
            href: "/entities/section/" + PAGES.USERS + "/adminUser",
            permissions: [UserPermission.ADMIN_SUPERUSER],
          },
          {
            id: "companyUser",
            icon: "badge",
            text: M("companyAdministrators"),
            href: "/entities/section/" + PAGES.USERS + "/companyUser",
            permissions: [
              UserPermission.ADMIN_SUPERUSER,
              UserPermission.COMPANY_USER,
            ],
          },
          {
            id: "customer",
            icon: "person",
            text: M("customers"),
            href: "/entities/section/" + PAGES.USERS + "/customer",
            permissions: [UserPermission.ADMIN_SUPERUSER],
          },
        ],
      },
    ],
  },

  [TYPES.SERVICES]: {
    items: [
      {
        id: PAGES.SERVICES,
        children: [
          {
            id: "experienceCategory",
            icon: "category",
            text: M("experienceCategory"),
            href: "/entities/section/" + PAGES.SERVICES + "/experienceCategory",
            permissions: [UserPermission.ADMIN_SUPERUSER],
          },
          {
            id: "experienceSubCategory",
            icon: "layers",
            text: M("experienceSubCategory"),
            href:
              "/entities/section/" + PAGES.SERVICES + "/experienceSubCategory",
            permissions: [UserPermission.ADMIN_SUPERUSER],
          },
          {
            id: "experience",
            icon: "kayaking",
            text: M("experiences"),
            href: "/entities/section/" + PAGES.SERVICES + "/experience",
          },
          {
            id: "availability",
            icon: "event_available",
            text: M("availabilities"),
            href: "/entities/section/" + PAGES.SERVICES + "/availability",
            permissions: [
              UserPermission.ADMIN_SUPERUSER,
              UserPermission.COMPANY_USER,
            ],
          },
          {
            id: "unavailability",
            icon: "developer_board_off",
            text: M("unavailability"),
            href: "/entities/section/" + PAGES.SERVICES + "/unavailability",
            permissions: [
              UserPermission.ADMIN_SUPERUSER,
              UserPermission.COMPANY_USER,
            ],
          },
        ],
      },
    ],
  },
  [TYPES.ORDERS_MENU]: {
    items: [
      {
        id: PAGES.ORDERS,
        children: [
          /*{
            id: "trip",
            icon: "flight",
            text: M("trip"),
            href: "/entities/section/" + PAGES.ORDERS + "/trip",
            permissions: [UserPermission.ADMIN_SUPERUSER],
          },*/
          {
            id: "customerOrder",
            icon: "list_alt",
            text: M("orders"),
            href: "/entities/section/" + PAGES.ORDERS + "/customerOrder",
            permissions: [
              UserPermission.COMPANY_USER,
              UserPermission.ADMIN_SUPERUSER,
            ],
          },
          {
            id: "booking",
            icon: "menu_book",
            text: M("bookings"),
            href: "/entities/section/" + PAGES.ORDERS + "/booking",
            permissions: [
              UserPermission.COMPANY_USER,
              UserPermission.ADMIN_SUPERUSER,
            ],
          },
          {
            id: "payment",
            icon: "payments",
            text: M("payments"),
            href: "/entities/section/" + PAGES.ORDERS + "/payment",
            permissions: UserPermission.ADMIN_SUPERUSER,
          },
          
          /*{
            id: "cancellationRequest",
            icon: "event_busy",
            text: M("cancellationRequest"),
            href: "/entities/section/" + PAGES.ORDERS + "/cancellationRequest",
            permissions: [
              UserPermission.COMPANY_USER,
              UserPermission.ADMIN_SUPERUSER,
            ],
          },
          {
            id: "complaint",
            icon: "sms_failed",
            text: M("complaint"),
            href: "/entities/section/" + PAGES.ORDERS + "/complaint",
            permissions: [
              UserPermission.COMPANY_USER,
              UserPermission.ADMIN_SUPERUSER,
            ],
          },*/
        ],
      },
    ],
  },

  /*[TYPES.LICENSE_MENU]:{
        backUrl: "/entities/section/" + PAGES.COMPANY + "/company" ,
        items:[
            {
                id: PAGES.LICENSE,
                children:[
                    {
                        id: "license",
                        icon: "layers",
                        text: M("license"),
                        href: "/entities/section/" + PAGES.COMPANY + "/license",
                        permissions: UserPermission.ADMIN_SUPERUSER
                    },
                ]
            }
        ]
    }*/
};
