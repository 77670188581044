import _ from "underscore";
import React, { useCallback } from "react";
import { hideDialog, showDialog } from "../../../actions/dialog";
import { Cell } from "../grids";
import StandaloneEntityGrid from "../standaloneEntityGrid";
import M from "../../../strings";
import { optional, uuid } from "../../../utils/lang";

export default class EntitiesLookupCell extends Cell {
  constructor(props) {
    super(props);
    this.uuid = uuid();

    this.state = {
      selectedValue: undefined,
    };
  }

  getDiscriminator() {
    return this.uuid + "_lookUpCell";
  }

  getDescriptor() {
    return this.props?.descriptor ?? {};
  }

  onChange(data) {
    const value = data;
    let column = this.props.column;
    let row = this.props.row;
    this.setState({ selectedValue: value });
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange(column, row.data, value.id);
    }
  }

  isEditable() {
    return optional(this.props.editable, true);
  }

  onClick() {
    showDialog({
      getContent: () => (
        <EntitiesSelectDialog
          entity="experienceSubCategory"
          onRowSelect={this.onChange.bind(this)}
          descriptor={this.getDescriptor()}
          discriminator={this.getDiscriminator()}
        />
      ),
      backdrop: "static",
    });
  }

  render() {
    let isEditable = this.isEditable();
    let selected = this.state.selectedValue;

    return (
      <div className="textcell-container">
        {isEditable || _.isUndefined(selected) ? (
          <div className="pointer-cursor" onClick={this.onClick.bind(this)}>
            {this.state.selectedValue?.description ?? M("noItemSelected")}
          </div>
        ) : (
          <div>
            {this.state.selectedValue?.description ?? M("noItemSelected")}
          </div>
        )}
      </div>
    );
  }
}

export function EntitiesSelectDialog(props) {
  const onRowDoubleClick = useCallback(
    (row) => {
      hideDialog();
      if (_.isFunction(props.onRowSelect)) {
        props.onRowSelect(row);
      }
    },
    [props.onRowSelect]
  );

  return (
    <StandaloneEntityGrid
      {...props}
      onRowDoubleClick={onRowDoubleClick}
      descriptor={props.descriptor}
      discriminator={props.discriminator}
      hidefilter={true}
      quickSearchEnabled={true}
    />
  );
}
