import { EQ, GT, GTE, LIKE, LT, LTE } from "../framework/query";

export const FilterTypeMap = new Map([
  [GTE, "	≥"],
  [GT, ">"],
  [LTE, "≤"],
  [LT, "<"],
  [EQ, "="],
  [LIKE, "≈"],
]);

export const UserPermission = {
  ADMIN_SUPERUSER: "ROLE_ADMIN",
  USER: "ROLE_USER",
  COMPANY_USER: "ROLE_COMPANY",
};

export const Status = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  CLOSED: "Closed",
  DRAFT: "Draft",
};

export const OrderStatus = {
  WAITING: "Waiting",
  CONFIRMED: "Confirmed",
  PAID: "Paid",
  NOT_PAID: "NotPaid",
  CANCELLED: "Cancelled",
  EXPRIRED: "Expired",
};

export const PaymentStatus = {
  CREATED : "Created",
  STARTED : "Started",
  FAILED : "Failed",
  PARTIALLY_COMPLETED : "PartiallyCompleted",
  COMPLETE : "Complete",
  CANCELLED : "Canceled",
};

export const TypeTimeAndPerson = {
  PRECOMPILED: "Precompiled",
  COMPILED: "Compilable",
  CHOICE: "Choice",
  DAILY: "Daily",
  SLOT: "Slot",
};

export const CancellationTimeUnit = {
  DAYS: "Days",
  HOURS: "Hours",
  MINUTES: "Minutes",
};
