import React from "react";
import { ActiveCell, DateCell, TextCell } from "../components/grids";
import { DateTime, Number } from "../components/forms";
import { CompanyServiceSubCategoriesControl } from "../components/controls/companyServiceSubCategoriesControl";
import M from "../../strings";
import * as ui from "../utils/ui";
import * as _query from "../../framework/query";
import { isNotEmpty } from "../../framework/utils";
import { ACTION_TYPES } from "../components/actions";
import _ from "underscore";
import { Status } from "../../model/enums";
import { hasCrudPermissions, isSuperuser } from "../../api/session";

export default () => ({
  grid: {
    title: null,
    canCreate: false,
    canDelete: false,
    canRefresh: false,
    canSelectAll: false,
    descriptor: {
      columns: [
        {
          property: "start",
          header: M("startDate"),
          cell: DateCell,
          sortable: true,
          searchable: false,
        },
        {
          property: "end",
          header: M("endDate"),
          cell: DateCell,
          sortable: true,
          searchable: false,
        },
        {
          property: "maxFacilities",
          header: M("maxFacilities"),
          cell: TextCell,
          sortable: true,
          searchable: true,
        },
        {
          property: "status",
          header: M("status"),
          cell: ActiveCell,
          sortable: false,
          searchable: false,
          props: {
            items: [
              {
                value: Status.ACTIVE,
                className: "active",
              },
              {
                value: Status.INACTIVE,
                className: "disabled",
              },
              {
                value: Status.CLOSED,
                className: "closed",
              },
            ],
          },
        },
      ],
    },
  },
  form: {
    title: M("editCurrentLicense"),
    subtitle: null,
    showFloatingSaveBtn: false,
    gridUrl: () =>
      "/entities/section/company/company/" +
      ui.getUrlParameter("parentEntityId"),
    canGoBack: () => isSuperuser(),
    canSave: () => hasCrudPermissions("license:save"),
    descriptor: {
      onModelLoadFirstTime: (model) => {
        model.set("companyId", ui.getUrlParameter("parentEntityId"));
        if (!isNotEmpty(model.get("status"))) {
          model.set("status", Status.INACTIVE);
          model.invalidateForm();
        }
      },
      onModelLoad: (model) => {
        model.on("property:change", (property, value) => {
          if (property === "start" || property === "status") {
            let currentDate = new Date().getTime();
            let start = model.get("start");
            if (start > currentDate) {
              model.set("status", Status.INACTIVE);
              model.invalidateForm();
            }
          }
        });
      },
      areas: [
        {
          title: M("currentLicense"),
          actions: (model) => {
            return [
              {
                id: "status",
                title: model.get("status"),
                type: isSuperuser() ? ACTION_TYPES.SELECT : ACTION_TYPES.BUTTON,
                className: "btn-secondary",
                items: [
                  {
                    id: "active",
                    title: "Active",
                    action: () => {
                      model.set("status", "Active");
                      model.invalidateForm();
                    },
                  },
                  {
                    id: "inactive",
                    title: "Inactive",
                    action: () => {
                      model.set("status", "Inactive");
                      model.invalidateForm();
                    },
                  },
                ],
              },
            ];
          },
          fields: [
            {
              property: "start",
              placeholder: M("from"),
              control: DateTime,
              size: "col-sm-4",
              label: M("startDate"),
              props: {
                isLocalDate: true,
              },
              validator: (value, model) => {
                let millisecondFirst = Date.UTC(
                  value[0],
                  value[1] - 1,
                  value[2]
                );
                if (_.isNull(value) || _.isUndefined(value)) {
                  throw new Error("Field mustn't be empty");
                } else {
                  if (
                    !(
                      _.isNull(model.get("end")) ||
                      _.isUndefined(model.get("end"))
                    )
                  ) {
                    let endDate = model.get("end");
                    let millisecondSecond = Date.UTC(
                      endDate[0],
                      endDate[1] - 1,
                      endDate[2]
                    );
                    if (millisecondFirst > millisecondSecond) {
                      throw new Error(
                        "Date must be lower than " + M("endDate")
                      );
                    }
                  }
                }
              },
            },
            {
              property: "end",
              placeholder: M("to"),
              control: DateTime,
              size: "col-sm-4",
              label: M("endDate"),
              props: {
                isLocalDate: true,
              },
              validator: (value, model) => {
                let millisecondFirst = Date.UTC(
                  value[0],
                  value[1] - 1,
                  value[2]
                );
                if (_.isNull(value) || _.isUndefined(value)) {
                  throw new Error("Field mustn't be empty");
                } else {
                  if (
                    !(
                      _.isNull(model.get("start")) ||
                      _.isUndefined(model.get("start"))
                    )
                  ) {
                    let startDate = model.get("start");
                    let millisecondSecond = Date.UTC(
                      startDate[0],
                      startDate[1] - 1,
                      startDate[2]
                    );
                    if (millisecondSecond > millisecondFirst) {
                      throw new Error(
                        "Date must be grater than " + M("startDate")
                      );
                    }
                    let currentDate = new Date();
                    let currentDateMilli = Date.UTC(
                      currentDate.getFullYear(),
                      currentDate.getMonth(),
                      currentDate.getDay()
                    );
                    if (millisecondFirst < currentDateMilli) {
                      throw new Error("Date must be grater than current date");
                    }
                  }
                }
              },
            },
            {
              property: "maxFacilities",
              control: Number,
              size: "col-sm-4",
              label: M("maxFacilities"),
              placeholder: M("maxFacilities"),
              validator: (value, model) => {
                if (_.isNull(value) || _.isUndefined(value)) {
                  throw new Error("Field mustn't be empty");
                }
                let isInteger = parseInt(value);
                if (_.isNaN(isInteger)) {
                  throw new Error("Field must be a number");
                }
                let number = parseInt(value);
                if (number < 0) {
                  throw new Error("Number must be grather than 0");
                }
              },
            },
          ],
        },
        {
          title: "",
          fields: [
            {
              title: M("experienceSubCategoriesEnabled"),
              property: "experienceSubCategories",
              control: CompanyServiceSubCategoriesControl,
              validator: (value, model) => {
                if (
                  _.isEmpty(value) ||
                  _.isUndefined(value) ||
                  _.isNull(value)
                ) {
                  throw new Error("Field mustn't be empty");
                }
              },
            },
          ],
        },
      ],
    },
  },
});
