import { dispatch } from "../aj";
import { createAsyncAction } from "../utils/ajex";
import Services from "../api/services";
import { hideLoader, showLoader } from "../plugins";
import {
  SET_USER_FACILITY,
  CHANGE_USER_FACILITY,
  GET_USER_FACILITY,
} from "./types";
import _ from "underscore";
import M from "../strings";
import * as responses from "../api/responses";

export function setUserFacility(userFacility) {
  return dispatch({
    SET_USER_FACILITY,
    userFacility,
  });
}

export const changeUserFacility = createAsyncAction(
  CHANGE_USER_FACILITY,
  async (data) => {
    if (_.isEmpty(data.id)) {
      alert(M("problemOccoured"), M("pleaseSpecifyId"));
      return;
    }

    dispatch({
      type: CHANGE_USER_FACILITY,
    });

    showLoader();
    try {
      const response = await Services.post("users/me/facility", {
        id: data.id,
      });
      changeUserFacility.complete({ userFacility: response.value });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      changeUserFacility.fail();
    } finally {
      hideLoader();
    }
  }
);

export const getUserFacility = createAsyncAction(
  GET_USER_FACILITY,
  async () => {
    dispatch({
      type: GET_USER_FACILITY,
    });

    showLoader();
    try {
      const response = await Services.get("users/me/facility");
      getUserFacility.complete({ userFacility: response.value });
    } catch (e) {
      alert(M("ooops"), responses.msg(e), "error");
      getUserFacility.fail();
    } finally {
      hideLoader();
    }
  }
);
