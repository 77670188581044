import React from "react";
import { PAGES } from "../../model/vars";
import { withCustomMenu } from "../components/customMenu";
import { Screen, Layout } from "../components/layout";
import { secureScreen } from "../components/secure";

class _Settings extends Screen {
  render() {
    const page = this.props.section ?? PAGES.SETTINGS;

    return <Layout page={page}></Layout>;
  }
}

export default secureScreen(withCustomMenu(_Settings));
