import moment from "moment";
import "moment/locale/it"; // without this line it didn't work
import M from "../strings";

export const DATE_FORMAT_DATEPICKER = "DD/MM/YYYY";
export const DATE_FORMAT_FULL_WITH_HOURS = "DD MMM YYYY, HH:mm";
export const DATE_FORMAT_FULL = "DD MMM YYYY";

export function momentInstance() {
  return moment;
}

export function formatDate(date, format = DATE_FORMAT_DATEPICKER) {
  try {
    return momentInstance()(date).format(format);
  } catch (e) {
    return date;
  }
}

//date.valueOf() ->timestamp con moment

export function getDateFromString(date, format = DATE_FORMAT_DATEPICKER) {
  return momentInstance()(date, format);
}

export function addDaysToDate(date, days = 0) {
  try {
    return momentInstance()(date).add(days, "days").toDate();
  } catch (e) {
    return date;
  }
}

export function addMonthsToDate(date, months = 0) {
  try {
    return momentInstance()(date).add(months, "months").toDate();
  } catch (e) {
    return date;
  }
}

export function datesOverlaps(startDate1, endDate1, startDate2, endDate2) {
  return (
    momentInstance()(startDate1).isSameOrBefore(endDate2) &&
    momentInstance()(endDate1).isSameOrAfter(startDate2) &&
    momentInstance()(startDate2).isSameOrBefore(endDate1) &&
    momentInstance()(endDate2).isSameOrAfter(startDate1)
  );
}

export function toLocalDate(date) {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()];
}

export function fromLocalDate(localDate) {
  return new Date(localDate[0], localDate[1] - 1, localDate[2]);
}

export function localDateToIsoString([year, month, date]) {
  year = new Number(year).toString().padStart(4, "0");
  month = new Number(month).toString().padStart(2, "0");
  date = new Number(date).toString().padStart(2, "0");

  return `${year}-${month}-${date}`;
}

export function isSameDay(date1, date2) {
  return momentInstance()(date1).isSame(date2, "day");
}

export function isDateBetween(date, startDate, endDate) {
  return momentInstance()(date).isBetween(startDate, endDate, null, "[]");
}

export function formatLocalDate(localDate, format = M("dateFormat")) {
  return formatDate(fromLocalDate(localDate), format);
}

export function formatFromInstantDate(date, format) {
 let formatted = new Date(date * 1000)
 return formatDate(formatted, format);
}
