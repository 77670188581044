import _ from "underscore";
import { getLanguage } from "../strings";

export const OK = "ok";
export const ERROR = "error.generic";
export const UNAUTHORIZED = "error.unauthorized";
export const ERROR_MAIL_ALREADY_EXISTS =
  "iam.error.email-address-already-exists";
export const ERROR_MAIL_NOT_FOUND = "iam.error.user-not-found";
export const ERROR_BAD_CREDENTIALS = "iam.error.bad-username-or-password";
export const ERROR_TOKEN_GENERATION = "iam.error.token-generation";
export const ERROR_TOKEN_EXPIRED = "iam.error.token-expired";
export const ERROR_TOKEN_FORMAT = "iam.error.token-format";
export const ERROR_MAIL_NOT_VALID = "iam.error.email-not-valid";
export const ERROR_PASSWORD_NOT_VALID = "iam.error.password-not-valid";
export const ERROR_VALIDATION = "error.validation";
export const ERROR_NOT_FOUND = "error.not-found";
export const ERROR_USER_NOT_FOUND = "iam.error.user-not-found";
export const ERROR_BAD_ACTIVATION_TOKEN = "iam.error.bad-activation-code";
export const ERROR_USER_NOT_ACTIVE = "iam.error.user-not-active";
export const ERROR_MAX_LOGIN_ATTEMPTS = "iam.error.max-login-attempts";
export const ERROR_CONSTRAINT_VIOLATION = "error.constraint-violation";

const messages = {
  en: {},

  it: {},
};

messages["en"][OK] = "OK";
messages["en"][ERROR] = "Generic error";
messages["en"][ERROR_MAIL_ALREADY_EXISTS] =
  "Cannot register: mail already exists";
messages["en"][ERROR_MAIL_NOT_FOUND] = "Mail not found";
messages["en"][ERROR_BAD_CREDENTIALS] =
  "Cannot login: bad username or password";
messages["en"][ERROR_TOKEN_GENERATION] = "Error generating token";
messages["en"][ERROR_TOKEN_FORMAT] = "Bat token format";
messages["en"][ERROR_MAIL_NOT_VALID] = "Invalid email";
messages["en"][ERROR_PASSWORD_NOT_VALID] = "Password not valid";
messages["en"][ERROR_VALIDATION] =
  "Validation error. Please control inserted data and retry";
messages["en"][ERROR_NOT_FOUND] = "Not found";
messages["en"][ERROR_USER_NOT_FOUND] = "User not found";
messages["en"][ERROR_CONSTRAINT_VIOLATION] = "Constraint violation";

messages["it"][OK] = "OK";
messages["it"][ERROR] = "Si è verificato un errore";
messages["it"][ERROR_MAIL_ALREADY_EXISTS] =
  "Impossibile registrarsi: indirizzo email già presente";
messages["it"][ERROR_MAIL_NOT_FOUND] = "Mail non trovata";
messages["it"][ERROR_BAD_CREDENTIALS] =
  "Impossibile accedere: email o password errati";
messages["it"][ERROR_TOKEN_GENERATION] =
  "Errore durante la generazione del token";
messages["it"][ERROR_TOKEN_FORMAT] = "Formato del token non valido";
messages["it"][ERROR_MAIL_NOT_VALID] = "Email non valida";
messages["it"][ERROR_PASSWORD_NOT_VALID] = "Password non valida";
messages["it"][ERROR_VALIDATION] =
  "Errore di validazione: ricontrollare i dati inseriti e riprovare";
messages["it"][ERROR_NOT_FOUND] = "Non trovato";
messages["it"][ERROR_USER_NOT_FOUND] = "Utente non trovato";
messages["it"][ERROR_CONSTRAINT_VIOLATION] =
  "Impossibile proseguire. Esistono entità collegate che dipendono da questa entità";

export function msg(response) {
  let responseCode = null;

  if (typeof response === "object") {
    if (response.message) return response.message;

    responseCode = response.responseCode;
  } else {
    responseCode = response;
  }

  if (_.has(messages[getLanguage()], responseCode)) {
    return messages[getLanguage()][responseCode];
  }

  return "Errore n. " + responseCode;
}

/**
 * Returns value of value responses. If o is a promise, a wrapped promise thar returns value will be returned
 */
export function value(o) {
  if (o instanceof Promise) {
    return new Promise((resolve, reject) => {
      o.then((result) => {
        resolve(result.value);
      }).catch((e) => reject(e));
    });
  } else {
    if (_.isObject(o)) {
      return o.value;
    }
  }

  logger.w(o, "is not a value response");

  return null;
}
